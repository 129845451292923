import qs from 'qs';
import { BooleanInput, FormDataConsumer, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import {
  AutocompleteArrayInputIntrospected,
  AutocompleteInputIntrospected,
} from 'shared/react-admin/Inputs';
import { CreateGuesserPage } from 'shared/react-admin/Pages';
import { ReferenceInputIntrospected } from 'shared/react-admin/ReferenceInputs';

import { fieldWidth } from '../../themes/variables';
import { cleanEmpty } from '../../utils/cleanEmpty';

type ApiClientDefaultValueType = {
  apiClient: string;
  merchant: string;
};

const MerchantPayoutControlsCreate = (): JSX.Element => {
  const redirect = useRedirect();
  const location = useLocation();
  const defaultValues = qs.parse(
    location?.search.replace('?', '') as string,
  ) as ApiClientDefaultValueType;

  const onSuccess = () => {
    redirect('/merchant_payout_controls');
  };

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButtonValidated key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Контроль выводов"
      mutationOptions={{ onSuccess }}
      title="Создание контроля выводов"
    >
      <CardContent>
        <ReferenceInputIntrospected
          filter={cleanEmpty({
            include: defaultValues?.merchant ? [defaultValues?.merchant] : undefined,
          })}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInputIntrospected
            defaultValue={defaultValues?.merchant}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            label="Мерчант"
            optionText="name"
            source="name"
            sx={fieldWidth.medium}
            variant="outlined"
          />
        </ReferenceInputIntrospected>
        <ReferenceInputIntrospected perPage={200} reference="gateways" source="gateway">
          <AutocompleteInputIntrospected
            filterToQuery={(searchText: string) => ({ name: searchText })}
            label="Шлюз"
            optionText="name"
            source="name"
            sx={fieldWidth.medium}
            variant="outlined"
          />
        </ReferenceInputIntrospected>
        <BooleanInput
          label="Разрешить выплату на другой аккаунт"
          source="allowPayoutToAnotherAccount"
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ReferenceInputIntrospected
                filter={{ merchant: formData.merchant }}
                queryOptions={{ enabled: formData.allowPayoutToAnotherAccount }}
                reference="customers"
                source="excludedCustomers"
              >
                <AutocompleteArrayInputIntrospected
                  disabled={!formData.allowPayoutToAnotherAccount}
                  label="Исключенные пользователи"
                  matchSuggestion={(filter, choice) => {
                    return choice.originId.match(filter);
                  }}
                  optionText="originId"
                  source="customerId"
                  sx={fieldWidth.medium}
                  variant="outlined"
                />
              </ReferenceInputIntrospected>
            );
          }}
        </FormDataConsumer>
      </CardContent>
    </CreateGuesserPage>
  );
};

export default MerchantPayoutControlsCreate;
