import { Box, LinearProgress } from '@mui/material';
import { useGetList, useRecordContext } from 'react-admin';

import { GatewayRuleFieldGroup } from './GatewayRuleFieldGroup';
import { Country, Currency } from '../../../../types';
import { useConditionGroups } from '../../hooks';

type Props = {
  label?: string;
  source: string;
  sortable?: boolean;
};

export function GatewayRuleField({ source }: Props): JSX.Element {
  const record = useRecordContext();
  const value = record[source];

  const { conditionGroup, isLoadingConditionGroup } = useConditionGroups(value);

  const { data: currencies = [] } = useGetList<Currency>('currencies', {
    pagination: { page: 1, perPage: 1000 },
  });

  const { data: countries = [] } = useGetList<Country>('countries', {
    pagination: { page: 1, perPage: Infinity },
  });

  if (isLoadingConditionGroup) {
    return (
      <Box sx={{ padding: '12px' }}>
        <LinearProgress />
      </Box>
    );
  }

  if (!conditionGroup) {
    return <></>;
  }

  if (conditionGroup?.expressions?.length <= 0 && conditionGroup?.children?.length <= 0) {
    return <>—</>;
  }

  return (
    <GatewayRuleFieldGroup
      className={`tree ${conditionGroup?.expressions?.length <= 0 ? 'empty-tree' : ''}`}
      countries={countries}
      currencies={currencies}
      group={conditionGroup}
      isOnlyOneExpression={
        conditionGroup?.children?.length <= 0 && conditionGroup?.expressions?.length <= 1
      }
    />
  );
}
