import { Box } from '@mui/material';

import {
  GatewayRuleConditionFieldList,
  GatewayRuleConditionFieldListItem,
  GatewayRuleConditionFieldListItemDot,
} from './GatewayRuleConditionFieldList';
import { GatewayRuleFieldExpression } from './GatewayRuleFieldExpression';
import { GatewayRuleGroupIsAnd } from './GatewayRuleGroupIsAnd';
import { Country, Currency } from '../../../../types';
import { ConditionGroup } from '../../../Common/Forms/GatewayRulesForm/GatewayRulesForm';

type Props = {
  shade?: number;
  isOnlyOneExpression?: boolean;
  className?: string;
  group: ConditionGroup;
  currencies: Currency[];
  countries: Country[];
};

export function GatewayRuleFieldGroup({
  shade = 0,
  group,
  countries,
  currencies,
  className,
  isOnlyOneExpression,
}: Props): JSX.Element {
  const SHADE_STEP = 0.04;

  return (
    <GatewayRuleConditionFieldList
      className={className}
      style={{
        marginBottom: '12px',
        backgroundColor: `rgba(0,0,0,${shade})`,
      }}
    >
      {!isOnlyOneExpression && (
        <GatewayRuleConditionFieldListItem>
          <Box>
            <GatewayRuleGroupIsAnd isAnd={group.isAnd} />
          </Box>
        </GatewayRuleConditionFieldListItem>
      )}
      <GatewayRuleConditionFieldList
        style={{
          marginLeft: isOnlyOneExpression ? '0' : '32px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <GatewayRuleConditionFieldListItemDot />
        {group?.expressions?.length > 0 &&
          group?.expressions?.map((expression) => (
            <GatewayRuleConditionFieldListItem key={expression.id}>
              {!isOnlyOneExpression && <GatewayRuleConditionFieldListItemDot />}
              <GatewayRuleFieldExpression
                countries={countries || []}
                currencies={currencies || []}
                expression={expression}
                key={expression.id}
              />
            </GatewayRuleConditionFieldListItem>
          ))}
      </GatewayRuleConditionFieldList>

      {group?.children?.length > 0 &&
        group?.children?.map((group, index) => (
          <GatewayRuleConditionFieldListItem
            key={index}
            style={{
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <GatewayRuleFieldGroup
              className="nested"
              countries={countries}
              currencies={currencies}
              group={group}
              isOnlyOneExpression={false}
              key={index}
              shade={shade + SHADE_STEP}
            />
          </GatewayRuleConditionFieldListItem>
        ))}
    </GatewayRuleConditionFieldList>
  );
}
