import React, { useMemo } from 'react';

import { Tooltip, Typography } from '@mui/material';

import { Country } from '../../../../../types';
import { GatewayRuleFieldChip } from '../GatewayRuleFieldChip';

type Props = {
  countries: Country[];
  codes: string[];
};

export function GatewayRuleFieldExpressionParamCountry({ countries, codes }: Props): JSX.Element {
  const countriesValue = useMemo(() => {
    return codes
      ?.map((code) => countries?.find(({ alphaCode }) => alphaCode === code)?.name)
      ?.join(', ');
  }, [countries, codes]);

  if (countries.length <= 0) {
    return <></>;
  }

  return (
    <Tooltip
      placement="bottom-start"
      title={<Typography fontSize={11}>{countriesValue}</Typography>}
    >
      <GatewayRuleFieldChip label={countriesValue} />
    </Tooltip>
  );
}
