import {
  ChipField,
  Datagrid,
  FunctionField,
  RaRecord,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { HistoryIconButton } from 'shared/mui/IconButtons';

import { usePermissions } from '../../hooks/usePermissions';
import { AppReferenceOneField } from '../Common';
import { AppShow } from '../Common/Show';

const HoldsShow = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <AppShow
      actions={false}
      listName="Холды"
      title="Просмотр холда"
      toolbarActions={[<HistoryIconButton key="history-button" />]}
    >
      <Datagrid>
        <TextField label="Id" source="originId" />
        <TextField label="Значение" source="value" />
        {hasResourceAccess('currencies') && (
          <AppReferenceOneField label="Валюта" reference="currencies" source="currency" target="id">
            <FunctionField
              label="Name"
              render={(record: RaRecord | undefined) => `${record?.alphaCode}, ${record?.name}`}
            />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('merchant_accounts') && (
          <ReferenceArrayField
            label="Счета мерчантов"
            reference="merchant_accounts"
            source="merchantAccounts"
          >
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
      </Datagrid>
    </AppShow>
  );
};

export default HoldsShow;
