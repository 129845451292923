import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import {
  BooleanInput,
  FormDataConsumer,
  RaRecord,
  required,
  SimpleForm,
  useEditController,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { AutocompleteInput, AutocompleteInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Permission } from 'types';

import { fieldWidth } from '../../themes/variables';
import { AppEdit } from '../Common/Edit';

const MerchantPayoutControlsEdit = (): JSX.Element => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: merchantPayoutControl,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<RaRecord>();

  return (
    <AppEdit actions={false} listName="Контроль выводов" title="Редактирование контроля выводов">
      <DeleteDialog
        details="Контроль выводов будет удален без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={merchantPayoutControl}
        title="Удаление контроля выводов"
      />
      <SimpleForm
        toolbar={
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
              <CancelButton key="cancel-button" linkType="back" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('merchant_payout_controls', [
                Permission.Full,
                Permission.Delete,
              ]) && (
                <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
              )
            }
          />
        }
      >
        <ReferenceInput reference="merchants" source="merchant">
          <AutocompleteInputIntrospected
            filterToQuery={(searchText: string) => ({ name: searchText })}
            label="Мерчант"
            optionText="name"
            source="name"
            sx={fieldWidth.medium}
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
        <ReferenceInput perPage={200} reference="gateways" source="gateway">
          <AutocompleteInputIntrospected
            filterToQuery={(searchText: string) => ({ name: searchText })}
            label="Шлюз"
            optionText="name"
            source="name"
            sx={fieldWidth.medium}
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
        <BooleanInput
          label="Разрешить выплату на другой аккаунт"
          source="allowPayoutToAnotherAccount"
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ReferenceInput
                filter={{ merchant: formData.merchant }}
                reference="customers"
                source="excludedCustomers"
              >
                <AutocompleteInput
                  disabled={!formData.allowPayoutToAnotherAccount}
                  label="Исключенные пользователи"
                  matchSuggestion={(filter, choice) => {
                    return choice.originId.match(filter);
                  }}
                  optionText="originId"
                  source="customerId"
                  sx={fieldWidth.medium}
                  variant="outlined"
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </AppEdit>
  );
};

export default MerchantPayoutControlsEdit;
