import React, { Fragment } from 'react';

import { Introspecter } from '@api-platform/admin';
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  useResourceContext,
} from 'react-admin';

import { fieldValidateGuesser } from '../../../utils';

const IntrospectedAutocompleteArrayInput = ({ fields, validate, ...props }: any) => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });
  if (isError) {
    return <Fragment />;
  }
  return <AutocompleteArrayInput validate={guessedValidate} {...props} />;
};

const AppAutocompleteArrayInput = ({
  disabled,
  ...restProps
}: AutocompleteArrayInputProps): JSX.Element => {
  const resource = useResourceContext(restProps);

  return (
    <Introspecter
      component={IntrospectedAutocompleteArrayInput}
      disabled={disabled}
      includeDeprecated={disabled}
      resource={resource}
      {...restProps}
    />
  );
};

export default AppAutocompleteArrayInput;
