import { AppSingleFieldList } from 'components/Common/Fields/AppSingleFieldList';
import {
  ChipField,
  FunctionField,
  NumberField,
  RaRecord,
  ReferenceArrayField,
  TextField,
  useShowController,
  WrapperField,
} from 'react-admin';
import { HistoryIconButton } from 'shared/mui/IconButtons';
import { JsonField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';

import { usePermissions } from '../../hooks/usePermissions';
import { Hold, MerchantAccount } from '../../types';
import { AppReferenceField, AppReferenceOneField } from '../Common';
import { AppShowGuesser } from '../Common/Show';

const MerchantAccountsShow = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();
  const { record } = useShowController<MerchantAccount>();

  return (
    <AppShowGuesser
      actions={false}
      listName="Счета"
      title="Просмотр счета"
      toolbarActions={[<HistoryIconButton key="history-button" />]}
    >
      <TextField label="Id" source="originId" />
      <TextField label="Название" source="name" />
      {hasResourceAccess('providers') && (
        <ReferenceField
          emptyText="—"
          label="Провайдер"
          link={false}
          reference="providers"
          sortBy="provider.name"
          source="provider"
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      {hasResourceAccess('gateways') && (
        <ReferenceArrayField label="Шлюз" reference="gateways" source="gateways">
          <AppSingleFieldList>
            {({ record, resource }) => (
              <ChipField record={record} resource={resource} source="name" />
            )}
          </AppSingleFieldList>
        </ReferenceArrayField>
      )}
      <NumberField label="Баланс" source="balance" />
      {hasResourceAccess('currencies') && (
        <AppReferenceOneField label="Валюта" reference="currencies" source="currency" target="id">
          <FunctionField
            label="Name"
            render={(record: RaRecord | undefined) => `${record?.alphaCode}, ${record?.name}`}
          />
        </AppReferenceOneField>
      )}
      {hasResourceAccess('holds') && record?.hold && (
        <AppReferenceField label="Холд" link={false} reference="holds" source="hold">
          <WrapperField>
            <FunctionField render={(record: Hold) => record.value + ', '} />
            <AppReferenceField link={false} reference="currencies" source="currency">
              <TextField source="alphaCode" />
            </AppReferenceField>
          </WrapperField>
        </AppReferenceField>
      )}
      {hasResourceAccess('merchants') && (
        <ReferenceArrayField label="Мерчанты" reference="merchants" source="merchants">
          <AppSingleFieldList>
            {({ record, resource }) => (
              <ChipField record={record} resource={resource} source="name" />
            )}
          </AppSingleFieldList>
        </ReferenceArrayField>
      )}
      {hasResourceAccess('merchant_credentials') && (
        <AppReferenceField
          label="Доступы мерчанта"
          link="show"
          reference="merchant_credentials"
          source="merchantCredentials"
        >
          <TextField source="name" />
        </AppReferenceField>
      )}
      <JsonField label="Доступы счёта" source="credentials" stringTruncate={1000000} />
    </AppShowGuesser>
  );
};

export default MerchantAccountsShow;
