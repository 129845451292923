import { TextField, BooleanField } from 'react-admin';
import { HistoryIconButton } from 'shared/mui/IconButtons';
import { JsonField } from 'shared/react-admin/Fields';

import { usePermissions } from '../../hooks/usePermissions';
import { AppReferenceOneField } from '../Common';
import { AppShowGuesser } from '../Common/Show';

const MerchantAlertsShow = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <AppShowGuesser
      actions={false}
      listName="Оповещения мерчантов"
      title="Просмотр оповещения мерчанта"
      toolbarActions={[<HistoryIconButton key="history-button" />]}
    >
      {hasResourceAccess('merchants') && (
        <AppReferenceOneField
          label="Мерчант"
          link="show"
          reference="merchants"
          source="merchant"
          target="id"
        >
          <TextField source="name" />
        </AppReferenceOneField>
      )}
      <TextField label="Тип" source="type" />
      <JsonField label="Доступы" source="credentials" />
      <BooleanField label="Активно" source="active" />
      <TextField label="Процент успешных транзакций" source="percentFailure" />
    </AppShowGuesser>
  );
};

export default MerchantAlertsShow;
