import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';
import { Company, Merchant, Permission } from 'types';

import { MerchantsShowCommonInformationTable } from './MerchantsShowCommonInformationTable';
import { UserActionsIconButton } from '../../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

type MerchantsShowCommonInformationProps = {
  record?: Merchant;
};

export const MerchantsShowCommonInformation: React.FC<MerchantsShowCommonInformationProps> = ({
  record,
}): JSX.Element => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { toRoot } = useNavigateToRoot();
  const {
    record: providerAccount,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<Merchant>();
  const {
    record: company,
    handleOpenRecordModal: handleOpenCompanyModal,
    handleCloseRecordModal: handleCloseCompanyModal,
    openRecordModal: openCompanyModal,
  } = useRecordModal<Company>();

  return (
    <Card sx={{ border: '1px solid rgba(0,0,0,0.12)', width: '600px' }}>
      <CardHeader
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="merchant" key="user-action-link" />]
            : undefined
        }
        title="Общая информация"
      />
      <MerchantsShowCommonInformationTable
        handleOpenCompanyModal={handleOpenCompanyModal}
        record={record}
      />
      <CardActionsButtons
        leftActionsSlot={
          hasResourcePermissions('merchants', [Permission.Full, Permission.Update]) && (
            <EditLinkButton />
          )
        }
        rightActionsSlot={
          hasResourcePermissions('merchants', [Permission.Full, Permission.Delete]) && (
            <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
          )
        }
      />
      <DeleteDialog
        details={`Мерчант ${record?.name} будет удален без возможности восстановления. `}
        onClose={handleCloseRecordModal}
        onSuccess={toRoot}
        open={openRecordModal}
        record={providerAccount}
        title="Удаление мерчанта"
      />
      {company && (
        <CompanyShowDialog
          company={company}
          onClose={handleCloseCompanyModal}
          open={openCompanyModal}
        />
      )}
    </Card>
  );
};
