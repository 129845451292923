import { ReactElement } from 'react';

import { Box, LinearProgress } from '@mui/material';
import {
  SingleFieldListProps,
  RecordContextProvider,
  useCreatePath,
  useListContext,
  useResourceContext,
} from 'react-admin';
import { Link } from 'react-router-dom';

type Props = Omit<SingleFieldListProps, 'children'> & {
  children: (context: { resource: string; record: any }) => ReactElement;
};

export function AppSingleFieldList({
  children,
  linkType = 'show',
  component: Component = Box,
  ...restProps
}: Props): JSX.Element {
  const { data, isLoading } = useListContext();
  const resource = useResourceContext();
  const createPath = useCreatePath();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!data?.length) {
    return <Component>—</Component>;
  }

  return (
    <Component {...restProps}>
      {data.map((record, rowIndex) => {
        const resourceLinkPath = !linkType
          ? false
          : createPath({
              resource,
              type: linkType,
              id: record.id,
            });
        return (
          <RecordContextProvider key={record.id ?? `row${rowIndex}`} value={record}>
            {resourceLinkPath ? (
              <Link to={resourceLinkPath}>{children({ resource, record })}</Link>
            ) : (
              children({ resource, record })
            )}
          </RecordContextProvider>
        );
      })}
    </Component>
  );
}
