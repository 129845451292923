import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { DeleteButton, CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserPage } from 'shared/react-admin/Pages';
import { ApiClient, Permission } from 'types';

import { ApiClientsEditForm } from './ApiClientsEditForm';

export const ApiClientsEdit: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const navigate = useNavigate();
  const { record } = useEditController();
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<ApiClient>();
  const transform = (data: RaRecord): RaRecord => {
    return {
      ...data,
      merchant: data.merchant || null,
    };
  };

  return (
    <EditGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" label="Сохранить" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Клиенты API"
      mutationOptions={{ onError: () => undefined }}
      rightActionsSlot={
        hasResourcePermissions('api_clients', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
        ) : undefined
      }
      title="Редактирование клиента API"
      transform={transform}
    >
      <CardContent>
        <ApiClientsEditForm />
      </CardContent>
      <DeleteDialog
        details={`Клиент API ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={record}
        title="Удаление клиента API"
      />
    </EditGuesserPage>
  );
};
