import { useGetList } from 'react-admin';

import { UNLIMITED_PAGINATION_PARAMS } from '../../../constants';

export function useHold(): {
  getHoldOptionText(value: number, currencyId: string): string;
} {
  const { data: currencies } = useGetList('currencies', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
    sort: { field: 'id', order: 'ASC' },
  });

  function getHoldOptionText(value: number, currencyId: string): string {
    return `${value}, ${currencies?.find(({ id }) => id === currencyId)?.alphaCode}`;
  }

  return {
    getHoldOptionText,
  };
}
