import { EMPTY_FIELD } from 'constants/emptyField';

import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useListContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';

import { ApiClientsListLinkedChip } from './ApiClientsListLinkedChip';
import { ProviderAccount } from '../../../types';

const CHIPS_COUNT = 3;

const StyledChip = styled(Chip)(({ isLast }: { isLast: boolean }) => ({
  marginBottom: isLast ? '0' : '12px',
}));

const renderChips = (chips: ProviderAccount[]) =>
  chips.map(({ name }, index) => (
    <StyledChip isLast={index === chips.length - 1} key={name} label={name} />
  ));

export const ApiClientsListChips = (): JSX.Element => {
  const { data, isLoading } = useListContext<ProviderAccount>();
  if (isLoading) {
    return <LinearProgress />;
  }

  if (!data.length) {
    return <span>{EMPTY_FIELD}</span>;
  }

  return (
    <Box display="flex" flexDirection="column">
      {renderChips(data.slice(0, CHIPS_COUNT))}
      {data.length > CHIPS_COUNT && (
        <ApiClientsListLinkedChip label={`+${data.length - CHIPS_COUNT}`} />
      )}
    </Box>
  );
};
