import { useMemo } from 'react';

import { hydraSchemaAnalyzer } from '@api-platform/admin';
import { GatewayRulesForm } from 'components/Common/Forms/GatewayRulesForm';
import { GatewayRulesFormValues } from 'components/Common/Forms/GatewayRulesForm/hooks/useGatewayRulesForm';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import _ from 'lodash';
import { HttpError, useDataProvider, useGetMany, useGetOne, useNotify } from 'react-admin';
import { useParams } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card/CardActionsButtons/CardActionsButtons';
import { SaveButton } from 'shared/react-admin/Buttons';
import { PageLoading } from 'shared/react-admin/Loading';
import { EditPage } from 'shared/react-admin/Pages/EditPage/EditPage';
import { getId } from 'utils';

import { GatewayRuleRemoveButton } from './components/GatewayRuleRemoveButton';
import { useConditionGroups, useExpressionParams } from './hooks';
import { getMappingItemsIds } from './utils';
import { getInitialEditFormValues } from './utils/getInitialEditFormValues';
import { GatewayRulesFormProvider } from '../Common/Forms/GatewayRulesForm/context/GatewayRulesFormContext';

export const GatewayRulesEdit = (): JSX.Element => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { toRoot } = useNavigateToRoot();

  const { id } = useParams();
  const { data } = useGetOne('gateway_rules', { id });

  const { data: mappings = [], isLoading: isLoadingMappings } = useGetMany('mappings', {
    ids: data?.mappings,
  });
  const { data: mappingItems = [] } = useGetMany('mapping_items', {
    ids: getMappingItemsIds(mappings),
  });
  const { expressionParams, loadingExpressionParams } = useExpressionParams();
  const { conditionGroup, isLoadingConditionGroup } = useConditionGroups(data?.conditionGroup);

  const defaultValues = useMemo<GatewayRulesFormValues>(() => {
    return getInitialEditFormValues({
      gatewayRule: data,
      mappingItems,
      mappings,
      conditionGroup,
    });
  }, [data, conditionGroup, mappings, mappingItems]);

  async function onSubmit(appGatewayRulesFormValues: GatewayRulesFormValues) {
    try {
      if (!id) {
        return;
      }
      const modifiedData = _.cloneDeepWith(appGatewayRulesFormValues, (value, key) => {
        if (key === 'comparisonOperator' && _.isPlainObject(value) && _.has(value, 'id')) {
          return `/admin/comparison_operators/${value.id}`;
        }

        return undefined;
      });

      await dataProvider.update('gateway_rules', {
        id,
        data: {
          id: parseInt(getId(id), 10),
          conditionGroup: null,
          ...modifiedData,
        },
        previousData: data,
      });

      notify('Правило отредактировано!', { type: 'info' });
      toRoot();
    } catch (error) {
      const violations = hydraSchemaAnalyzer().getSubmissionErrors(error as HttpError);
      notify(
        violations
          ? `Ошибка: ${Object.values(violations).join('\n')}`
          : `Ошибка:  ${(error as Error).message}`,
        {
          type: 'error',
          multiLine: true,
        },
      );
    }
  }

  if (isLoadingConditionGroup || isLoadingMappings) {
    return <PageLoading />;
  }

  return (
    <EditPage listName="Правила" title="Редактирование правила" width="auto">
      <GatewayRulesFormProvider
        value={{
          loadingExpressionParams,
          expressionParams,
        }}
      >
        <GatewayRulesForm defaultValues={defaultValues} onSubmit={onSubmit}>
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButton key="save-button" label="Сохранить правило" />,
              <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
            ]}
            rightActionsSlot={
              <GatewayRuleRemoveButton
                id={data?.id || ''}
                merchantId={data?.merchant || ''}
                originId={data?.originId || ''}
                render={({ onClick }) => {
                  return (
                    <DeleteButton label="Удалить правило" onClick={onClick} variant="outlined" />
                  );
                }}
              />
            }
          />
        </GatewayRulesForm>
      </GatewayRulesFormProvider>
    </EditPage>
  );
};
