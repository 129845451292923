import { styled } from '@mui/material/styles';
import { JsonEditor } from 'json-edit-react';

export const JsonEditorStyled = styled(JsonEditor)({
  fontSize: '14px',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  '& .jer-value-and-buttons': {
    width: '100%',
  },
  '& .jer-input-component': {
    width: '80%',
  },
});
