import { useEffect, useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartData,
} from 'chart.js';
import { formatDistance } from 'date-fns';
import { useLocalDatagridPagination } from 'hooks/useLocalDatagridPagination';
import { ListToolbar, TopToolbar } from 'react-admin';
import { Line } from 'react-chartjs-2';
import { PageHeader } from 'shared/mui/PageHeader';
import { TablePagination } from 'shared/mui/Table';
import { SelectColumnButton } from 'shared/react-admin/Table/SelectColumnButton/SelectColumnButton';

import { defaultOptions } from './config';
import { useGetOrdersMonitoring } from './hooks/useGetOrdersMonitoring';
import { useGetOrdersMonitoringChartData } from './hooks/useGetOrdersMonitoringChartData';
import { useInitialDates } from './hooks/useInitialDates';
import { BoxStyled } from './OrdersMonitoringList.styled';
import { OrdersMonitoringListChartBox } from './OrdersMonitoringListChartBox';
import { OrderMonitoringListFilter } from './OrdersMonitoringListFilter';
import { OrdersMonitoringListTable } from './OrdersMonitoringListTable';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export type OrderMonitoringListFormattedFilter = {
  dateFrom: string;
  dateTo: string;
  directionId?: number | null;
  direction?: string | null;
  gatewayId?: number | null;
};

export type OrderMonitoringListFilterValues = {
  dateFrom: Date;
  dateTo: Date;
  direction?: string | null;
  gateway?: string | null;
};

export const ORDERS_MONITORING_DATE_LIMIT = 31;

const PostListActions = () => (
  <TopToolbar>
    <SelectColumnButton />
  </TopToolbar>
);

export const OrdersMonitoringList: React.FC = () => {
  const { dateFrom, dateTo } = useInitialDates();

  const [appliedFilters, setAppliedFilters] = useState<OrderMonitoringListFilterValues>({
    dateFrom,
    dateTo,
  });

  const [isDayDistance, setIsDayDistance] = useState(true);

  const { ordersMonitoringList, isLoading, refetch } = useGetOrdersMonitoring(appliedFilters);
  const chartData = useGetOrdersMonitoringChartData(
    ordersMonitoringList?.orderStatistic,
    isDayDistance,
  );

  const { dataPerPage, setPage, ...paginationProps } = useLocalDatagridPagination({
    data: ordersMonitoringList?.orderStatistic,
    defaultPage: 0,
    defaultRowsPerPage: 10,
  });

  function onSubmitClick(filter: OrderMonitoringListFilterValues) {
    setAppliedFilters(filter);
  }

  useEffect(() => {
    if (Object.keys(appliedFilters).length > 0) {
      refetch();
      setPage(0);
      setIsDayDistance(
        formatDistance(appliedFilters.dateFrom, appliedFilters.dateTo) === '1 day' ||
          (appliedFilters.dateFrom.getMonth() === appliedFilters.dateTo.getMonth() &&
            appliedFilters.dateFrom.getDate() === appliedFilters.dateTo.getDate()),
      );
    }
  }, [appliedFilters]);

  const total = ordersMonitoringList?.orderStatistic?.length || 0;

  return (
    <BoxStyled>
      <PageHeader
        headerTitle={{
          titleText: 'Мониторинг заказов',
          tooltipText: 'Мониторинг заказов системы',
        }}
      />
      <OrderMonitoringListFilter
        appliedFilters={appliedFilters}
        defaultDateFrom={dateFrom}
        defaultDateTo={dateTo}
        onSubmitClick={onSubmitClick}
        setFilters={setAppliedFilters}
      />
      <OrdersMonitoringListChartBox>
        <Line
          data={chartData as ChartData<'line'>}
          options={defaultOptions}
          style={{ maxHeight: '50vh' }}
        />
      </OrdersMonitoringListChartBox>
      <ListToolbar actions={<PostListActions />} />
      <OrdersMonitoringListTable
        dataPerPage={dataPerPage}
        isDayDistance={isDayDistance}
        isLoading={isLoading}
      />
      <TablePagination count={total} {...paginationProps} />
    </BoxStyled>
  );
};
