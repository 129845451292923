import { RoleEntity } from 'constants/roles';

import { fromUnixTime, getUnixTime } from 'date-fns';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { DeleteIconButton } from 'shared/mui/IconButtons';
import { CalendarTodayIcon } from 'shared/mui/Icons';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

import { StyledBox } from './UserEntityPermissionsForm.styled';

type Props = {
  id?: number;
  index: number;
  onRemoveField: (index: number) => void;
};

export const UserEntityPermissionsFormPermission: React.FC<Props> = ({
  id,
  index,
  onRemoveField,
}) => {
  const { control } = useFormContext();

  const formatDate = (date: number | null): Date | null => {
    if (typeof date === 'number') {
      return fromUnixTime(date);
    }
    return date;
  };

  return (
    <StyledBox>
      <ReferenceInput
        filter={{ roleEntities: [RoleEntity.Tech], enabled: true }}
        perPage={50}
        reference="users"
        source={`permissions.${index}.user`}
      >
        <AutocompleteInputShrink
          disabled={!!id}
          filterToQuery={(searchText: string) => ({ fullName: searchText })}
          fullWidth
          helperText={false}
          label="Ответственный"
          optionText={(record: User) => record && `${record.firstName} ${record.lastName}`}
          size="medium"
          variant="outlined"
        />
      </ReferenceInput>
      <Controller
        control={control}
        name={`permissions.${index}.expiredAt`}
        render={({ field }) => (
          <DatePicker
            customInput={
              <TextFieldShrink
                InputProps={{
                  endAdornment: <CalendarTodayIcon />,
                }}
                fullWidth
                label="Срок окончания доступа"
                size="medium"
                value={formatDate(field.value)}
                variant="outlined"
              />
            }
            dateFormat="dd/MM/yyyy HH:mm"
            disabled={!!id}
            locale="ru"
            onChange={(date: Date) => {
              field.onChange(getUnixTime(date));
            }}
            portalId="root"
            selected={formatDate(field.value)}
            showTimeInput
            timeInputLabel="Время"
          />
        )}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
        <DeleteIconButton onClick={() => onRemoveField(index)} />
      </Box>
    </StyledBox>
  );
};
