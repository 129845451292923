import { Identifier, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { UserEntityPermissionsForm } from './components/UserEntityPermissionsForm/UserEntityPermissionsForm';
import { UserEntityPermissionsNames } from './constants/userEntityPermissionsNames';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  recordName: string;
  recordId: string;
  entityName: UserEntityPermissionsNames;
};

export type UserEntityPermission = {
  id?: number;
  entity: UserEntityPermissionsNames;
  rowId: number;
  user: Identifier | null;
  expiredAt: number | null;
};
export type UserEntityPermissionFormValues = {
  permissions: UserEntityPermission[];
};

export const UserEntityPermissionsCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  recordName,
  recordId,
  entityName,
}) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const form = useForm({
    defaultValues: {
      permissions: [
        {
          user: null,
          expiredAt: null,
          entity: entityName,
          rowId: Number(recordId),
        },
      ],
    },
  });

  const onSubmit = async (formValues: UserEntityPermissionFormValues) => {
    try {
      await Promise.all(
        formValues?.permissions.map((formValue) =>
          dataProvider.create('user_entity_permissions', {
            data: formValue,
          }),
        ),
      );
      onClose();
      refresh();
      notify('Все настройки доступа успешно созданы!', { type: 'success' });
    } catch (error) {
      notify((error as Error)?.message || 'Ошибка сервера', { type: 'error' });
    }
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title={title}
          />
          <CardContent sx={{ paddingTop: '12px !important' }}>
            <Alert severity="warning" sx={{ marginBottom: '24px' }}>
              {`После подтверждения включения доступа к настройкам, запись ${recordName} будет
            доступна к редактированию для выбранного пользователя. Ограничение доступа будет
            отключено автоматически в соответствии с указанным сроком окончания доступа.`}
            </Alert>
            <UserEntityPermissionsForm
              entityName={entityName}
              recordId={recordId}
              recordName={recordName}
            />
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Подтвердить" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
