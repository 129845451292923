import { styled } from '@mui/material/styles';
import { ChipField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { Datagrid } from '../../../../shared/react-admin/Datagrids';
import { getColumnStyle } from '../../../../utils';

export const StyledDatagrid = styled(Datagrid)({
  '& .column-name': getColumnStyle(250),
  '& .column-merchants': getColumnStyle(250),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});

export const StyledChipFieldLink = styled(ChipField)({
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});
