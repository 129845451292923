import { Introspecter } from '@api-platform/admin';
import { SxProps, Theme } from '@mui/material';
import { InputProps, useResourceContext } from 'react-admin';

import { JsonInput } from './JsonInput';

export type JsonInputIntrospectedProps = InputProps & { sx?: SxProps<Theme> };

export const JsonInputIntrospected = (props: JsonInputIntrospectedProps): JSX.Element => {
  const resource = useResourceContext(props);

  return (
    <Introspecter component={JsonInput} includeDeprecated={true} resource={resource} {...props} />
  );
};
