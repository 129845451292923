import React from 'react';

import EventNoteIcon from '@mui/icons-material/EventNote';
import { useHistoryContext } from 'hooks/useHistoryContext';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Link, useLocation } from 'react-router-dom';

import { IconButton, IconButtonProps } from '..';

export type HistoryIconButtonProps = Omit<IconButtonProps, 'icon'>;

export const HistoryIconButton: React.FC<HistoryIconButtonProps> = (props) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const location = useLocation();
  const { baseRoute } = useHistoryContext();

  return (
    <IconButton
      component={Link}
      to={{
        pathname: `/${resource}/${encodeURIComponent(record?.id)}/show/history`,
        state: { backgroundLocation: location || baseRoute },
      }}
      {...props}
    >
      <EventNoteIcon fontSize="small" />
    </IconButton>
  );
};
