import {
  PageHeaderStyled,
  ShowStyled,
  SimpleShowLayoutStyled,
  TabbedShowLayoutStyled,
} from './ShowPageTabbed.styled';
import { PageHeaderTitleProps } from '../../../mui/PageHeader';

export type ShowPageTabbedProps = {
  headerTitle?: PageHeaderTitleProps;
  title: string;
  listName: string;
  children: React.ReactNode;
};

export const ShowPageTabbed: React.FC<ShowPageTabbedProps> = ({
  title,
  listName,
  children,
  headerTitle,
}) => (
  <>
    <PageHeaderStyled breadCrumbs={{ currentPage: title, listName }} headerTitle={headerTitle} />
    <ShowStyled actions={false}>
      <SimpleShowLayoutStyled>
        <TabbedShowLayoutStyled>{children}</TabbedShowLayoutStyled>
      </SimpleShowLayoutStyled>
    </ShowStyled>
  </>
);
