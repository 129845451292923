import React from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: Path<T>;
};

export function GatewayRulesFormRuleIsAnd<T extends FieldValues>({ name }: Props<T>): JSX.Element {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...fieldProps } }) => {
        return (
          <ToggleButtonGroup
            {...fieldProps}
            color="primary"
            exclusive
            onChange={(_event, newValue) => {
              if (newValue !== null) {
                onChange(newValue);
              }
            }}
            size="small"
            value={value}
          >
            <ToggleButton sx={{ width: 40, height: 40 }} value={true}>
              и
            </ToggleButton>
            <ToggleButton sx={{ width: 40, height: 40 }} value={false}>
              или
            </ToggleButton>
          </ToggleButtonGroup>
        );
      }}
    />
  );
}
