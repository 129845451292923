import { ReactElement, useEffect, useState } from 'react';

import { AutocompleteProps, InputLabelProps } from '@mui/material';
import { debounce } from 'lodash';
import {
  FieldTitle,
  InputHelperText,
  RaRecord,
  Validator,
  useChoicesContext,
  useInput,
} from 'react-admin';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Checkbox } from 'shared/mui/Checkbox/Checkbox';
import { Chip } from 'shared/mui/Chips';
import { TextField } from 'shared/mui/TextField/TextField';

export type AutocompleteMultiselectInputProps<
  OptionType = RaRecord,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<OptionType, true, DisableClearable, SupportCreate>,
  'renderInput' | 'options'
> & {
  optionText: string;
  name: string;
  label: string;
  optionValue?: string;
  options?: RaRecord[];
  InputLabelProps?: Partial<InputLabelProps>;
  renderChipLabel?: (record: any) => string;
  source?: string;
  validate?: Validator | Validator[];
  helperText?: string | ReactElement | false;
  disabled?: boolean;
  filterToQuery?: (searchText: string) => Record<string, any>;
  getOptionLabel?: (option: RaRecord) => string;
  isRequired?: boolean;
};

const defaultSx = {
  '& .MuiInputBase-input': {
    minWidth: '0!important',
  },
};

export const AutocompleteMultiselectInput: React.FC<AutocompleteMultiselectInputProps> = ({
  name,
  optionText,
  optionValue = 'id',
  label,
  options,
  defaultValue = [],
  size,
  InputLabelProps,
  renderChipLabel,
  getOptionLabel,
  disabled,
  filterToQuery,
  sx,
  ...restProps
}) => {
  const { allChoices, selectedChoices, setFilters, displayedFilters } = useChoicesContext();
  const [inputValue, setInputValue] = useState('');
  const [currentSelectedChoices, setCurrentSelectedChoices] = useState<RaRecord[]>([]);
  useEffect(() => {
    if (selectedChoices?.length) {
      if (!currentSelectedChoices.length) {
        setCurrentSelectedChoices(selectedChoices);
        return;
      }
      const sortedSelectedChoices = currentSelectedChoices.reduce<RaRecord[]>((arr, choice) => {
        arr.push(
          selectedChoices.find(
            (selectedChoice) => selectedChoice.id === choice.id || selectedChoice.id === choice,
          ),
        );
        return arr;
      }, []);
      setCurrentSelectedChoices(sortedSelectedChoices);
    }
  }, [selectedChoices]);

  const handleInputChange = (event: any, newInputValue: string) => {
    if (!event) {
      return;
    }
    setInputValue(newInputValue);
    const debouncedSetFilters = debounce(() => {
      setFilters(
        filterToQuery ? filterToQuery(newInputValue) : { name: newInputValue },
        displayedFilters,
      );
    }, 500);
    debouncedSetFilters();
  };

  const handleBlur = () => {
    setFilters({}, displayedFilters);
  };

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    name,
    source: name,
    ...restProps,
    defaultValue,
  });

  useEffect(() => {
    if (!field.value.length) {
      setCurrentSelectedChoices([]);
    }
  }, [field.value]);

  useEffect(() => {
    if (defaultValue) {
      setCurrentSelectedChoices(defaultValue);
    }
  }, [defaultValue.length]);

  const getChipLabel = (option: RaRecord<string>) => {
    const currentOption = currentSelectedChoices.find(
      (choice) => choice[optionValue] === option[optionValue],
    );

    if (!currentOption) {
      return '';
    }

    return renderChipLabel ? renderChipLabel(currentOption) : currentOption[optionText];
  };

  const getLabel = (option: RaRecord<string>) => {
    if (options) {
      return getOptionLabel ? getOptionLabel(option) : option[optionText];
    }

    return getChipLabel(option);
  };

  return (
    <Autocomplete
      {...field}
      {...restProps}
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={(option) => {
        return renderChipLabel ? renderChipLabel(option) : option[optionText];
      }}
      id={name}
      inputValue={inputValue}
      // @ts-ignore
      isOptionEqualToValue={(option, value) =>
        option[optionValue] === value || option[optionValue] === value[optionValue]
      }
      multiple
      onBlur={handleBlur}
      onChange={(e, data) => {
        if (options) {
          setCurrentSelectedChoices(data);
          const ids = data.map((item) => item[optionValue] || item);
          return field.onChange(ids);
        }
        if (!data.length) {
          setCurrentSelectedChoices([]);
        }
        const ids = data.map((item) => item[optionValue] || item);
        setCurrentSelectedChoices(data);
        return field.onChange(ids);
      }}
      onInputChange={handleInputChange}
      options={options || allChoices || []}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={InputLabelProps}
          error={(isTouched || isSubmitted) && invalid}
          helperText={
            restProps.helperText !== false && (
              <InputHelperText
                error={error?.message}
                helperText={restProps.helperText}
                touched={isTouched || isSubmitted}
              />
            )
          }
          label={
            <FieldTitle
              isRequired={(restProps as any)?.validate?.isRequired || restProps?.isRequired}
              label={label}
              resource={restProps.resource}
              source={name}
            />
          }
          size={size}
          sx={{ marginBottom: 0 }}
          variant="outlined"
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox checked={selected} sx={{ marginRight: '8px' }} />
            {renderChipLabel ? renderChipLabel(option) : option[optionText]}
          </li>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={options ? option[optionText] : index}
            label={getLabel(option as RaRecord<string>)}
            variant="filled"
          />
        ));
      }}
      sx={{ ...defaultSx, ...sx }}
      value={defaultValue ? currentSelectedChoices : field.value}
    />
  );
};
