import { styled } from '@mui/material/styles';

import { appColors } from '../../../../themes/variables';
import { Edit, EditProps } from '../../Edit/Edit';

export const EditStyled = styled(Edit)<EditProps & { width?: string }>(({ width }) => ({
  '& .RaEdit-main': {
    width: width ? `${width}` : 'fit-content',
    margin: '24px 0',
    border: `1px solid ${appColors.divider}`,
    borderRadius: '4px',
    '.MuiCardContent-root': {
      padding: 0,
    },
  },
}));
