import { RaRecord } from 'react-admin';
import { CancelButton } from 'shared/mui/Buttons';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserPage } from 'shared/react-admin/Pages';

import { ApiClientsCreateForm } from './ApiClientsCreateForm';
import { CardContent } from '../../../shared/mui/Card/CardContent/CardContent';

export const ApiClientsCreate: React.FC = () => {
  const transform = (data: RaRecord): RaRecord => {
    return {
      ...data,
      merchant: data.merchant || null,
    };
  };

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Клиенты Api"
      mutationOptions={{ onError: () => undefined }}
      title="Создание клиента Api"
      transform={transform}
    >
      <CardContent>
        <ApiClientsCreateForm />
      </CardContent>
    </CreateGuesserPage>
  );
};
