import { useEffect, useState } from 'react';

import RuleIcon from '@mui/icons-material/Rule';
import { Stack, Typography } from '@mui/material';
import { ListContextProvider, useGetList, UseListValue } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { BooleanField, FunctionField, ImageField, TextField } from 'shared/react-admin/Fields';

import { useOrderMethodsContext } from './hooks/useOrderMethodsContext';
import OrderMethodsFilter from './OrderMethodsFilter';
import OrderMethodsRulesShowDialog from './OrderMethodsRulesShowDialog';
import { UNLIMITED_PAGINATION_PARAMS } from '../../constants';
import { Language } from '../../constants/languages';
import { Country, Currency, Merchant, OrderMethod } from '../../types';
import { AppListContextProviderCard } from '../Common/Card';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

export type Direction = 'payout' | 'payment';

export type OrderMethodsListFilter = {
  merchant?: Merchant | null;
  currency?: Currency | null;
  direction?: Direction | null;
  customerId?: string;
  country?: Country | null;
  language?: Language | null;
  countDeposit?: string;
  appVersion?: string | null;
  domain?: string;
};

export const OrderMethodsList: React.FC = () => {
  const [filters, setFilters] = useState<OrderMethodsListFilter>({});

  const { data: merchants } = useGetList<Merchant>('merchants', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
  });
  const { data: currencies } = useGetList<Currency>('currencies', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
    sort: { field: 'id', order: 'ASC' },
    filter: { active: true },
  });
  const { data: countries } = useGetList<Country>('countries', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
  });

  const { context, refetch } = useOrderMethodsContext(filters);
  function onGenerateClick(filter: OrderMethodsListFilter) {
    setFilters(filter);
  }

  const [selectedOrderMethod, setSelectedOrderMethod] = useState<OrderMethod | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      refetch();
    }
  }, [filters]);

  return (
    <ListContextProvider value={context as UseListValue}>
      <OrderMethodsRulesShowDialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        orderMethod={selectedOrderMethod}
      />
      <AppListContextProviderCard>
        <AppPanelHeader>
          <AppPanelHeaderToolbar titleText="Проверка правил и методов" />
        </AppPanelHeader>
        <OrderMethodsFilter
          countries={countries || []}
          currencies={currencies || []}
          merchants={merchants || []}
          onGenerateClick={onGenerateClick}
          setFilters={setFilters}
        />
        <Datagrid bulkActionButtons={false}>
          <TextField label="Название метода" source="name" />
          <TextField label="Алиас" source="alias" />
          <ImageField
            emptyText="—"
            label="Лого"
            source="logo"
            sx={{
              '& .RaImageField-image': {
                maxWidth: 60,
                maxHeight: 60,
                minWidth: 60,
                minHeight: 60,
                objectFit: 'contain',
              },
            }}
          />
          <TextField label="Тип метода" source="type.name" />
          <TextField label="Шлюз" source="gateway" />
          <FunctionField
            label={
              <Stack alignItems="center" direction="row" fontSize="13px" gap={1}>
                <RuleIcon sx={{ fontSize: '18px' }} />
                <Typography variant="body1">Правила</Typography>
              </Stack>
            }
            render={(orderMethod: OrderMethod) => (
              <Button
                label="Смотреть"
                onClick={() => {
                  setSelectedOrderMethod(orderMethod);
                  setIsOpen(true);
                }}
                startIcon={<RuleIcon sx={{ fontSize: '18px' }} />}
              />
            )}
          />
          <BooleanField label="Запрос суммы" source="requestFormAmount" />
        </Datagrid>
      </AppListContextProviderCard>
    </ListContextProvider>
  );
};
