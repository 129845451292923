import { JsonInputIntrospected, TextInputIntrospected } from 'shared/react-admin/Inputs';

import { fieldWidth } from '../../themes/variables';
import { AppCreateGuesser } from '../Common/Create';

const MerchantCredentialsCreate = (): JSX.Element => {
  return (
    <AppCreateGuesser
      actions={false}
      listName="Реквизиты мерчантов"
      mutationOptions={{ onError: () => undefined }}
      title="Создание реквизита мерчанта"
    >
      <TextInputIntrospected
        label="Название"
        source="name"
        sx={fieldWidth.medium}
        variant="outlined"
      />
      <JsonInputIntrospected label="Настройки" source="credentials" />
    </AppCreateGuesser>
  );
};

export default MerchantCredentialsCreate;
