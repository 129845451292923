import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { DatagridConfigurable } from 'shared/react-admin/Datagrids';

export const BoxStyled = styled(Box)({
  marginTop: '24px',
  marginBottom: '24px',
  backgroundColor: 'inherit',
});

const columnStyle = {
  width: '120px',
  maxWidth: '120px',
  minWidth: '120px',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
};

export const DatagridConfigurableStyled = styled(DatagridConfigurable)({
  width: '100%',
  '& .MuiTableCell-head': {
    verticalAlign: 'top',
    padding: '16px',
    lineHeight: '18px',
    minWidth: 140,
  },
  '& .MuiTableCell-body': {
    verticalAlign: 'top',
    padding: '16px',
  },
  '& .column-period': {
    width: '140px',
    maxWidth: '140px',
  },
  '& .column-typical': columnStyle,
});
