import { useCallback, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from 'shared/mui/Buttons';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { Typography } from 'shared/mui/Typography';

import { BoxCollapsed, BoxNotCollapsed } from './OrdersMonitoringListChartBox.styled';

export function OrdersMonitoringListChartBox({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapsed = useCallback(() => setIsCollapsed((prev) => !prev), []);

  return (
    <FilterBox>
      {isCollapsed ? (
        <BoxCollapsed>
          <Typography fontSize={16}>График мониторинга заказов</Typography>
          <Button
            endIcon={<ArrowDownwardIcon />}
            label="Развернуть график"
            onClick={toggleCollapsed}
            size="small"
            sx={{ minWidth: 'fit-content' }}
          />
        </BoxCollapsed>
      ) : (
        <>
          <BoxNotCollapsed>
            <Typography fontSize={16}>График мониторинга заказов</Typography>
            <Button
              endIcon={<ArrowUpwardIcon />}
              label="Свернуть график"
              onClick={toggleCollapsed}
              size="small"
            />
          </BoxNotCollapsed>
          {children}
        </>
      )}
    </FilterBox>
  );
}
