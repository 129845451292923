import { useFlag } from '@unleash/proxy-client-react';
import { useRedirect, useShowController } from 'react-admin';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ShowPage } from 'shared/react-admin/Pages';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';

import { ProviderAccountsShowTable } from './ProviderAccountsShowTable';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useRecordModal } from '../../../../hooks/useRecordModal';
import { Company, Permission, Provider, ProviderAccount } from '../../../../types';

export const ProviderAccountsShow = () => {
  // ToDo при удалении флага wppa_6258_provider_account_new - убрать resource и оставить provider_accounts
  const isProviderAccountNew = useFlag('wppa_6258_provider_account_new');
  const resource = isProviderAccountNew ? 'provider_account_news' : 'provider_accounts';
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();
  const redirect = useRedirect();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<ProviderAccount>();

  const {
    record: company,
    handleCloseRecordModal: handleCloseCompanyModal,
    openRecordModal: openCompanyModal,
    handleOpenRecordModal: handleOpenCompanyModal,
  } = useRecordModal<Company>();

  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();

  return (
    <ShowPage
      actions={
        hasResourceAccess('user_actions')
          ? [<UserActionsIconButton entityName={resource} key="user-action-link" />]
          : undefined
      }
      leftActionsSlot={[
        hasResourcePermissions(resource, [Permission.Full, Permission.Update]) && (
          <EditLinkButton key="edit-button" label="Редактировать" />
        ),
      ]}
      listName="Личные кабинеты"
      rightActionsSlot={[
        hasResourcePermissions(resource, [Permission.Full, Permission.Delete]) && (
          <DeleteButton
            key="delete-button"
            onClick={() => handleOpenRecordModal(record)}
            variant="outlined"
          />
        ),
      ]}
      title="Личный кабинет"
    >
      <ProviderAccountsShowTable
        handleOpenCompanyModal={handleOpenCompanyModal}
        handleOpenRecordModalProvider={handleOpenRecordModalProvider}
      />
      <DeleteDialog
        details={`Личный кабинет ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => redirect('list', resource)}
        open={openRecordModal}
        record={record}
        title="Удаление личного кабинета"
      />
      {company && (
        <CompanyShowDialog
          company={company}
          onClose={handleCloseCompanyModal}
          open={openCompanyModal}
        />
      )}
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseRecordModalProvider}
          open={openRecordModalProvider}
          provider={provider}
        />
      )}
    </ShowPage>
  );
};
