import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { Role } from '../../../constants/roles';
import { useFilters } from '../../../hooks/useFilters';

type ApiClientsFilterValues = { name: string; merchants: string; roles: string };

export const ApiClientsListFilters: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<ApiClientsFilterValues>({
    name: '',
    merchants: '',
    roles: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              key="name"
              label="Название"
              resettable
              source="name"
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={[
                { id: Role.Merchant, name: 'Мерчант' },
                { id: Role.Service, name: 'Сервис' },
              ]}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              helperText={false}
              label="Доступ"
              optionText="name"
              source="roles"
              variant="outlined"
            />
            <ReferenceInput key="merchants" perPage={200} reference="merchants" source="merchants">
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Мерчанты"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
