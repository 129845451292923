import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserPage } from 'shared/react-admin/Pages';
import { Merchant, Permission } from 'types';

import { MerchantsEditForm } from './MerchantsEditForm';

export const MerchantsEdit: React.FC = () => {
  const { record } = useEditController();
  const { hasResourcePermissions } = usePermissions();
  const { toRoot } = useNavigateToRoot();

  const {
    record: method,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<Merchant>();

  return (
    <EditGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" label="Сохранить" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Мерчанты"
      mutationOptions={{
        onError: () => undefined,
      }}
      rightActionsSlot={
        hasResourcePermissions('merchants', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal(method)} variant="outlined" />
        ) : undefined
      }
      sx={{ marginTop: 0, padding: '0!important' }}
      title="Редактирование мерчанта"
    >
      <DeleteDialog
        details={`Мерчант ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={toRoot}
        open={openRecordModal}
        record={record}
        title="Удаление мерчанта"
      />
      <CardContent sx={{ paddingBottom: '0!important' }}>
        <MerchantsEditForm />
      </CardContent>
    </EditGuesserPage>
  );
};
