import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, required, useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { AutocompleteInputIntrospected, TextInput } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Hold, Permission } from 'types';

import { validateNumber } from './utils/validateNumber';
import { fieldWidth } from '../../themes/variables';
import { AppEditGuesser } from '../Common/Edit';

const HoldsEdit: React.FC = () => {
  const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
    return {
      ...data,
      value: Number(data.value),
    };
  };
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: hold,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<Hold>();

  return (
    <AppEditGuesser
      actions={false}
      listName="Холды"
      title="Редактирование холда"
      toolbar={
        <>
          <DeleteDialog
            details="Холд будет удален без возможности восстановления."
            onClose={handleCloseRecordModal}
            onSuccess={() => navigate(-1)}
            open={openRecordModal}
            record={hold}
            title="Удаление холда"
          />
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
              <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('holds', [Permission.Full, Permission.Delete]) && (
                <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
              )
            }
          />
        </>
      }
      transform={transform}
    >
      <TextInput
        label="Значение"
        size="medium"
        source="value"
        sx={fieldWidth.medium}
        validate={[validateNumber, required()]}
        variant="outlined"
      />
      <ReferenceInput
        perPage={1000}
        reference="currencies"
        sort={{ field: 'id', order: 'ASC' }}
        source="currency"
      >
        <AutocompleteInputIntrospected
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Валюта"
          optionText={(record: RaRecord) => record && `${record.alphaCode}, ${record.name}`}
          size="medium"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
    </AppEditGuesser>
  );
};

export default HoldsEdit;
