import { RaRecord, required } from 'react-admin';
import { AutocompleteInputIntrospected, TextInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { validateNumber } from './utils/validateNumber';
import { fieldWidth } from '../../themes/variables';
import { AppCreateGuesser } from '../Common/Create';

const HoldsCreate = (): JSX.Element => {
  const transform = (data: RaRecord): RaRecord | Promise<RaRecord> => {
    return {
      ...data,
      value: Number(data.value),
    };
  };

  return (
    <AppCreateGuesser actions={false} listName="Холды" title="Создание холда" transform={transform}>
      <TextInputIntrospected
        label="Значение"
        size="medium"
        source="value"
        sx={fieldWidth.medium}
        validate={[validateNumber, required()]}
        variant="outlined"
      />
      <ReferenceInput
        perPage={1000}
        reference="currencies"
        sort={{ field: 'id', order: 'ASC' }}
        source="currency"
      >
        <AutocompleteInputIntrospected
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Валюта"
          optionText={(record: RaRecord) => record && `${record.alphaCode}, ${record.name}`}
          size="medium"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
    </AppCreateGuesser>
  );
};

export default HoldsCreate;
