import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Modal } from '@mui/material';
import { DeleteButton } from 'shared/mui/Buttons';
import { Merchant } from 'types';

import { modalBase } from '../../../../themes/variables';
import { AppButton } from '../../../Common/Buttons';

type Props = {
  open: boolean;
  onClose: () => void;
  merchant: Merchant;
  onConfirm: () => void;
  originId: string;
};

export function GatewayRuleModalDelete({
  merchant,
  open,
  onClose,
  onConfirm,
  originId,
}: Props): JSX.Element {
  return (
    <Modal onClose={onClose} open={open}>
      <Box sx={{ ...modalBase, maxWidth: '600px', top: '45%' }}>
        <Card>
          <CardHeader
            action={
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
            sx={{
              px: 3,
              py: 2,
            }}
            title="Удаление правила"
          />
          <CardContent
            sx={{
              px: 3,
              py: 2,
            }}
          >
            {`Правило ID:${originId} для мерчанта ${merchant?.name} будет удалено без возможности восстановления.`}
          </CardContent>
          <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
            <AppButton label="Отменить" onClick={onClose} />
            <DeleteButton onClick={onConfirm} />
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
}
