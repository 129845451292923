import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, IconButtonProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const GatewayRulesFormRemoveButton: React.FC<IconButtonProps> = ({
  sx,
  color = 'error',
  ...restProps
}: IconButtonProps) => {
  const defaultSx: SxProps<Theme> = {
    width: '32px',
    height: '32px',
  };

  return (
    <IconButton color={color} sx={{ ...defaultSx, ...sx }} {...restProps}>
      <CloseIcon
        sx={{
          fontSize: '24px',
        }}
      />
    </IconButton>
  );
};
