import { useMemo } from 'react';

import { ChartData, ChartDataset } from 'chart.js';
import { format } from 'date-fns';

import { OrderMonitoringStatistic, OrderMonitoringStatisticValue } from '../../../../types';
import { defaultDatasets } from '../config';

export function useGetOrdersMonitoringChartData(
  orderMonitoringStatistic: OrderMonitoringStatistic[] | undefined,
  isDayDistance: boolean,
): ChartData {
  const ordersMonitoringChartData = useMemo(() => {
    const datasets: Record<string, ChartDataset> = {};
    const labels: string[] = [];
    orderMonitoringStatistic?.forEach((statistic) => {
      const formattedTimeLabel = isDayDistance
        ? format(new Date(statistic.period.dateTo), 'HH:mm')
        : format(new Date(statistic.period.dateFrom), 'dd/MM');
      labels.push(formattedTimeLabel);

      for (const [key, value] of Object.entries(statistic)) {
        const typedKey = key as keyof typeof defaultDatasets;
        if (datasets[typedKey]?.data.length >= labels.length) {
          return;
        }
        if (defaultDatasets[typedKey] && !datasets[typedKey]) {
          datasets[typedKey] = { ...defaultDatasets[typedKey], data: [] };
        }
        datasets[typedKey]?.data.push((value as OrderMonitoringStatisticValue).count);
      }
    });

    return { labels, datasets: Object.values(datasets) };
  }, [orderMonitoringStatistic]);

  return ordersMonitoringChartData;
}
