import { InputGuesser } from '@api-platform/admin';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { AutocompleteInputIntrospected, JsonInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Permission } from 'types';

import { useMerchantAlertsValidation } from './hooks/useMerchantAlertsValidation';
import { fieldWidth } from '../../themes/variables';
import { AppNumberInput } from '../Common';
import { AppEditGuesser } from '../Common/Edit';

const MerchantAlertsEdit: React.FC = () => {
  const { validatePercentFailure } = useMerchantAlertsValidation();
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: merchantAlert,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<RaRecord>();

  const transform = (data: RaRecord): RaRecord => {
    return {
      ...data,
      type: 'reddy',
    };
  };

  return (
    <AppEditGuesser
      actions={false}
      listName="Оповещения мерчантов"
      title="Редактирование оповещения мерчанта"
      toolbar={
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('merchant_alerts', [Permission.Full, Permission.Delete]) && (
              <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
            )
          }
        />
      }
      transform={transform}
    >
      <ReferenceInput perPage={200} reference="merchants" source="merchant">
        <AutocompleteInputIntrospected
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Мерчант"
          optionText="name"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
      <InputGuesser disabled label="Тип" source="type" variant="outlined" />
      <JsonInputIntrospected isRequired label="Доступы" source="credentials" />
      <InputGuesser label="Активно" source="active" />
      <AppNumberInput
        label="Процент успешных транзакций"
        source="percentFailure"
        sx={fieldWidth.medium}
        validate={validatePercentFailure}
        variant="outlined"
      />
      <DeleteDialog
        details="Оповещение мерчанта будет удалено без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={merchantAlert}
        title="Удаление оповещения мерчанта"
      />
    </AppEditGuesser>
  );
};

export default MerchantAlertsEdit;
