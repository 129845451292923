import { TextField } from 'react-admin';
import { HistoryIconButton } from 'shared/mui/IconButtons';
import { JsonField } from 'shared/react-admin/Fields';

import { AppShowGuesser } from '../Common/Show';

const MerchantCredentialsShow = (): JSX.Element => {
  return (
    <AppShowGuesser
      actions={false}
      listName="Реквизиты мерчантов"
      title="Просмотр реквизита мерчанта"
      toolbarActions={[<HistoryIconButton key="history-button" />]}
    >
      <TextField label="Id" source="originId" />
      <TextField label="Название" source="name" />
      <JsonField label="Настройки" source="credentials" />
    </AppShowGuesser>
  );
};

export default MerchantCredentialsShow;
