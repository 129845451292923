import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { OrderMonitoring } from 'types';

import { getOrdersMonitoringList } from '../../../../config/requests';
import { getId } from '../../../../utils';
import { OrderMonitoringListFilterValues } from '../OrdersMonitoringList';

type UseGetOrdersMonitoringListResult = {
  ordersMonitoringList: OrderMonitoring;
  isLoading: boolean;
  refetch: () => void;
};

export function useGetOrdersMonitoring(
  filter: OrderMonitoringListFilterValues,
): UseGetOrdersMonitoringListResult {
  const formattedFilter = {
    direction: filter.direction ? getId(filter.direction) : null,
    gatewayId: filter.gateway ? Number(getId(filter.gateway)) : null,
    dateFrom:
      typeof filter.dateFrom === 'object'
        ? `${format(filter.dateFrom, 'yyyy-MM-dd')}T00:00:00+00:00`
        : filter.dateFrom,
    dateTo:
      typeof filter.dateTo === 'object'
        ? `${format(filter.dateTo, 'yyyy-MM-dd')}T23:59:59+00:00`
        : filter.dateTo,
  };

  const {
    data: ordersMonitoringList,
    isLoading,
    refetch,
  } = useQuery(['order_monitoring'], () => getOrdersMonitoringList(formattedFilter), {
    enabled: true,
  });

  return { ordersMonitoringList, isLoading, refetch };
}
