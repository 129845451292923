import { useRecordModal } from 'hooks/useRecordModal';
import {
  RecordContextProvider,
  ShowBase,
  useCreatePath,
  useGetList,
  useResourceContext,
  useShowController,
} from 'react-admin';
import { Box } from 'shared/mui/Box';
import { LinkChip } from 'shared/mui/Chips';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { FunctionField, SingleFieldList, TextField } from 'shared/react-admin/Fields';
import { Link } from 'shared/react-admin/Link/Link';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { CommissionShowDialog } from 'shared/widgets/CommissionShowDialog/CommissionShowDialog';
import { GatewayRuleActiveSwitch } from 'shared/widgets/GatewayRuleActiveSwitch/GatewayRuleActiveSwitch';

import { GatewayRulesShowCardHeader } from './GatewayRulesShowCardHeader';
import { UNLIMITED_PAGINATION_PARAMS } from '../../../constants';
import { usePermissions } from '../../../hooks/usePermissions';
import { Button, CancelButton, DeleteButton } from '../../../shared/mui/Buttons';
import { CardActionsButtons } from '../../../shared/mui/Card';
import { CardHeader } from '../../../shared/mui/Card';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { layoutBase } from '../../../themes/variables';
import { Commission, MappingRecord, Permission } from '../../../types';
import { AppBreadCrumbs } from '../../Common/BreadCrumbs';
import { GatewayRuleMapping } from '../components';
import { GatewayRuleField } from '../components/GatewayRuleField';
import { GatewayRuleInfo } from '../components/GatewayRuleInfo';
import { GatewayRulePercentOfTrafficField } from '../components/GatewayRulePercentOfTrafficField';
import { GatewayRuleRemoveButton } from '../components/GatewayRuleRemoveButton';

export const GatewayRulesShow: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const createPath = useCreatePath();

  const resource = useResourceContext();
  const { record, isLoading } = useShowController();

  const {
    record: commission,
    handleOpenRecordModal,
    handleCloseRecordModal,
    openRecordModal,
  } = useRecordModal<Commission>();

  const { data: mappings = [], isLoading: isLoadingMappings } = useGetList<MappingRecord>(
    'mappings',
    {
      pagination: UNLIMITED_PAGINATION_PARAMS,
      filter: { gatewayRule: record?.id },
    },
    { enabled: Boolean(record) && !isLoading },
  );

  function renderMappingFields(): JSX.Element | JSX.Element[] {
    if (isLoadingMappings) {
      return <LinearProgress />;
    }

    return mappings.map((mapping, index) => {
      return (
        <RecordContextProvider key={index} value={mapping}>
          <GatewayRuleMapping>
            {hasResourceAccess('gateways') && (
              <ReferenceOneField
                emptyText="—"
                label="Шлюз"
                link="show"
                reference="gateways"
                source="gateway"
                target="id"
              >
                <TextField source="name" />
              </ReferenceOneField>
            )}
            {hasResourceAccess('merchant_accounts') && (
              <ReferenceOneField
                label="Счёт"
                link="show"
                reference="merchant_accounts"
                source="merchantAccount"
                target="id"
              >
                <TextField source="name" />
              </ReferenceOneField>
            )}
            {hasResourceAccess('commissions') && (
              <ReferenceArrayField label="Комиссии" reference="commissions" source="commissions">
                <SingleFieldList linkType={false}>
                  <FunctionField
                    render={(record: Commission) => (
                      <LinkChip
                        label={record.name}
                        onClick={() => {
                          handleOpenRecordModal(record);
                        }}
                        size="medium"
                      />
                    )}
                  />
                </SingleFieldList>
              </ReferenceArrayField>
            )}
            <GatewayRulePercentOfTrafficField label="Процент трафика" mappingRecord={mapping} />
          </GatewayRuleMapping>
        </RecordContextProvider>
      );
    });
  }

  return (
    <ShowBase resource={resource}>
      <>
        <AppBreadCrumbs currentPage="Просмотр правила" listName="Правила" />
        <Box sx={{ ...layoutBase }}>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              <GatewayRulesShowCardHeader>
                <Box alignItems="center" display="flex">
                  {hasResourcePermissions('gateway_rules', [
                    Permission.Full,
                    Permission.Update,
                  ]) && <GatewayRuleActiveSwitch />}
                  <GatewayRuleInfo />
                </Box>
                {hasResourceAccess('user_actions') && (
                  <UserActionsIconButton entityName="gateway_rule" />
                )}
              </GatewayRulesShowCardHeader>
              <Box sx={{ padding: '0 24px' }}>
                <CardHeader sx={{ paddingLeft: '0', paddingRight: '0' }} title="Условие" />
                <GatewayRuleField label="Правила" sortable={false} source="conditionGroup" />
              </Box>
              <CardHeader title="Маппинги" />
              <Box sx={{ padding: '0 24px' }}>
                <Box sx={{ marginBottom: '16px' }}>{renderMappingFields()}</Box>
              </Box>
              <CardActionsButtons
                leftActionsSlot={[
                  hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Update]) && (
                    <Button
                      component={Link}
                      key="edit-button"
                      label="Редактировать правило"
                      to={createPath({
                        resource: 'gateway_rules',
                        id: record?.id,
                        type: 'edit',
                      })}
                      variant="contained"
                    />
                  ),
                  <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
                ]}
                rightActionsSlot={
                  hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Delete]) && (
                    <GatewayRuleRemoveButton
                      id={record?.id || ''}
                      merchantId={record?.merchant || ''}
                      originId={record?.originId || ''}
                      render={({ onClick }) => {
                        return (
                          <DeleteButton
                            label="Удалить правило"
                            onClick={onClick}
                            variant="outlined"
                          />
                        );
                      }}
                    />
                  )
                }
              />
            </>
          )}
          {commission && (
            <CommissionShowDialog
              commission={commission}
              onClose={handleCloseRecordModal}
              open={openRecordModal}
            />
          )}
        </Box>
      </>
    </ShowBase>
  );
};
