import { useEffect } from 'react';

import { useFirstRender } from 'hooks/useFirstRender';
import { RaRecord, BooleanInput, FormDataConsumer } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
  AutocompleteArrayInputShrink,
  AutocompleteInput,
  AutocompleteInputIntrospected,
  JsonInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput, ReferenceInputIntrospected } from 'shared/react-admin/ReferenceInputs';
import { Hold } from 'types';

import { fieldWidth } from '../../../themes/variables';
import { AppAutocompleteArrayInput, AppReferenceArrayInput } from '../../Common';
import { useHold } from '../hooks/useHold';

export const MerchantAccountsEditForm: React.FC = () => {
  const { getHoldOptionText } = useHold();

  const { watch, setValue } = useFormContext();
  const providerFormValue = watch('provider');
  const { isFirstRender } = useFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      setValue('gateways', []);
    }
  }, [providerFormValue]);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <BooleanInput label="Активно" source="active" />
          <TextInputIntrospected
            label="Название"
            source="name"
            sx={fieldWidth.medium}
            variant="outlined"
          />
          <ReferenceInputIntrospected perPage={200} reference="providers" source="provider">
            <AutocompleteInputIntrospected
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Провайдер"
              optionText="name"
              size="medium"
              source="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <AppReferenceArrayInput
            filter={{ provider: formData.provider || '' }}
            perPage={200}
            reference="gateways"
            source="gateways"
          >
            <AppAutocompleteArrayInput
              clearOnBlur={false}
              disabled={!formData.provider || undefined}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Шлюз"
              optionText="name"
              size="medium"
              source="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </AppReferenceArrayInput>
          <ReferenceInputIntrospected
            filter={{ active: true }}
            reference="currencies"
            source="currency"
          >
            <AutocompleteInputIntrospected
              filterToQuery={(searchText: string) => ({ currency: searchText })}
              label="Валюта"
              optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
              size="medium"
              source="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <ReferenceInput reference="holds" source="hold">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Холд"
              matchSuggestion={(filter, choice: Hold) => {
                return Boolean(getHoldOptionText(choice.value, choice.currency).match(filter));
              }}
              optionText={(record: Hold) => {
                return record && getHoldOptionText(record.value, record.currency);
              }}
              size="medium"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInput>
          <ReferenceInputIntrospected perPage={200} reference="merchants" source="merchants">
            <AutocompleteArrayInputShrink
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Мерчанты"
              optionText="name"
              size="medium"
              source="name"
              sx={{
                ...fieldWidth.medium,
              }}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <ReferenceInputIntrospected reference="merchant_credentials" source="merchantCredentials">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Доступы мерчанта"
              optionText="name"
              size="medium"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <JsonInputIntrospected label="Доступы счёта" source="credentials" />
        </>
      )}
    </FormDataConsumer>
  );
};
