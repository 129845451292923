import { EMPTY_FIELD } from 'constants/emptyField';
import { UNLIMITED_PAGINATION_PARAMS } from 'constants/unlimitedPaginationParams';

import { useMemo } from 'react';

import { FormControl } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import { updateProviderAccountWorkTeam } from 'config/requests';
import { useGetList, useGetOne, useNotify, useRefresh } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { GroupFieldChip } from 'shared/mui/Chips';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { InputLabel } from 'shared/mui/Labels/InputLabel/InputLabel';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { Typography } from 'shared/mui/Typography';

import { StyledBox } from './WorkTeamAddDialog.styled';
import { Country, ProviderAccount, WorkTeam } from '../../../../../types';

type WorkGroupAddDialogProps = {
  provider?: { providerAccount: ProviderAccount };
  onClose: () => void;
} & DialogProps;

export const WorkTeamAddDialog = ({ open, provider, onClose }: WorkGroupAddDialogProps) => {
  const isProviderAccountNew = useFlag('wppa_6258_provider_account_new');
  const notify = useNotify();
  const refresh = useRefresh();
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      provider: provider?.providerAccount?.id,
      workTeam: '',
    },
  });

  const { data: workTeams } = useGetList<WorkTeam>('work_teams', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
  });

  const { data: countries } = useGetList<Country>('countries', {
    pagination: UNLIMITED_PAGINATION_PARAMS,
  });

  const { data: currentCompany } = useGetOne('companies', {
    id: provider?.providerAccount.company,
  });

  const { data: currentProvider } = useGetOne('providers', {
    id: provider?.providerAccount.provider,
  });

  const workTeamsConfig = useMemo(
    () =>
      workTeams?.reduce<Record<string, WorkTeam>>((acc, workTeam) => {
        acc[workTeam.id] = workTeam;

        return acc;
      }, {}),
    [workTeams?.length],
  );
  const countriesConfig = useMemo(
    () =>
      countries?.reduce<Record<string, string>>((acc, { id, name }) => {
        acc[id] = name;

        return acc;
      }, {}),
    [countries?.length],
  );

  const onSubmit = () => {
    if (provider?.providerAccount.id) {
      updateProviderAccountWorkTeam(
        provider.providerAccount.originId,
        getValues().workTeam,
        isProviderAccountNew,
      )
        .then(() => {
          refresh();
          onClose();
        })
        .catch((err) => {
          notify(err.data.detail, { type: 'error' });
        });
    }
  };

  if (!workTeamsConfig || !countriesConfig) {
    return <></>;
  }

  const providerName = `${currentCompany.name} | ${currentProvider.name}`;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Назначить рабочую группу"
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ marginBottom: '16px' }} variant="outlined">
              <InputLabel id="company-name">Личный кабинет</InputLabel>
              <Select
                disabled
                label="Личный кабинет"
                labelId="company-name"
                size="medium"
                value={providerName}
              >
                <MenuItem value={providerName}>{providerName}</MenuItem>
              </Select>
            </FormControl>
            <Controller
              control={control}
              name="workTeam"
              render={({ field }) => (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="work-team" sx={{ top: '7px' }}>
                    Рабочая группа
                  </InputLabel>
                  <Select
                    label="Рабочая группа"
                    labelId="work-team"
                    onChange={field.onChange}
                    renderValue={(selected: string) => (
                      <GroupFieldChip group={workTeamsConfig[selected]} />
                    )}
                    size="medium"
                    value={field?.value}
                  >
                    {workTeams?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography component="p" variant="body2">
                            {item.name}
                          </Typography>
                          <Typography
                            color="secondary"
                            component="p"
                            sx={{
                              width: 'calc(600px - 48px * 2)',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            variant="body2"
                          >
                            {item.countries && countriesConfig
                              ? item.countries.map((id) => countriesConfig[id]).join(', ')
                              : EMPTY_FIELD}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </form>
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <Button key="save-button" label="Сохранить" onClick={onSubmit} variant="contained" />,
            <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
          ]}
        />
      </StyledBox>
    </Dialog>
  );
};
