import React from 'react';

import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

type MerchantsShowCredentialsFilterValues = { name: string | null };

const INITIAL_VALUES = {
  name: null,
};

export const MerchantsShowCredentialsFilters: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantsShowCredentialsFilterValues>(
    INITIAL_VALUES,
    INITIAL_VALUES,
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow>
            <TextInputShrink
              InputLabelProps={{ shrink: true }}
              helperText={false}
              key="name"
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <ReferenceInput
              isFilter={true}
              name="provider"
              perPage={200}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
