import { Expression } from 'components/Common/Forms/GatewayRulesForm/GatewayRulesForm';
import { useExpressionParamWithData } from 'components/GatewayRules/hooks';
import { ExpressionParam } from 'types';

export function useInitialExpression(expressionParams: ExpressionParam[]): Expression {
  const expressionParam = expressionParams[0];
  const { expressionParamWithData } = useExpressionParamWithData(expressionParam?.alias);

  return {
    expressionParam,
    comparisonOperator: expressionParamWithData?.comparisonOperators[0],
    value: [],
  };
}
