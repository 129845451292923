import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { JsonInputIntrospected, TextInputIntrospected } from 'shared/react-admin/Inputs';
import { Permission } from 'types';

import { fieldWidth } from '../../themes/variables';
import { AppEditGuesser } from '../Common/Edit';

const MerchantCredentialsEdit: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: merchantCredential,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<RaRecord>();

  return (
    <AppEditGuesser
      actions={false}
      listName="Реквизиты мерчантов"
      mutationOptions={{ onError: () => undefined }}
      title="Редактирование реквизита мерчанта"
      toolbar={
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('merchant_credentials', [
              Permission.Full,
              Permission.Delete,
            ]) && <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
          }
        />
      }
    >
      <TextInputIntrospected
        label="Название"
        source="name"
        sx={fieldWidth.medium}
        variant="outlined"
      />
      <JsonInputIntrospected label="Настройки" source="credentials" />
      <DeleteDialog
        details="Реквизит мерчанта будет удален без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={merchantCredential}
        title="Удаление реквизита мерчанта"
      />
    </AppEditGuesser>
  );
};

export default MerchantCredentialsEdit;
