import { useState } from 'react';

import { queryClient } from 'App';
import { archiveCompany } from 'config/requests';
import { useRecordModal } from 'hooks/useRecordModal';
import { useNotify, useShowController } from 'react-admin';
import { Button, DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { CompanyShowDialogTable } from 'shared/widgets/CompanyShowDialog/CompanyShowDialogTable';

import { CompaniesArchiveDialog } from './components/CompaniesArchiveDialog/CompaniesArchiveDialog';
import { CompaniesDeleteDialog } from './components/CompaniesDeleteDialog/CompaniesDeleteDialog';
import { usePermissions } from '../../hooks/usePermissions';
import { UserActionsIconButton } from '../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Company, Permission } from '../../types';

export const CompaniesShow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Company>();

  const {
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Company>();

  const onArchive = async () => {
    try {
      setIsLoading(true);
      await archiveCompany(record.id, false);
      setIsLoading(false);
      notify(`Компания ${record.name} восстановлена`, { type: 'success' });
      await queryClient.refetchQueries(['companies']);
    } catch (e) {
      notify((e as Error)?.message || 'Ошибка сервера', { type: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="company" key="user-action-link" />]
            : undefined
        }
        open={!openRecordModalDelete && !openRecordModalArchive}
        title="Просмотр компании"
      >
        <CompanyShowDialogTable />
        <CardActionsButtons
          leftActionsSlot={[
            hasResourcePermissions('companies', [Permission.Full, Permission.Update]) &&
              record?.archive && (
                <Button
                  isLoading={isLoading}
                  key="archive-button"
                  label="восстановить"
                  onClick={onArchive}
                  variant="contained"
                />
              ),
            hasResourcePermissions('companies', [Permission.Full, Permission.Update]) && (
              <EditButton disabled={record?.archive} />
            ),
          ]}
          rightActionsSlot={
            hasResourcePermissions('companies', [Permission.Full, Permission.Delete]) ? (
              record?.archive ? (
                <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
              ) : (
                <DeleteButton
                  label="В архив"
                  onClick={() => handleOpenRecordModalArchive()}
                  variant="outlined"
                />
              )
            ) : undefined
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <CompaniesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
      {openRecordModalArchive && (
        <CompaniesArchiveDialog
          isToRootOnSuccess={true}
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={record}
        />
      )}
    </>
  );
};
