import { RaRecord } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';

import { StyledTypography } from './TooltipTextField.styled';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type TooltipTextFieldProps = {
  source: string;
  value?: string;
} & Omit<FunctionFieldProps, 'render'>;

export const TooltipTextField: React.FC<TooltipTextFieldProps> = ({ source, value, ...rest }) => {
  return (
    <FunctionField
      render={(record: RaRecord) => {
        return (
          <Box>
            <Tooltip title={<Typography fontSize={12}>{value || record[source]}</Typography>}>
              <StyledTypography>{value || record[source]}</StyledTypography>
            </Tooltip>
          </Box>
        );
      }}
      {...rest}
    />
  );
};
