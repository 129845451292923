import { useEffect } from 'react';

import { Card } from '@mui/material';
import { useListContext, RecordContextProvider, useRemoveFromStore } from 'react-admin';
import { Typography } from 'shared/mui/Typography';

import { GatewayRuleCard } from './GatewayRuleCard';
import { GatewayRuleRecord } from '../../../types';

type GatewayRulesDatagridProps = {
  resetExpandedRules: () => void;
};

export const GatewayRulesDatagrid: React.FC<GatewayRulesDatagridProps> = ({
  resetExpandedRules,
}) => {
  const { data = [], isLoading, resource } = useListContext<GatewayRuleRecord>();
  const remove = useRemoveFromStore();
  useEffect(() => {
    resetExpandedRules();
    return () => remove(`${resource}.listParams`);
  }, [data]);

  if (!data.length && !isLoading) {
    return (
      <Typography marginLeft={2} variant="body2">
        Результатов не найдено
      </Typography>
    );
  }

  return (
    <Card
      sx={{
        overflow: 'auto',
        backgroundColor: 'transparent',
      }}
    >
      {data.map((record: GatewayRuleRecord) => (
        <RecordContextProvider key={record.id} value={record}>
          <GatewayRuleCard />
        </RecordContextProvider>
      ))}
    </Card>
  );
};
