import { TextField } from 'react-admin';
import { CreateButtonToEntity } from 'shared/mui/Buttons';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { MerchantCredentialsFilters } from './MerchantCredentialsFilters';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types';
import { AppListEditButton, AppListShowButton } from '../Common/Buttons';
import { AppDatagrid } from '../Common/Datagrid';
import { AppList } from '../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

const MerchantCredentialsList: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <AppPanelHeader>
        <AppPanelHeaderToolbar
          actions={[
            hasResourcePermissions('merchant_credentials', [
              Permission.Full,
              Permission.Create,
            ]) && <CreateButtonToEntity entity="merchant" key="create_merchant_credentials" />,
          ]}
          titleText="Реквизиты мерчантов"
        />
      </AppPanelHeader>
      <MerchantCredentialsFilters />
      <AppDatagrid>
        <TextField label="Id" sortBy="id" source="originId" />
        <TextField label="Название" source="name" />
        <ActionsSlots
          render={() => (
            <>
              <AppListShowButton />
              {hasResourcePermissions('merchant_credentials', [
                Permission.Full,
                Permission.Update,
              ]) && <AppListEditButton />}
            </>
          )}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default MerchantCredentialsList;
