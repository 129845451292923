import { FilterChipType } from 'constants/filterChipType';
import { RoleEntity } from 'constants/roles';

import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteArrayInputShrink, AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { useFilterChips } from '../../../../hooks/useFilterChips';
import { useFilters } from '../../../../hooks/useFilters';
import { User } from '../../../../types';

type ProviderAccountsListFilterState = {
  company: string;
  provider: string;
  merchantAccounts: string[];
  workTeam: string;
  members: string[];
};

export const ProviderAccountsListFilter = (): JSX.Element => {
  const { filterValues } = useListContext();

  const { form, resetFilter, onSubmit, resetFilterBySource, resetFilterArrayById } =
    useFilters<ProviderAccountsListFilterState>({
      company: '',
      provider: '',
      merchantAccounts: [],
      workTeam: '',
      members: [],
    });

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'company', label: 'Компания', type: FilterChipType.Id },
      { source: 'provider', label: 'Провайдер', type: FilterChipType.Id },
      { source: 'merchantAccounts', label: 'Счета мерчанта', type: FilterChipType.IdArray },
      { source: 'workTeam', label: 'Рабочая группа', type: FilterChipType.Id },
      { source: 'members', label: 'Участники', type: FilterChipType.Users },
    ],
    appliedFilters: filterValues,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilter} />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById as any}
          resetFilterBySource={resetFilterBySource as any}
        >
          <FilterFieldGroup>
            <ReferenceInput
              isFilter={true}
              name="company"
              perPage={200}
              reference="companies"
              source="company"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Компания"
                name="company"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceInput
              isFilter={true}
              name="provider"
              perPage={200}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              isFilter={true}
              name="merchantAccounts"
              perPage={1000}
              reference="merchant_accounts"
              resource="merchant_accounts"
              source="merchantAccounts"
            >
              <AutocompleteArrayInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Счета мерчанта"
                name="merchantAccounts"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceInput perPage={50} reference="work_teams" source="workTeam">
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                fullWidth
                helperText={false}
                label="Рабочая группа"
                name="workTeam"
                optionText="name"
                size="small"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              filter={{
                roleEntities: [RoleEntity.Finance],
              }}
              perPage={200}
              reference="users"
              source="members"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({
                  fullName: searchText,
                })}
                helperText={false}
                label="Участники"
                name="members"
                optionText="name"
                renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
                size="small"
              />
            </ReferenceArrayInput>
          </FilterFieldGroup>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
