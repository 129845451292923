export const APP_VERSIONS = [
  'KASSA_CASH',
  'KASSA_GUEST',
  'RFID_STAVKOMAT',
  'ANDROID',
  'ANDROID_NEW',
  'IOS',
  'IOS_NEW',
  'IOS_NEW_2',
  'IOS_IPAD',
  'IOS_BROWSER',
  'WEB',
  'WEB_NEW',
  'MOBI',
  'MOBI_NEW',
  'WIN_PHONE',
  'TELEGRAM',
  'WIN',
];
