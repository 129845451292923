import { getMerchantRules } from 'config/requests';
import { useQuery } from 'react-query';
import { GatewayRuleRecord } from 'types';

import { MerchantShowRulesFilterValues } from '../MerchantsShow/MerchantShowRules/MerchantShowRulesListFilter';

type UseGetMerchantRulesReturnValue = {
  merchantRules: GatewayRuleRecord[] | undefined;
  totalItems: number;
  isLoading: boolean;
  refetch: () => void;
};

export function useGetMerchantRules(
  merchantId?: string,
  filters?: MerchantShowRulesFilterValues,
): UseGetMerchantRulesReturnValue {
  const { data, isLoading, refetch } = useQuery<any>(['merchant_rules', merchantId, filters], () =>
    getMerchantRules(merchantId, filters),
  );

  return { merchantRules: data?.merchantRules, totalItems: data?.totalItems, isLoading, refetch };
}
