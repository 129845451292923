import { InputGuesser } from '@api-platform/admin';
import qs from 'qs';
import { RaRecord, required } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputIntrospected, JsonInputIntrospected } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { useMerchantAlertsValidation } from './hooks/useMerchantAlertsValidation';
import { fieldWidth } from '../../themes/variables';
import { AppNumberInput } from '../Common';
import { AppCreateGuesser } from '../Common/Create';

type MerchantDefaultValueType = {
  merchant: string;
};

const MerchantAlertsCreate = (): JSX.Element => {
  const location = useLocation();
  const defaultValues = qs.parse(
    location?.search.replace('?', '') as string,
  ) as MerchantDefaultValueType;
  const { validatePercentFailure } = useMerchantAlertsValidation();

  const transform = (data: RaRecord): RaRecord => {
    return {
      ...data,
      type: 'reddy',
    };
  };

  return (
    <AppCreateGuesser
      actions={false}
      listName="Оповещения мерчантов"
      title="Создание оповещения мерчанта"
      transform={transform}
    >
      <ReferenceInput perPage={200} reference="merchants" source="merchant">
        <AutocompleteInputIntrospected
          defaultValue={defaultValues.merchant}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Мерчант"
          optionText="name"
          size="medium"
          source="name"
          sx={fieldWidth.medium}
          variant="outlined"
        />
      </ReferenceInput>
      <TextField
        defaultValue="reddy"
        disabled
        label="Тип"
        size="medium"
        sx={fieldWidth.medium}
        variant="outlined"
      />
      <JsonInputIntrospected
        isRequired
        label="Доступы"
        source="credentials"
        sx={{ marginTop: '20px' }}
      />
      <InputGuesser
        defaultValue={true}
        label="Активно"
        source="active"
        sx={{ marginTop: '20px' }}
      />
      <AppNumberInput
        label="Процент успешных транзакций"
        size="medium"
        source="percentFailure"
        sx={{ ...fieldWidth.medium, marginTop: 0 }}
        validate={[validatePercentFailure, required()]}
        variant="outlined"
      />
    </AppCreateGuesser>
  );
};

export default MerchantAlertsCreate;
