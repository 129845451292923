import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Stack, Box, Alert } from '@mui/material';
import { LinearProgress } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { BooleanInput, AutocompleteInput, TextInput } from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { fieldWidth } from 'themes/variables';
import { ComparisonOperator, ExpressionParam } from 'types';

import { GatewayRulesFormMapping } from './components/GatewayRulesFormMapping';
import { GatewayRulesFormRule } from './components/GatewayRulesFormRule/GatewayRulesFormRule';
import { useGatewayRulesFormContext } from './context/GatewayRulesFormContext';
import { StyledTitleCardContent } from './GatewayRulesForm.styled';
import { useGatewayRulesForm, useInitialExpression, useMappingField } from './hooks';
import { GatewayRulesFormValues, MappingItemFormValues } from './hooks/useGatewayRulesForm';
import { getInitialMappingItem } from './utils';
import { AppButton } from '../../Buttons';
import { AppTypography } from '../../Typography';

export type Expression = {
  id?: string;
  expressionParam?: ExpressionParam;
  comparisonOperator?: ComparisonOperator;
  value?: string[];
};

export type ConditionGroup = {
  parent: null | string;
  isAnd: boolean;
  expressions: Expression[];
  children: ConditionGroup[];
};

type Props = {
  children: React.ReactNode;
  onSubmit: (data: GatewayRulesFormValues) => void;
  defaultValues?: GatewayRulesFormValues;
};

export function GatewayRulesForm(props: Props): JSX.Element {
  const { expressionParams, loadingExpressionParams } = useGatewayRulesFormContext();

  const { onSubmit, form } = useGatewayRulesForm({
    defaultValues: props.defaultValues,
    onSubmit: props.onSubmit,
  });
  const { mappingFields, onRemoveMappingClick, onAddMappingClick } = useMappingField({
    name: 'mappings',
    form,
  });

  const [isDisabledAddCondition, setDisabledAddCondition] = useState<boolean>(
    Boolean(props.defaultValues?.conditionGroup),
  );
  const initialExpression = useInitialExpression(expressionParams);

  useEffect(() => {
    form.reset(props.defaultValues);
  }, [props.defaultValues]);

  function onAddConditionClick(): void {
    form.setValue('conditionGroup', {
      isAnd: true,
      expressions: [initialExpression],
    } as ConditionGroup);
    setDisabledAddCondition(true);
  }

  function onRemoveConditionClick(): void {
    form.unregister('conditionGroup');
    form.setValue('conditionGroup', null, { shouldDirty: true });
    setDisabledAddCondition(false);
  }

  function onChangeMethod(mappingIndex: number): void {
    form.setValue(`mappings.${mappingIndex}.mappingItems`, [
      getInitialMappingItem() as MappingItemFormValues,
    ]);
    return;
  }

  function onChangeDirection(mappingIndex: number): void {
    form.setValue(`mappings.${mappingIndex}.mappingItems`, [
      getInitialMappingItem() as MappingItemFormValues,
    ]);
    return;
  }

  return (
    <FormProvider {...form}>
      <Stack alignItems="flex-start" sx={{ form: { width: '100%' } }}>
        <form onSubmit={onSubmit}>
          <Box
            sx={{
              display: 'flex',
              padding: '0 24px',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <CardContent
              sx={{ gap: '16px', display: 'flex', alignItems: 'flex-start', paddingBottom: '0' }}
            >
              <TextInput
                label="Название"
                name="name"
                source="name"
                sx={{ ...fieldWidth.medium, marginTop: '0' }}
                variant="outlined"
              />
              <ReferenceInput perPage={1000} reference="merchants" source="merchant">
                <AutocompleteInput
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  label="Мерчант"
                  optionText="name"
                  sx={fieldWidth.medium}
                  variant="outlined"
                />
              </ReferenceInput>
              <BooleanInput label="Активно" source="active" />
            </CardContent>
            <StyledTitleCardContent>
              <AppTypography size="small">1. Добавление условия</AppTypography>
              <AppButton
                disabled={isDisabledAddCondition || loadingExpressionParams}
                label="Добавить условие"
                onClick={onAddConditionClick}
                startIcon={<AddIcon />}
              />
            </StyledTitleCardContent>
            {!isDisabledAddCondition && (
              <CardContent sx={{ width: '100%' }}>
                {loadingExpressionParams ? (
                  <LinearProgress />
                ) : (
                  <Alert severity="info">
                    Если не добавить условие, правило будет применено для всех клиентов мерчанта.
                  </Alert>
                )}
              </CardContent>
            )}
            {isDisabledAddCondition && (
              <CardContent sx={{ width: '100%' }}>
                <GatewayRulesFormRule
                  className="tree"
                  onRemoveConditionClick={onRemoveConditionClick}
                />
              </CardContent>
            )}
            <StyledTitleCardContent>
              <AppTypography size="small">2. Добавление маппингов</AppTypography>
              <AppButton
                label="Добавить маппинг"
                onClick={onAddMappingClick}
                startIcon={<AddIcon />}
              />
            </StyledTitleCardContent>
            <CardContent sx={{ width: '100%', paddingTop: 0 }}>
              {mappingFields.map((mapping, mappingIndex) => (
                <GatewayRulesFormMapping
                  key={mapping.id}
                  mappingIndex={mappingIndex}
                  onChangeDirection={onChangeDirection}
                  onChangeMethod={onChangeMethod}
                  onRemoveMappingClick={onRemoveMappingClick}
                />
              ))}
            </CardContent>
            {props.children}
          </Box>
        </form>
      </Stack>
    </FormProvider>
  );
}
