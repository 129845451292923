import { useMemo } from 'react';

export function useInitialDates(): { dateFrom: Date; dateTo: Date } {
  return useMemo(() => {
    return {
      dateFrom: new Date(),
      dateTo: new Date(),
    };
  }, []);
}
