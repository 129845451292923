import { Stack } from 'shared/mui/Stack/Stack';

import { ReactComponent as ArrowDown } from '../../../images/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../images/arrowUp.svg';
import { OrderMonitoringStatisticValue } from '../../../types';

export const OrdersMonitoringListCell = ({
  record,
}: {
  record: OrderMonitoringStatisticValue;
}): JSX.Element => {
  const withTreshold = record?.isThresholdLow !== undefined;
  const style = withTreshold
    ? record?.isThresholdLow
      ? { color: '#EF5350' }
      : { color: '#4CAF50' }
    : undefined;

  return (
    <Stack alignItems="center" direction="row" flexWrap="nowrap" gap={0.5}>
      <span style={style}>{withTreshold ? `${record?.count}%` : record?.count}</span>
      {record?.isDropping ? <ArrowDown /> : <ArrowUp />}
    </Stack>
  );
};
