import { format } from 'date-fns';
import { RaRecord } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { OrderMonitoringStatistic } from 'types';

import { PERIOD, tableConfig } from './constants/tableConfig';
import { DatagridConfigurableStyled } from './OrdersMonitoringList.styled';
import { OrdersMonitoringListCell } from './OrdersMonitoringListCell';

type Props = {
  dataPerPage: OrderMonitoringStatistic[];
  isDayDistance: boolean;
  isLoading: boolean;
};

export const OrdersMonitoringListTable = ({
  isDayDistance,
  dataPerPage,
  isLoading,
}: Props): JSX.Element => (
  <DatagridConfigurableStyled
    bulkActionButtons={false}
    data={dataPerPage}
    isLoading={isLoading}
    sort={{ field: '', order: 'ASC' }}
  >
    <FunctionField
      label={PERIOD}
      render={(record: RaRecord) => (
        <Stack alignItems="start" direction="column" flexWrap="nowrap">
          {isDayDistance && (
            <span style={{ whiteSpace: 'nowrap' }}>
              {format(new Date(record.period.dateFrom), 'HH:mm') +
                ' — ' +
                format(new Date(record.period.dateTo), 'HH:mm')}
            </span>
          )}
          <span style={{ color: isDayDistance ? appColors.secondary.main : 'initial' }}>
            {format(new Date(record.period.dateFrom), 'dd/MM/yyyy')}
          </span>
        </Stack>
      )}
      source="period"
    />
    {tableConfig.map(({ label, value }, i) => (
      <FunctionField
        key={i}
        label={label}
        render={(record: RaRecord) => <OrdersMonitoringListCell record={record[value]} />}
        source="typical"
      />
    ))}
  </DatagridConfigurableStyled>
);
