import { DEFAULT_PAGINATION } from 'constants/defaultPagination';

import { useEffect, useMemo } from 'react';

import { useMerchantSpacesFilters } from 'components/MerchantSpaces/MerchantSpacesList/hooks/useMerchantSpacesFilters';
import { useRecordModal } from 'hooks/useRecordModal';
import { ListContextProvider, Pagination, useList } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { CreateButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { DeleteIconButton } from 'shared/mui/IconButtons';
import { PageHeader } from 'shared/mui/PageHeader';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { EditIconButton } from 'shared/react-admin/IconButtons';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { MerchantSpaces } from 'types';
import { getId } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';
import { getBooleanFromString } from 'utils/getBooleanFromString';

import { MerchantSpacesActivitySwitch } from './components/MerchantSpacesActivitySwitch';
import { MerchantSpacesListFilter } from './components/MerchantSpacesListFilter';
import { MerchantSpacesMerchantsField } from './components/MerchantSpacesMerchantsField';
import { useGetMerchantSpaces } from './hooks/useGetMerchantSpaces';

export type MerchantSpacesFilter = {
  spaceName?: string | null;
  active?: boolean | null;
  sourceCompany?: string | null;
  sites?: string[] | null;
  page: number;
  itemsPerPage: number;
};

export const MerchantSpacesList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsFilter: MerchantSpacesFilter = useMemo(() => {
    return cleanEmpty({
      spaceName: searchParams.get('spaceName'),
      active: getBooleanFromString(searchParams.get('active')),
      sites: searchParams.getAll('sites'),
      sourceCompany: searchParams.get('sourceCompany'),

      page: Number(searchParams.get('page')) || DEFAULT_PAGINATION.page,
      itemsPerPage: Number(searchParams.get('itemsPerPage')) || DEFAULT_PAGINATION.itemsPerPage,
    });
  }, [searchParams]);

  const { form, onSubmit, resetFilter, resetFilterBySource, resetFilterArrayById } =
    useMerchantSpacesFilters();

  const { merchantSpaces, isLoading, refetch } = useGetMerchantSpaces(searchParamsFilter);
  const list = useList<MerchantSpaces>({
    data: merchantSpaces?.items || [],
    page: form.getValues('page'),
    perPage: form.getValues('itemsPerPage'),
  });

  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<MerchantSpaces>();

  useEffect(() => {
    form.reset(searchParamsFilter);
  }, []);

  return (
    <ListContextProvider value={list}>
      <PageHeader
        actions={[<CreateButton key="create_merchant_space" />]}
        headerTitle={{
          titleText: 'Кабинеты мерчантов',
        }}
        mt={3}
      />
      <MerchantSpacesListFilter
        form={form}
        onSubmit={onSubmit}
        resetFilter={resetFilter}
        resetFilterArrayById={resetFilterArrayById}
        resetFilterBySource={resetFilterBySource}
      />
      <Datagrid
        data={merchantSpaces?.items || []}
        isLoading={isLoading}
        sx={{
          '&.RaDatagrid-root tr:has(.Mui-checked)': {
            background: 'initial',
          },
          '&.RaDatagrid-root tr:has(.MuiCheckbox-root.Mui-checked)': {
            background: '#1976D214',
          },
        }}
      >
        <FunctionField label="ID" render={(record: MerchantSpaces) => getId(record?.id)} />
        <FunctionField
          label="Активен"
          render={() => <MerchantSpacesActivitySwitch refetchMerchantSpaces={refetch} />}
        />
        <TextField label="Название" source="spaceName" />
        <ReferenceOneField
          emptyText="—"
          label="Компания"
          reference="companies"
          source="sourceCompany"
          target="id"
        >
          <TextField source="name" />
        </ReferenceOneField>
        <FunctionField label="Мерчанты" render={() => <MerchantSpacesMerchantsField />} />
        <ActionsSlots
          render={(record) => (
            <>
              <EditIconButton />
              <DeleteIconButton onClick={() => handleOpenRecordModal(record as MerchantSpaces)} />
            </>
          )}
        />
      </Datagrid>
      <Pagination
        page={form.getValues('page')}
        perPage={form.getValues('itemsPerPage')}
        setPage={(page) => {
          form.setValue('page', page);
          setSearchParams((prev) => {
            return { ...Object.fromEntries(prev), page: page.toString() };
          });
        }}
        setPerPage={(itemsPerPage) => {
          form.setValue('itemsPerPage', itemsPerPage);
          setSearchParams((prev) => {
            return { ...Object.fromEntries(prev), itemsPerPage: itemsPerPage.toString() };
          });
        }}
        total={merchantSpaces?.totalItems}
      />
      <DeleteDialog
        details={`Кабинет мерчанта ${record?.spaceName || ''} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        open={openRecordModal}
        record={record}
        title="Удаление кабинета мерчанта"
      />
    </ListContextProvider>
  );
};
