import { useMemo } from 'react';

import { hydraSchemaAnalyzer } from '@api-platform/admin';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import _ from 'lodash';
import qs from 'qs';
import { HttpError, useDataProvider, useNotify } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CardActionsButtons } from 'shared/mui/Card';
import { PageLoading } from 'shared/react-admin/Loading';

import { useExpressionParams } from './hooks';
import { AppCreateFormSaveButton } from '../Common/Buttons';
import { AppCreate } from '../Common/Create';
import { GatewayRulesForm } from '../Common/Forms/GatewayRulesForm';
import { GatewayRulesFormProvider } from '../Common/Forms/GatewayRulesForm/context/GatewayRulesFormContext';
import { GatewayRulesFormValues } from '../Common/Forms/GatewayRulesForm/hooks/useGatewayRulesForm';
import { getInitialMapping } from '../Common/Forms/GatewayRulesForm/utils';

export function GatewayRulesCreate(): JSX.Element {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { toRoot } = useNavigateToRoot();

  const { expressionParams, loadingExpressionParams } = useExpressionParams();
  const location = useLocation();

  const defaultUrlValues = qs.parse(location?.search.replace('?', '') as string);

  const defaultValues = useMemo<GatewayRulesFormValues>(() => {
    return {
      ...defaultUrlValues,
      mappings: [getInitialMapping()],
    } as GatewayRulesFormValues;
  }, [location, expressionParams]);

  async function onSubmit(data: GatewayRulesFormValues) {
    const modifiedData = _.cloneDeepWith(data, (value, key) => {
      if (key === 'comparisonOperator' && _.isPlainObject(value) && _.has(value, 'id')) {
        return `/admin/comparison_operators/${value.id}`;
      }

      return undefined;
    });

    try {
      await dataProvider.create('gateway_rules', {
        data: modifiedData,
      });
      notify('Правило создано!', { type: 'info' });
      toRoot();
    } catch (error) {
      const violations = hydraSchemaAnalyzer().getSubmissionErrors(error as HttpError);
      notify(
        violations ? Object.values(violations).join('\n') : `Ошибка:  ${(error as Error).message}`,
        {
          type: 'error',
          multiLine: true,
        },
      );
    }
  }

  if (loadingExpressionParams) {
    return <PageLoading />;
  }

  return (
    <AppCreate actions={false} listName="Правила" title="Создание правила">
      <GatewayRulesFormProvider
        value={{
          loadingExpressionParams,
          expressionParams,
        }}
      >
        <GatewayRulesForm defaultValues={defaultValues} onSubmit={onSubmit}>
          <CardActionsButtons
            leftActionsSlot={
              <AppCreateFormSaveButton icon={<></>} label="Создать правило" type="submit" />
            }
          />
        </GatewayRulesForm>
      </GatewayRulesFormProvider>
    </AppCreate>
  );
}
