import { useEffect } from 'react';

import { useExpressionParamWithData } from 'components/GatewayRules/hooks';
import { useFormContext } from 'react-hook-form';
import { ExpressionParam, ExpressionParamFieldType } from 'types';

import { StyledExpressionCard } from './GatewayRulesFormRuleExpression.styled';
import { GatewayRulesFormRuleExpressionArrayInput } from './GatewayRulesFormRuleExpressionArrayInput';
import { GatewayRulesFormRuleExpressionInput } from './GatewayRulesFormRuleExpressionInput';
import { GatewayRulesFormRuleExpressionOperator } from './GatewayRulesFormRuleExpressionOperator';
import { GatewayRulesFormRuleExpressionParams } from './GatewayRulesFormRuleExpressionParams';
import { useFirstRender } from '../../../../../../../hooks/useFirstRender';
import { useGatewayRulesFormContext } from '../../../context/GatewayRulesFormContext';
import { GatewayRulesFormRemoveButton } from '../../GatewayRulesFormRemoveButton';

type Props = {
  comparisonOperatorName: string;
  expressionIndex: number;
  expressionParamName: string;
  onRemoveExpressionClick: (index: number) => void;
  valueName: string;
};

export function GatewayRulesFormRuleExpression({
  comparisonOperatorName,
  expressionIndex,
  expressionParamName,
  onRemoveExpressionClick,
  valueName,
}: Props): JSX.Element {
  const { watch, setValue } = useFormContext();
  const currentExpressionParam: ExpressionParam = watch(expressionParamName);

  const { expressionParams } = useGatewayRulesFormContext();
  const { expressionParamWithData, loadingExpressionParamWithData } = useExpressionParamWithData(
    currentExpressionParam?.alias,
  );

  const { isFirstRender } = useFirstRender();

  useEffect(() => {
    // пропускаем первый рендер, когда данные еще только подгружаются
    // и очищаем значение и устанавливаем по умолчанию 0й знак выражения только после изменения expression param
    if (!isFirstRender && !loadingExpressionParamWithData) {
      setValue(valueName, []);
      setValue(comparisonOperatorName, expressionParamWithData?.comparisonOperators[0]);
    }
  }, [
    currentExpressionParam,
    expressionParamWithData?.comparisonOperators,
    loadingExpressionParamWithData,
  ]);

  function getValueInput(): JSX.Element {
    if (currentExpressionParam?.fieldType === ExpressionParamFieldType.MultiSelect) {
      return (
        <GatewayRulesFormRuleExpressionArrayInput
          name={valueName}
          options={expressionParamWithData?.data || []}
        />
      );
    }

    return <GatewayRulesFormRuleExpressionInput name={valueName} />;
  }

  return (
    <StyledExpressionCard>
      <GatewayRulesFormRuleExpressionParams
        expressionParams={expressionParams}
        name={expressionParamName}
      />
      <GatewayRulesFormRuleExpressionOperator
        comparisonOperators={expressionParamWithData?.comparisonOperators || []}
        expressionParamName={currentExpressionParam?.name}
        name={comparisonOperatorName}
      />
      {getValueInput()}
      <GatewayRulesFormRemoveButton onClick={() => onRemoveExpressionClick(expressionIndex)} />
    </StyledExpressionCard>
  );
}
