import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord, useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';

import { MerchantAccountsEditForm } from './MerchantAccountEditForm';
import { MerchantAccount, Permission } from '../../../types';
import { AppEditGuesser } from '../../Common/Edit';

// ToDo в данном компоненте некорректно работает обработка ошибки после обновление библиотеки
// react-admin. После мержа задачи WPPA-5001 взять задачу на фикс данной проблемы.

const MerchantAccountsEdit: React.FC = () => {
  const transform = (data: MerchantAccount): RaRecord => {
    return {
      ...data,
      balance: `${data.balance}`,
      merchantCredentials: data.merchantCredentials || null,
      hold: data.hold || null,
      currency: data.currency || null,
    };
  };
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: merchantAccount,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<MerchantAccount>();

  return (
    <AppEditGuesser
      actions={false}
      listName="Счета"
      mutationMode="pessimistic"
      mutationOptions={{ onError: () => undefined }}
      title="Редактирование счета"
      toolbar={
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton alwaysEnable={true} icon={<></>} key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Delete]) && (
              <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
            )
          }
        />
      }
      transform={transform}
    >
      <MerchantAccountsEditForm />
      <DeleteDialog
        details={`Счет мерчанта ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={merchantAccount}
        title="Удаление счета мерчанта"
      />
    </AppEditGuesser>
  );
};

export default MerchantAccountsEdit;
