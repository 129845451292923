import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterBaseApplyButton } from 'shared/mui/Filter/FilterButtons/FilterBaseApplyButton/FilterBaseApplyButton';
import { FilterBaseResetButton } from 'shared/mui/Filter/FilterButtons/FilterBaseResetButton/FilterBaseResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { TextInput } from 'shared/react-admin/Inputs';

type Props = {
  form: UseFormReturn<MerchantShowRulesFilterValues>;
  onSubmit: () => void;
  onReset: () => void;
};

export type MerchantShowRulesFilterValues = {
  name?: string | null;
  page: number;
  itemsPerPage: number;
};

export const MerchantShowRulesListFilter: React.FC<Props> = ({ form, onSubmit, onReset }) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInput
              InputLabelProps={{ shrink: true }}
              helperText={false}
              label="Название"
              source="name"
              sx={{ marginTop: '8px !important', marginBottom: '8px' }}
              variant="outlined"
            />
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterBaseApplyButton key="apply-button" type="submit" />
              <FilterBaseResetButton key="reset-button" onClick={onReset} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
