import CreateIcon from '@mui/icons-material/Create';
import { Box, IconButton, Typography } from '@mui/material';
import { FunctionField, useGetOne } from 'react-admin';

import { GatewayRulePercentOfTrafficDialog } from './GatewayRulePercentOfTrafficDialog';
import { useRecordModal } from '../../../../hooks/useRecordModal';
import { Gateway, MappingItemRecord, MappingRecord, Method } from '../../../../types';

type Props = {
  label: string;
  mappingRecord: MappingRecord;
};

export function GatewayRulePercentOfTrafficField({ label, mappingRecord }: Props): JSX.Element {
  const {
    record: mappingItemRecord,
    openRecordModal: openPercentOfTrafficModal,
    handleOpenRecordModal: handleOpenPercentOfTrafficModal,
    handleCloseRecordModal: handleClosePercentOfTrafficModal,
  } = useRecordModal<MappingItemRecord>();

  const { data: methodRecord } = useGetOne<Method>('methods', { id: mappingRecord.method });
  const { data: gatewayRecord } = useGetOne<Gateway>(
    'gateways',
    { id: mappingItemRecord?.gateway || '' },
    { enabled: !!mappingItemRecord },
  );

  return (
    <>
      <GatewayRulePercentOfTrafficDialog
        gatewayRecord={gatewayRecord}
        mappingItemRecord={mappingItemRecord}
        methodRecord={methodRecord}
        onClose={handleClosePercentOfTrafficModal}
        open={openPercentOfTrafficModal}
      />
      <FunctionField
        label={label}
        render={(record: MappingItemRecord) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography component="span" mr={1} sx={{ fontSize: '14px' }} variant="body1">
                {record.value}
              </Typography>
              <IconButton sx={{ width: '28px', height: '28px' }}>
                <CreateIcon
                  color="secondary"
                  onClick={() => handleOpenPercentOfTrafficModal(record)}
                  sx={{ fontSize: '20px' }}
                />
              </IconButton>
            </Box>
          );
        }}
        source="percent"
      />
    </>
  );
}
