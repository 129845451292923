import { AppReferenceOneField } from 'components/Common';
import {
  BooleanField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { CreateButtonToEntity } from 'shared/mui/Buttons';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { MerchantPayoutControlsFilters } from './MerchantPayoutControlsFilters';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types';
import { AppListEditButton, AppListShowButton } from '../Common/Buttons';
import { AppDatagrid } from '../Common/Datagrid';
import { AppList } from '../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

const MerchantPayoutControlsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <AppPanelHeader>
        <AppPanelHeaderToolbar
          actions={[
            hasResourcePermissions('merchant_payout_controls', [
              Permission.Full,
              Permission.Create,
            ]) && <CreateButtonToEntity entity="merchant" />,
          ]}
          titleText="Контроль выводов"
        />
      </AppPanelHeader>
      <MerchantPayoutControlsFilters />
      <AppDatagrid>
        {hasResourceAccess('merchants') && (
          <AppReferenceOneField
            label="Мерчант"
            link="show"
            reference="merchants"
            source="merchant"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('gateways') && (
          <AppReferenceOneField
            label="Шлюз"
            link="show"
            reference="gateways"
            source="gateway"
            target="id"
          >
            <TextField source="name" />
          </AppReferenceOneField>
        )}
        <BooleanField
          label="Разрешить выплату на другой аккаунт"
          source="allowPayoutToAnotherAccount"
        />
        {hasResourceAccess('customers') && (
          <ReferenceArrayField
            label="Исключенные пользователи"
            reference="customers"
            source="excludedCustomers"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="originId" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <ActionsSlots
          render={() => (
            <>
              <AppListShowButton />
              {hasResourcePermissions('merchant_payout_controls', [
                Permission.Full,
                Permission.Update,
              ]) && <AppListEditButton />}
            </>
          )}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default MerchantPayoutControlsList;
