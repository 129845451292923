import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowDialog } from 'shared/react-admin/Dialogs/ShowDialog/ShowDialog';

import { CommissionsShowTable } from './CommissionsShowTable';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Commission, Permission } from '../../../types';
import { CommissionsDeleteDialog } from '../components/CommissionsDeleteDialog';

export const CommissionsShow = (): JSX.Element => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Commission>();

  return (
    <>
      <ShowDialog
        actions={
          hasResourceAccess('user_actions')
            ? [<UserActionsIconButton entityName="commission" key="user-action-link" />]
            : undefined
        }
        open={!openRecordModalDelete}
        title="Просмотр комиссии"
      >
        <CommissionsShowTable />
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </ShowDialog>
      {openRecordModalDelete && (
        <CommissionsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
