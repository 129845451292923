import { ROLES_SELECT_ITEMS, ROLES_ICONS } from 'constants/roles';

import { TableBody, TableRow } from '@mui/material';
import { AppReferenceOneField } from 'components/Common';
import { AppSingleFieldList } from 'components/Common/Fields/AppSingleFieldList';
import { usePermissions } from 'hooks/usePermissions';
import { ReferenceArrayField, TextField, useShowController } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Chip } from 'shared/mui/Chips';
import { TableCell, TableCellHeader } from 'shared/mui/Table';
import { ChipField, ListField } from 'shared/react-admin/Fields';

export function ApiClientsShowTable(): JSX.Element {
  const { hasResourceAccess } = usePermissions();
  const {
    record: { merchant },
  } = useShowController();

  return (
    <CardTable>
      <TableBody>
        <TableRow>
          <TableCellHeader title="ID" />
          <TableCell component="th" scope="row">
            <TextField source="originId" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Название" />
          <TableCell component="th" scope="row">
            <TextField source="name" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Алиас" />
          <TableCell component="th" scope="row">
            <TextField source="alias" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Доступ" />
          <TableCell component="th" scope="row">
            <ListField
              array={ROLES_SELECT_ITEMS}
              label="Доступ"
              renderItem={({ id, name }) => <Chip icon={ROLES_ICONS[id]} key={id} label={name} />}
              sortable={false}
              source="roles"
            />
          </TableCell>
        </TableRow>
        {hasResourceAccess('merchants') && (
          <TableRow>
            <TableCellHeader title={`Мерчант${merchant ? '' : 'ы'}`} />
            <TableCell component="th" scope="row">
              {merchant ? (
                <AppReferenceOneField
                  label="Мерчант"
                  link="show"
                  reference="merchants"
                  source="merchant"
                  target="id"
                >
                  <ChipField
                    source="name"
                    sx={{ color: 'black!important', margin: '0!important' }}
                  />
                </AppReferenceOneField>
              ) : (
                <ReferenceArrayField
                  label="Мерчанты"
                  reference="merchants"
                  sortBy="merchants.name"
                  source="merchants"
                >
                  <AppSingleFieldList>
                    {({ record, resource }) => {
                      return <ChipField record={record} resource={resource} source="name" />;
                    }}
                  </AppSingleFieldList>
                </ReferenceArrayField>
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </CardTable>
  );
}
