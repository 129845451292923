import { EMPTY_FIELD } from 'constants/emptyField';

import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { JsonEditorProps } from 'json-edit-react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip';

import { JsonEditorStyled } from './JsonField.styled';

export type JsonFieldProps = FieldProps & Partial<JsonEditorProps> & { src?: Record<string, any> };

export const JsonField = (props: JsonFieldProps) => {
  const { source, src } = props;
  const [tooltipText, setTooltipText] = useState('');
  const record = useRecordContext();

  const TypedJsonEditorStyled = JsonEditorStyled as unknown as (
    props: JsonFieldProps,
  ) => JSX.Element;
  if (!(source && record[source]) && !src) {
    return <span>{EMPTY_FIELD}</span>;
  }

  const jsonValue = src || record[source as string];
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(jsonValue, null, 2));
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 2000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <TypedJsonEditorStyled
        data={jsonValue}
        restrictAdd={true}
        restrictDelete={true}
        restrictEdit={true}
        {...props}
      />
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={!!tooltipText}
        sx={{ position: 'absolute', top: '8px', right: '8px' }}
        title={tooltipText}
      >
        <IconButton onClick={handleCopy}>
          <ContentCopyIcon color="secondary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
