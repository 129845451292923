import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteArrayInputShrink, AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { useFilters } from '../../hooks/useFilters';

type HolfdFilterValues = {
  merchantAccounts: string[];
  currency: string;
};

export const HoldsFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<HolfdFilterValues>({
    merchantAccounts: [],
    currency: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <ReferenceArrayInput
              isFilter={true}
              perPage={100}
              reference="merchant_accounts"
              source="merchantAccounts"
            >
              <AutocompleteArrayInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Счета мерчантов"
                optionText="name"
                source="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceInput
              perPage={1000}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Валюта"
                optionText="name"
                source="name"
                variant="outlined"
              />
            </ReferenceInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
