import { styled } from '@mui/material/styles';
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { Chip, ChipProps } from 'shared/mui/Chips';
import { appColors } from 'themes/variables';
import { getLinkParams } from 'utils';

type Props = ChipProps;

export const StyledChip = styled(Chip)({
  background: appColors.primary.main,
  color: appColors.text.light,
  marginTop: '12px',
});

export const ApiClientsListLinkedChip = (props: Props): JSX.Element => {
  const record = useRecordContext();
  const linkParams = getLinkParams({
    pathname: `/api_clients/${encodeURIComponent(record.id)}/show`,
  });

  return <StyledChip component={Link} {...props} {...linkParams} />;
};
