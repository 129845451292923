import { styled } from '@mui/material/styles';
import { BulkDeleteButton, Datagrid, DatagridProps, useListContext } from 'react-admin';

import { useExistingChildren } from '../../../hooks/useExistingChildren';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';

type Props = DatagridProps & { fullWidth?: boolean };

export const StyledDatagrid = styled(Datagrid)(({ fullWidth }: Props) => ({
  minWidth: '36px',
  '.MuiButton-startIcon': {
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  padding: fullWidth ? 0 : '0px 16px',
  '& .RaDatagrid-headerCell': {
    verticalAlign: 'top',
    padding: '13px 16px',
    lineHeight: '23px',
  },
  '& .RaDatagrid-rowCell': {
    verticalAlign: 'center',
    padding: '12px 16px',
  },
  '& .MuiTableCell-head': {
    paddingTop: '15px !important',
    verticalAlign: 'top',
  },
  '& .column-originId': {
    width: 0,
  },
}));

export function AppDatagrid({ children, ...props }: Props): JSX.Element {
  const { resource, data = [] } = useListContext();
  const { hasResourcePermissions } = usePermissions();

  const existingFields = data.reduce((fields, record) => {
    return Array.from(new Set([...fields, ...Object.keys(record)]));
  }, []);

  const filteredChildren = useExistingChildren(children, existingFields);

  return (
    <StyledDatagrid
      bulkActionButtons={
        hasResourcePermissions(resource, [Permission.Full, Permission.Delete]) && (
          <BulkDeleteButton />
        )
      }
      {...props}
    >
      {filteredChildren}
    </StyledDatagrid>
  );
}
