import { UNLIMITED_PAGINATION_PARAMS } from 'constants/unlimitedPaginationParams';

import { useEffect, useMemo } from 'react';

import { useFirstRender } from 'hooks/useFirstRender';
import { ReferenceArrayInput, ReferenceInput, useGetList, useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { GatewayRulesFormMappingItemWrapper } from './GatewayRulesFormMappingItem.styled';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  NumberInput,
} from '../../../../../../shared/react-admin/Inputs';
import { GatewayMethod } from '../../../../../../types';
import { getId, jsonEqual } from '../../../../../../utils';
import { cleanEmpty } from '../../../../../../utils/cleanEmpty';
import { GatewayRulesFormValues, MappingItemFormValues } from '../../hooks/useGatewayRulesForm';
import { getInitialMappingItem } from '../../utils';
import { GatewayRulesFormRemoveButton } from '../GatewayRulesFormRemoveButton';

type Props = {
  mappingIndex: number;
  mappingItemIndex: number;
  onRemoveMappingItemClick: (index: number) => void;
};

export const GatewayRulesFormMappingItem: React.FC<Props> = ({
  mappingItemIndex,
  mappingIndex,
  onRemoveMappingItemClick,
}) => {
  const { getValues, formState, watch, setValue } = useFormContext<GatewayRulesFormValues>();
  const { isFirstRender } = useFirstRender();
  const isDisabledRemoveMappingItem = useMemo<boolean>(() => {
    const currentValues = getValues();
    return (
      currentValues.mappings[mappingIndex].mappingItems.length === 1 &&
      jsonEqual(currentValues.mappings[mappingIndex].mappingItems[0], getInitialMappingItem())
    );
  }, [formState]);

  const mappings = watch('mappings');

  const { data: gatewayMethods } = useGetList<GatewayMethod>(
    'gateway_methods',
    {
      pagination: UNLIMITED_PAGINATION_PARAMS,
      filter: {
        method: mappings[mappingIndex].method,
      },
    },
    {
      enabled: Boolean(mappings[mappingIndex].method),
    },
  );
  const gateway = watch(`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.gateway`);
  const merchantAccount = watch(
    `mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.merchantAccount`,
  );
  const merchant = watch('merchant');

  const { data: currentGateway } = useGetOne(
    'gateways',
    {
      id: mappings[mappingIndex].mappingItems[mappingItemIndex].gateway,
    },
    { enabled: !!mappings[mappingIndex].mappingItems[mappingItemIndex].gateway },
  );

  useEffect(() => {
    if (!isFirstRender) {
      setValue(`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.commissions`, []);
      setValue(`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.merchantAccount`, '');
    }
  }, [gateway]);

  useEffect(() => {
    if (!isFirstRender) {
      setValue(
        `mappings.${mappingIndex}.mappingItems.${mappingItemIndex}`,
        getInitialMappingItem() as MappingItemFormValues,
      );
      setValue(`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.commissions`, []);
    }
  }, [merchant]);

  return (
    <GatewayRulesFormMappingItemWrapper>
      <ReferenceInput
        filter={cleanEmpty({
          gatewayMethods: gatewayMethods?.reduce(
            (ids, method) => (method?.id ? [...ids, method?.id] : ids),
            [] as string[],
          ),
          direction: getId((mappings[mappingIndex] as any).direction?.toString()),
          merchantAccounts: {
            merchants: merchant,
          },
          groups: ['reference'],
        })}
        perPage={200}
        queryOptions={{ enabled: !!merchant && !!mappings[mappingIndex].method }}
        reference="gateways"
        source={`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.gateway`}
      >
        <AutocompleteInput
          {...(!gatewayMethods?.length && {
            choices: currentGateway ? [currentGateway] : [],
          })}
          disabled={!merchant || !mappings[mappingIndex].method}
          filterToQuery={(searchText: string) => ({
            name: searchText,
          })}
          label="Шлюз"
          name={`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.gateway`}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        filter={cleanEmpty({
          gateways: mappings[mappingIndex].mappingItems[mappingItemIndex].gateway,
          groups: ['reference'],
          include: [
            getValues(`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.merchantAccount`),
          ],
        })}
        perPage={200}
        reference="merchant_accounts"
        source="merchantAccount"
      >
        <AutocompleteInput
          disabled={!gateway}
          filterToQuery={(searchText: string) => ({
            name: searchText,
          })}
          label="Счёт"
          name={`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.merchantAccount`}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={cleanEmpty({
          groups: ['reference'],
          'provider.gateways': mappings[mappingIndex].mappingItems[mappingItemIndex].gateway,
          include: getValues(
            `mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.commissions`,
          ),
        })}
        perPage={200}
        reference="commissions"
        source="commissions"
      >
        <AutocompleteArrayInput
          clearOnBlur={true}
          disabled={!gateway || !merchantAccount}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          label="Комиссии"
          name={`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.commissions`}
          optionText="name"
          source="name"
          variant="outlined"
        />
      </ReferenceArrayInput>
      <NumberInput
        InputProps={{
          inputProps: { min: 0, max: 100 },
        }}
        label="Процент трафика"
        name={`mappings.${mappingIndex}.mappingItems.${mappingItemIndex}.value`}
        source="value"
        variant="outlined"
      />
      <GatewayRulesFormRemoveButton
        disabled={isDisabledRemoveMappingItem}
        onClick={() => onRemoveMappingItemClick(mappingItemIndex)}
        sx={{ marginTop: '4px' }}
      />
    </GatewayRulesFormMappingItemWrapper>
  );
};
