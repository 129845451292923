import { EMPTY_FIELD } from 'constants/emptyField';

import { useEffect } from 'react';

import { LockOpen } from '@mui/icons-material';
import { useRecordModal } from 'hooks/useRecordModal';
import { Pagination, useRemoveFromStore, useResourceContext } from 'react-admin';
import { UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { DeleteIconButton } from 'shared/mui/IconButtons';
import { IconLabel } from 'shared/mui/Labels/IconLabel/IconLabel';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { FunctionField, InviteStatusChipField, TextField } from 'shared/react-admin/Fields';
import { EditIconButton, ShowIconButton } from 'shared/react-admin/IconButtons';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { MerchantSpacesUsers } from 'types';
import { getId } from 'utils';

import { MerchantSpacesUsersDatagridBulkActionsButtons } from './components/MerchantSpacesUsersDatagridBulkActionsButtons';
import { MerchantSpacesRoleChip } from '../../../components/MerchantSpacesUsers/components/MerchantSpacesRoleChip';
import { MerchantSpacesUsersActivitySwitch } from '../../../components/MerchantSpacesUsers/MerchantSpacesUsersList/components/MerchantSpacesUsersActivitySwitch';
import { MerchantSpacesUsersSpaceField } from '../../../components/MerchantSpacesUsers/MerchantSpacesUsersList/components/MerchantSpacesUsersSpaceField';
import { MerchantSpacesUsersFilter } from '../../../components/MerchantSpacesUsers/MerchantSpacesUsersList/MerchantSpacesUsersList';
import { SettingsMerchantAvailabilityDialog } from '../SettingsMerchantAvailabilityDialog/SettingsMerchantAvailabilityDialog';

export const MerchantSpacesUsersDatagrid = ({
  refetch,
  form,
  isLoading,
  total,
}: {
  refetch: () => void;
  form: UseFormReturn<MerchantSpacesUsersFilter>;
  isLoading: boolean;
  total?: number;
}) => {
  const resource = useResourceContext();
  const remove = useRemoveFromStore();

  const settingsMerchantAvailabilityDialog = useRecordModal<MerchantSpacesUsers>();
  const [, setSearchParams] = useSearchParams();
  const deleteMerchantSpacesUserDialog = useRecordModal<MerchantSpacesUsers>();

  useEffect(() => {
    return () => {
      remove(`${resource}.selectedIds`);
      remove(`${resource}.listParams`);
    };
  }, []);

  return (
    <>
      <Datagrid
        bulkActionButtons={<MerchantSpacesUsersDatagridBulkActionsButtons />}
        isLoading={isLoading}
        sx={{
          '&.RaDatagrid-root tr:has(.Mui-checked)': {
            background: 'initial',
          },
          '&.RaDatagrid-root tr:has(.MuiCheckbox-root.Mui-checked)': {
            background: '#1976D214',
          },
        }}
      >
        <FunctionField label="ID" render={(record: MerchantSpacesUsers) => getId(record?.id)} />
        <FunctionField
          label="Активен"
          render={() => <MerchantSpacesUsersActivitySwitch refetchMerchantSpacesUsers={refetch} />}
        />
        <FunctionField
          label="Имя, фамилия"
          render={(record: MerchantSpacesUsers) =>
            !!record.firstName && !!record.lastName
              ? `${record.firstName} ${record.lastName}`
              : EMPTY_FIELD
          }
        />
        <TextField label="Email" source="email" />
        <FunctionField label="Личный кабинет" render={() => <MerchantSpacesUsersSpaceField />} />
        <FunctionField
          label="Роль"
          render={() => {
            return <MerchantSpacesRoleChip />;
          }}
        />
        <FunctionField
          label={<IconLabel icon={LockOpen} label="Доступность мерчантов" />}
          render={(record: MerchantSpacesUsers) => (
            <Button
              label="Смотреть"
              onClick={() => settingsMerchantAvailabilityDialog.handleOpenRecordModal(record)}
              startIcon={<LockOpen />}
            />
          )}
        />
        <InviteStatusChipField label="Статус приглашения" />
        <ActionsSlots
          render={(record: MerchantSpacesUsers) => (
            <>
              <ShowIconButton />
              <EditIconButton />
              <DeleteIconButton
                onClick={() => deleteMerchantSpacesUserDialog.handleOpenRecordModal(record)}
              />
            </>
          )}
        />
      </Datagrid>
      <Pagination
        page={form.getValues('page')}
        perPage={form.getValues('itemsPerPage')}
        setPage={(page) => {
          form.setValue('page', page);
          setSearchParams((prev) => {
            return { ...Object.fromEntries(prev), page: page.toString() };
          });
        }}
        setPerPage={(itemsPerPage) => {
          form.setValue('itemsPerPage', itemsPerPage);
          setSearchParams((prev) => {
            return { ...Object.fromEntries(prev), itemsPerPage: itemsPerPage.toString() };
          });
        }}
        total={total}
      />
      <SettingsMerchantAvailabilityDialog
        onClose={settingsMerchantAvailabilityDialog.handleCloseRecordModal}
        open={settingsMerchantAvailabilityDialog.openRecordModal}
        record={settingsMerchantAvailabilityDialog.record}
      />
      <DeleteDialog
        details={`Пользователь ${deleteMerchantSpacesUserDialog.record?.firstName + ' ' + deleteMerchantSpacesUserDialog.record?.lastName || ''} будет удален без возможности восстановления.`}
        onClose={deleteMerchantSpacesUserDialog.handleCloseRecordModal}
        open={deleteMerchantSpacesUserDialog.openRecordModal}
        record={deleteMerchantSpacesUserDialog.record}
        title="Удаление пользователя"
      />
    </>
  );
};
