import { useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import { queryClient } from 'App';
import { merchantPayoutControlsCustomersUpdate } from 'config/requests';
import { RaRecord, SimpleForm, useGetList, useNotify, useRecordContext } from 'react-admin';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { MerchantCustomer } from 'types';

import { StyledBox, StyledDialog } from './EditPayoutControlTestClientsExceptionsAddDialog.styled';
import { useEditPayoutControlContext } from '../../context/EditPayoutControlContext';

type Props = {
  onCreate: () => void;
} & DialogProps;

export const EditPayoutControlTestClientsExceptionsAddDialog: React.FC<Props> = ({
  open,
  onClose,
  onCreate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { payoutControl } = useEditPayoutControlContext();
  const record = useRecordContext();
  const notify = useNotify();
  const isCreateCustomerEnabled = useFlag('wppa-6583-create-customer');

  const { data: customersList, isLoading: isLoadingCustomersList } = useGetList<MerchantCustomer>(
    'customers',
    {
      filter: { merchant: record.id },
    },
  );

  const customerOptions = customersList?.map(({ customerId, originId, merchantPayoutControl }) => ({
    id: Number(originId),
    customerId,
    merchantPayoutControl,
  }));

  const handleSubmit: SubmitHandler<FieldValues> = async ({ customers }) => {
    setIsLoading(true);
    const customersData = (customers as MerchantCustomer[]).map(
      (customer) => `/admin/customers/${customer}`,
    );

    merchantPayoutControlsCustomersUpdate(payoutControl?.id, customersData)
      .then(() => {
        queryClient.refetchQueries(['merchant_payout_controls', record?.originId]);
        onClose();
      })
      .catch((err) => {
        notify(err.data.detail || 'Ошибка сервера', { type: 'error' });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<></>}>
        <StyledBox>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Добавление тестовых клиентов в исключения"
          />
          <CardContent>
            {isLoadingCustomersList && <LinearProgress />}
            {customerOptions ? (
              <AutocompleteMultiselectInput
                InputLabelProps={{ shrink: true }}
                defaultValue={payoutControl.customers}
                fullWidth
                getOptionLabel={({ customerId }) => customerId}
                helperText={false}
                label="Внешний ID"
                name="customers"
                optionText="customerId"
                options={customerOptions}
                renderChipLabel={(record: RaRecord) => `${record.customerId}`}
                size="medium"
              />
            ) : null}
          </CardContent>
          <CardActionsButtons
            leftActionsSlot={[
              <Button
                disabled={isLoading}
                key="save-button"
                label="Сохранить"
                type="submit"
                variant="contained"
              />,
              <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            ]}
            rightActionsSlot={
              isCreateCustomerEnabled && (
                <Button label="Создать нового" onClick={onCreate} variant="outlined" />
              )
            }
          />
        </StyledBox>
      </SimpleForm>
    </StyledDialog>
  );
};
