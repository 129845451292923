import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { useFilters } from '../../../hooks/useFilters';

type MerchantsFilterValues = { name: string; companies: string[] };

export const MerchantsListFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantsFilterValues>({
    name: '',
    companies: [],
  });
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              id="name"
              key="name"
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <ReferenceArrayInput
              isFilter={true}
              name="companies"
              perPage={200}
              reference="companies"
              source="companies"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Компания"
                name="companies"
                optionText="name"
              />
            </ReferenceArrayInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
