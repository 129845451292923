import React, { Fragment } from 'react';

import { Introspecter } from '@api-platform/admin';
import { NumberInput, NumberInputProps, useResourceContext } from 'react-admin';

import { fieldValidateGuesser } from '../../../utils';

const IntrospectedNumberInput = ({ fields, validate, ...props }: any): JSX.Element => {
  const { guessedValidate, isError } = fieldValidateGuesser({ fields, validate, ...props });
  if (isError) {
    return <Fragment />;
  }
  return <NumberInput validate={guessedValidate} {...props} />;
};

const AppNumberInput = (props: NumberInputProps): JSX.Element => {
  const resource = useResourceContext(props);

  return (
    <Introspecter
      component={IntrospectedNumberInput as any}
      includeDeprecated={true}
      resource={resource}
      {...props}
    />
  );
};

export default AppNumberInput;
