import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Filter } from 'shared/mui/Filter/Filter/Filter';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { ReferenceInputIntrospected } from 'shared/react-admin/ReferenceInputs';
import { getLastUrlSegment } from 'utils/getLastUrlSegment';

import { Direction, OrderMethodsListFilter } from './OrderMethodsList';
import { APP_VERSIONS } from '../../constants/appVersions';
import { LANGUAGES } from '../../constants/languages';
import { DOMAIN_REGEX } from '../../constants/regexp';
import { Country, Currency, Merchant } from '../../types';

type Props = {
  currencies: Currency[];
  merchants: Merchant[];
  countries: Country[];
  onGenerateClick: (filter: OrderMethodsListFilter) => void;
  setFilters: (filters: OrderMethodsListFilter) => void;
};

const OrderMethodsFilter: React.FC<Props> = ({
  currencies,
  merchants,
  countries,
  onGenerateClick,
  setFilters,
}) => {
  const form = useForm<OrderMethodsListFilter>({
    defaultValues: {
      currency: null,
      country: null,
      merchant: null,
      direction: 'payment',
      customerId: '',
      language: null,
      countDeposit: '',
      appVersion: null,
      domain: '',
    },
  });

  const handleFilter = (): void => {
    const { direction, ...values } = form.getValues();
    onGenerateClick({ ...values, direction: getLastUrlSegment(direction) as Direction });
  };

  const resetFilter = (): void => {
    form.reset({});
    setFilters({});
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleFilter)}>
        <Filter>
          <FilterRow sx={{ mb: 2 }}>
            <Controller
              control={form.control}
              name="merchant"
              render={({ field, formState: { errors } }) => {
                return (
                  <Autocomplete
                    getOptionLabel={(merchant) => merchant.name}
                    id="merchant"
                    onChange={(_event, merchant) => field.onChange(merchant)}
                    options={merchants || []}
                    renderInput={(params) => (
                      <TextFieldShrink
                        {...params}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message as React.ReactNode}
                        label="Мерчант"
                        variant="outlined"
                      />
                    )}
                    value={field.value}
                  />
                );
              }}
              rules={{ required: 'Обязательное поле' }}
            />
            <Controller
              control={form.control}
              name="domain"
              render={({ field, formState: { errors } }) => (
                <TextFieldShrink
                  error={!!errors.domain}
                  helperText={errors[field.name]?.message}
                  id="domain"
                  label="Домен"
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  variant="outlined"
                />
              )}
              rules={{
                pattern: {
                  value: DOMAIN_REGEX,
                  message: 'Некорректный домен',
                },
              }}
            />
            <Controller
              control={form.control}
              name="appVersion"
              render={({ field }) => (
                <Autocomplete
                  getOptionLabel={(appVersion) => appVersion}
                  id="appVersion"
                  onChange={(_event, appVersion) => {
                    field.onChange(appVersion);
                  }}
                  options={APP_VERSIONS}
                  renderInput={(params) => (
                    <TextFieldShrink {...params} label="Версия приложения" variant="outlined" />
                  )}
                  value={field.value}
                />
              )}
            />
            <Controller
              control={form.control}
              name="country"
              render={({ field, formState: { errors } }) => (
                <Autocomplete
                  getOptionLabel={(country) => country.name}
                  id="country"
                  isOptionEqualToValue={(option, value) => option.alphaCode === value.alphaCode}
                  loading={!(countries && countries.length <= 0)}
                  onChange={(_event, country) => field.onChange(country)}
                  options={countries}
                  renderInput={(params) => (
                    <TextFieldShrink
                      {...params}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      label="Страна"
                      variant="outlined"
                    />
                  )}
                  value={field.value}
                />
              )}
              rules={{ required: 'Обязательное поле' }}
            />
          </FilterRow>
          <FilterRow sx={{ mb: 2 }}>
            <Controller
              control={form.control}
              name="currency"
              render={({ field, formState: { errors } }) => (
                <Autocomplete
                  getOptionLabel={(currency) => `${currency.alphaCode}, ${currency.name}`}
                  id="currency"
                  isOptionEqualToValue={(option, value) => option.alphaCode === value.alphaCode}
                  loading={!(currencies && currencies.length <= 0)}
                  onChange={(_event, currency) => field.onChange(currency)}
                  options={currencies}
                  renderInput={(params) => (
                    <TextFieldShrink
                      {...params}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message as React.ReactNode}
                      label="Валюта"
                      variant="outlined"
                    />
                  )}
                  value={field.value}
                />
              )}
              rules={{ required: 'Обязательное поле' }}
            />
            <Controller
              control={form.control}
              name="customerId"
              render={({ field, formState: { errors } }) => (
                <TextFieldShrink
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  id="customerId"
                  label="ID клиента"
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  variant="outlined"
                />
              )}
              rules={{ required: 'Обязательное поле' }}
            />
            <Controller
              control={form.control}
              name="language"
              render={({ field }) => (
                <Autocomplete
                  getOptionLabel={(language) => language.title}
                  id="language"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_event, language) => field.onChange(language)}
                  options={LANGUAGES}
                  renderInput={(params) => (
                    <TextFieldShrink {...params} label="Язык" variant="outlined" />
                  )}
                  value={field.value}
                />
              )}
            />
            <Controller
              control={form.control}
              name="countDeposit"
              render={({ field, formState: { errors } }) => (
                <TextFieldShrink
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  id="countDeposit"
                  label="Число депозитов"
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  variant="outlined"
                />
              )}
              rules={{
                validate: (value) => !isNaN(Number(value)) || 'Введите число',
              }}
            />
          </FilterRow>
          <FilterRow>
            <ReferenceInputIntrospected
              name="direction"
              reference="directions"
              resource="directions"
              source="direction"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ description: searchText })}
                fullWidth
                helperText={false}
                label="Направление"
                name="direction"
                optionText="description"
                optionValue="id"
                variant="outlined"
              />
            </ReferenceInputIntrospected>
          </FilterRow>
          <FilterRow marginTop={2}>
            <FilterActions>
              <FilterApplyButton label="Применить" type="submit" />
              <FilterResetButton label="Сбросить" onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};

export default OrderMethodsFilter;
