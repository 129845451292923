import { styled } from '@mui/material/styles';
import { EditGuesser } from 'shared/react-admin/Guessers/EditGuesser/EditGuesser';
import { appColors } from 'themes/variables';

export const StyledEditGuesser = styled(EditGuesser)({
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  marginTop: '24px',
  backgroundColor: appColors.background,
  width: '444px',
  padding: 0,
  '& .MuiCardContent-root:last-child': {
    padding: 0,
  },
  '& .RaEdit-main': {
    margin: 0,
  },
});
