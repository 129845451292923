import { useEffect } from 'react';

import { useFirstRender } from 'hooks/useFirstRender';
import qs from 'qs';
import { AutocompleteInput, RaRecord, BooleanInput, FormDataConsumer, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  AutocompleteInputIntrospected,
  JsonInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import {
  ReferenceArrayInputIntrospected,
  ReferenceInputIntrospected,
} from 'shared/react-admin/ReferenceInputs';
import { Hold } from 'types';

import { fieldWidth } from '../../../themes/variables';
import { AppAutocompleteArrayInput } from '../../Common';
import { useHold } from '../hooks/useHold';

type MerchantDefaultValueType = {
  merchant: string;
};

// ToDo валидация поля Мерчанты работает некорректно, пофиксить
// ToDo при редизайне нужно перевести страницу на guesser + introspected или клиентскую валидацию

export const MerchantAccountsCreateForm: React.FC = () => {
  const location = useLocation();
  const { getHoldOptionText } = useHold();

  const { watch, setValue, setError, formState } = useFormContext();
  const providerFormValue = watch('provider');
  const { isFirstRender } = useFirstRender();
  const gatewaysFormValue = watch('gateways');

  useEffect(() => {
    if (!isFirstRender) {
      setValue('gateways', []);
    }
  }, [providerFormValue]);

  useEffect(() => {
    if (formState.isSubmitted && !gatewaysFormValue?.length) {
      setError('gateways', { type: 'string', message: 'Обязательно для заполнения' });
    }
  }, [gatewaysFormValue]);

  const defaultValues = qs.parse(
    location?.search.replace('?', '') as string,
  ) as MerchantDefaultValueType;

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <BooleanInput label="Активно" source="active" />
          <TextInputIntrospected
            label="Название"
            size="small"
            source="name"
            sx={fieldWidth.medium}
            variant="outlined"
          />
          <ReferenceInputIntrospected perPage={200} reference="providers" source="provider">
            <AutocompleteInputIntrospected
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Провайдер"
              optionText="name"
              source="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <ReferenceArrayInputIntrospected
            filter={{ provider: formData.provider || '' }}
            perPage={200}
            reference="gateways"
            source="gateways"
          >
            <AppAutocompleteArrayInput
              clearOnBlur={false}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Шлюз"
              optionText="name"
              source="name"
              sx={{ ...fieldWidth.medium, marginBottom: '10px' }}
              validate={required()}
              variant="outlined"
            />
          </ReferenceArrayInputIntrospected>
          <ReferenceInputIntrospected
            filter={{ active: true }}
            perPage={1000}
            reference="currencies"
            sort={{ field: 'id', order: 'ASC' }}
            source="currency"
          >
            <AutocompleteInputIntrospected
              filterToQuery={(searchText: string) => ({ currency: searchText })}
              label="Валюта"
              optionText={(record: RaRecord) => record && `${record.alphaCode}, ${record.name}`}
              source="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <ReferenceInputIntrospected reference="holds" source="hold">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Холд"
              matchSuggestion={(filter, choice: Hold) => {
                return Boolean(getHoldOptionText(choice.value, choice.currency).match(filter));
              }}
              optionText={(record: Hold) => {
                return record && getHoldOptionText(record.value, record.currency);
              }}
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <ReferenceArrayInputIntrospected perPage={200} reference="merchants" source="merchants">
            <AppAutocompleteArrayInput
              clearOnBlur={false}
              defaultValue={defaultValues.merchant ? [defaultValues.merchant] : []}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Мерчанты"
              optionText="name"
              source="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceArrayInputIntrospected>
          <ReferenceInputIntrospected reference="merchant_credentials" source="merchantCredentials">
            <AutocompleteInput
              filterToQuery={(searchText: string) => ({ name: searchText })}
              label="Доступы мерчанта"
              optionText="name"
              sx={fieldWidth.medium}
              variant="outlined"
            />
          </ReferenceInputIntrospected>
          <JsonInputIntrospected label="Доступы счёта" source="credentials" />
        </>
      )}
    </FormDataConsumer>
  );
};
