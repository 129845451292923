import { useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Labeled, useInput } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';
import { Tooltip } from 'shared/mui/Tooltip';

import { JsonEditorStyled } from './JsonInput.styled';

export const JsonInput = (props: any): JSX.Element => {
  const { field, isRequired } = useInput(props);
  const [data, setData] = useState({});
  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    if (field.value) {
      setData(field.value);
    }
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
      setTooltipText('Cкопировано');
      setTimeout(() => setTooltipText(''), 2000);
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
    }
  };

  return (
    <Labeled {...props} isRequired={isRequired}>
      <Box sx={{ position: 'relative' }}>
        <JsonEditorStyled
          data={data}
          onUpdate={({ newData }) => {
            setData(newData);
            field.onChange(newData);
          }}
          stringTruncate={1000000}
          theme={
            [
              'githubDark',
              {
                iconEdit: 'grey',
                boolean: { color: 'red', fontStyle: 'italic', fontWeight: 'bold', fontSize: '80%' },
              },
            ] as any
          }
        />
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={!!tooltipText}
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          title={tooltipText}
        >
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon color="secondary" />
          </IconButton>
        </Tooltip>
      </Box>
    </Labeled>
  );
};
