import { AppReferenceOneField } from 'components/Common';
import {
  ChipField,
  FunctionField,
  RaRecord,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { CreateButton } from 'shared/mui/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { HoldsFilter } from './HoldsFilter';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types';
import { AppListEditButton } from '../Common/Buttons';
import { AppList } from '../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

const HoldsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <AppPanelHeader>
        <AppPanelHeaderToolbar
          actions={[
            hasResourcePermissions('holds', [Permission.Full, Permission.Create]) && (
              <CreateButton />
            ),
          ]}
          titleText="Холды"
        />
      </AppPanelHeader>
      <HoldsFilter />
      <Datagrid>
        <TextField label="Id" sortBy="id" source="originId" />
        <TextField label="Значение" source="value" />
        {hasResourceAccess('currencies') && (
          <AppReferenceOneField label="Валюта" reference="currencies" source="currency" target="id">
            <FunctionField
              label="Name"
              render={(record: RaRecord | undefined) => `${record?.alphaCode}, ${record?.name}`}
            />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('merchant_accounts') && (
          <ReferenceArrayField
            label="Счета мерчантов"
            reference="merchant_accounts"
            source="merchantAccounts"
          >
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <ActionsSlots
          render={() => (
            <>
              {hasResourcePermissions('holds', [Permission.Full, Permission.Update]) && (
                <AppListEditButton />
              )}
            </>
          )}
        />
      </Datagrid>
    </AppList>
  );
};

export default HoldsList;
