import ContentAdd from '@mui/icons-material/Add';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';

import { StyledTextField } from './UserEntityPermissionsForm.styled';
import { UserEntityPermissionsFormPermission } from './UserEntityPermissionsFormPermission';
import { UserEntityPermissionsNames } from '../../constants/userEntityPermissionsNames';
import { useUserEntityPermissionsFieldArray } from '../../hooks/useUserEntityPermissionsFieldArray';

type Props = {
  recordName: string;
  recordId: string;
  entityName: UserEntityPermissionsNames;
};

export const UserEntityPermissionsForm: React.FC<Props> = ({
  recordName,
  recordId,
  entityName,
}) => {
  const { fields, onAddField, onRemoveField } = useUserEntityPermissionsFieldArray(
    recordId,
    entityName,
  );
  return (
    <Box>
      <StyledTextField
        defaultValue={recordName}
        disabled
        label="Название"
        size="medium"
        variant="outlined"
      />
      {fields.map((field, index) => (
        <UserEntityPermissionsFormPermission
          id={field.id}
          index={index}
          key={index}
          onRemoveField={onRemoveField}
        />
      ))}
      <Button
        label="Добавить доступ"
        onClick={onAddField}
        startIcon={<ContentAdd />}
        variant="outlined"
      />
    </Box>
  );
};
