import { useQuery } from 'react-query';

import { getConditionGroups } from '../../../config/requests';
import { ConditionGroup } from '../../Common/Forms/GatewayRulesForm/GatewayRulesForm';

type UseConditionGroupsReturnValues = {
  conditionGroup: ConditionGroup | null;
  isLoadingConditionGroup: boolean;
  refetchConditionGroup: () => Promise<ConditionGroup | unknown>;
};

export function useConditionGroups(id?: string): UseConditionGroupsReturnValues {
  const {
    data: conditionGroup = null,
    isLoading: isLoadingConditionGroup,
    refetch: refetchConditionGroup,
  } = useQuery<ConditionGroup | null>(
    ['condition_groups', id],
    () => (id ? getConditionGroups(id) : null),
    {
      enabled: typeof id !== 'undefined' && id !== '' && id !== null,
      cacheTime: 0,
    },
  );

  return { conditionGroup, isLoadingConditionGroup, refetchConditionGroup };
}
