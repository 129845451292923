import { useInitialExpression } from 'components/Common/Forms/GatewayRulesForm/hooks';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ExpressionParam } from 'types';

type UseExpressionFieldReturnValues = {
  expressionsFields: Record<'id', string>[];
  onAddExpressionClick: () => void;
  onRemoveExpressionClick: (index: number) => void;
};

type Props = {
  expressionParams: ExpressionParam[];
  name: string;
};

export function useExpressionField({
  expressionParams,
  name,
}: Props): UseExpressionFieldReturnValues {
  const { control } = useFormContext();
  const {
    fields: expressionsFields,
    append: appendExpression,
    remove: removeExpression,
  } = useFieldArray({
    name,
    control,
  });

  const initialExpression = useInitialExpression(expressionParams);

  function onAddExpressionClick(): void {
    appendExpression(initialExpression);
  }

  function onRemoveExpressionClick(index: number): void {
    removeExpression(index);
  }

  return {
    expressionsFields,
    onAddExpressionClick,
    onRemoveExpressionClick,
  };
}
