import { MappingItemFormValues } from '../hooks/useGatewayRulesForm';

export function getInitialMappingItem(): Nullable<MappingItemFormValues> {
  return {
    commissions: [],
    gateway: null,
    merchantAccount: null,
    value: null,
  };
}
