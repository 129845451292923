import { useEffect } from 'react';

import { getOrderStatuses } from 'config/requests';
import { useGetList, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Popper } from 'shared/mui/Popper/Popper';
import { Row } from 'shared/mui/Row/Row';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import {
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs/TextInputs';
import { Select, SelectItem } from 'shared/react-admin/Selects/Select/Select';
import { RoleCategory } from 'types';

import { RolesFormDirection } from './RolesFormDirection';

type RolesFormProps = {
  defaultOrderStatuses?: string[];
};

const CustomPopper = (props: any) => (
  <Popper {...props} disablePortal popperOptions={{ placement: 'bottom' }} />
);

export const RolesForm: React.FC<RolesFormProps> = ({ defaultOrderStatuses }) => {
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const { data: roleCategories } = useGetList<RoleCategory>('role_categories');
  const { data: roleOrderStatuses } = useQuery('new/order_statuses', getOrderStatuses);

  const orderStatuses = roleOrderStatuses;

  const defaultOrderStatusesFiltered =
    defaultOrderStatuses &&
    orderStatuses?.filter(({ value }) => defaultOrderStatuses.find((status) => status === value));

  const roleCategoriesOptions = roleCategories?.map(({ id, value, description }) => ({
    value: id,
    label: value,
    description,
  }));

  const defaultRoleIcon = roleCategoriesOptions?.find(({ label }) => label === 'default');
  const selectedRoleIcon =
    roleCategoriesOptions?.find(({ value }) => value === record?.roleCategories) || defaultRoleIcon;

  useEffect(() => {
    if (defaultRoleIcon?.value === selectedRoleIcon?.value) {
      setValue('roleCategories', selectedRoleIcon?.value);
    }
  }, [defaultRoleIcon?.value]);

  return (
    <CardContent sx={{ paddingTop: '0!important', width: '396px' }}>
      <Row sx={{ maxWidth: '396px' }}>
        <TextInputIntrospected
          InputLabelProps={{ shrink: true }}
          fullWidth
          label="Название"
          size="medium"
          source="name"
          sx={{ width: '304px' }}
          variant="outlined"
        />
        {roleCategories && (
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  width: '396px',
                  height: '300px',
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
            defaultValue={selectedRoleIcon}
            items={roleCategoriesOptions}
            label="Иконка"
            renderValue={({ description }: SelectItem<string>) => (
              <RolesFormDirection description={description} />
            )}
            required
            shrink
            source="roleCategories"
            sx={{
              width: '76px',
              marginTop: 0,
            }}
          >
            {roleCategoriesOptions?.map(({ description, value, label }) => (
              <MenuItem key={value} value={value}>
                <RolesFormDirection description={description} value={label} />
              </MenuItem>
            ))}
          </Select>
        )}
      </Row>
      <TextInputWithAliasIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Алиас"
        source="alias"
        transformValue={(value) => value.replace(/[0-9-]/g, '').replace(/^_+([a-zA-Z])/, '$1')}
        variant="outlined"
      />
      {orderStatuses ? (
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          ListboxProps={{ sx: { height: '250px' } }}
          PopperComponent={CustomPopper}
          defaultValue={defaultOrderStatusesFiltered}
          fullWidth
          getOptionLabel={({ description }) => description}
          helperText={false}
          label="Статусы заказа"
          name="allowedStatusesEditing"
          optionText="value"
          optionValue="value"
          options={orderStatuses}
          renderChipLabel={({ description }) => description}
          size="medium"
        />
      ) : null}
    </CardContent>
  );
};
