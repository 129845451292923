import { useCallback, useState } from 'react';

import { updateGatewayRuleActive } from 'config/requests';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Switch } from 'shared/mui/Switch';

import { GatewayRuleActiveSwitchConfirm } from './GatewayRuleActiveSwitchConfirm';
import { useRecordModal } from '../../../hooks/useRecordModal';
import { GatewayRuleRecord } from '../../../types';

type Props = {
  size?: 'small' | 'medium';
  onSuccess?: () => void;
};

export const GatewayRuleActiveSwitch: React.FC<Props> = ({ size = 'medium', onSuccess }) => {
  const { handleOpenRecordModal, handleCloseRecordModal, openRecordModal } = useRecordModal();
  const notify = useNotify();
  const record = useRecordContext<GatewayRuleRecord>();
  const [isActive, setIsActive] = useState(record.active);
  const refresh = useRefresh();

  const defaultOnSuccess = () => {
    notify('Активность успешна изменена.', { type: 'success' });
    refresh();
  };

  const updateActiveRule = async () => {
    try {
      await updateGatewayRuleActive(record.id, !isActive);
      notify('Активность правила успешно изменена', { type: 'success' });
      if (onSuccess) {
        onSuccess();
      } else {
        defaultOnSuccess();
      }
      setIsActive(!isActive);
    } catch (error) {
      notify(`Ошибка: ${error as string}` || 'Ошибка удаления правила', { type: 'error' });
    }
  };

  const toggleActiveGatewayRule = useCallback(() => {
    handleOpenRecordModal(record);
  }, [record]);

  return (
    <>
      <GatewayRuleActiveSwitchConfirm
        onClose={handleCloseRecordModal}
        onConfirm={updateActiveRule}
        open={openRecordModal}
        record={record}
      />
      <Switch
        checked={isActive}
        onClick={toggleActiveGatewayRule}
        size={size}
        sx={{
          marginRight: '16px',
        }}
      />
    </>
  );
};
