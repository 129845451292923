import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import {
  DeleteIconButton,
  EditDialogIconButton,
  ShowDialogIconButton,
} from 'shared/mui/IconButtons';
import { ExportButton } from 'shared/react-admin/Buttons';
import { ImageField, TextField, TooltipTextField } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages/ListPage/ListPage';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { StyledDatagrid } from './MethodLogosList.styled';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { MethodLogo, Permission } from '../../../types';
import { MethodLogosDeleteDialog } from '../components/MethodLogosDeleteDialog/MethodLogosDeleteDialog';
import { MethodLogosFilters } from '../MethodLogosFilters';

export const MethodLogosList: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<MethodLogo>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('method_logos', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create_method_logos" />
        ),
        <ExportButton key="export_method_logos" />,
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Логотипы методов',
      }}
    >
      <MethodLogosFilters />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" sortable={false} source="originId" />
        <TooltipTextField label="Название метода" source="name" />
        <ImageField
          label="Логотип"
          sortable={false}
          source="filePath"
          sx={{
            '& .RaImageField-image': {
              maxWidth: 60,
              maxHeight: 60,
              minWidth: 60,
              minHeight: 60,
              objectFit: 'contain',
            },
          }}
        />
        <ActionsSlots
          render={(record: MethodLogo) => (
            <>
              {hasResourceAccess('user_actions') && (
                <UserActionsIconButton entityName="method_logo" />
              )}
              <ShowDialogIconButton />
              {hasResourcePermissions('method_logos', [Permission.Full, Permission.Update]) && (
                <EditDialogIconButton />
              )}
              {hasResourcePermissions('method_logos', [Permission.Full, Permission.Delete]) && (
                <DeleteIconButton onClick={() => handleOpenRecordModal(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModal && (
        <MethodLogosDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
    </ListPage>
  );
};
