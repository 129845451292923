import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteArrayInputShrink, AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';

import { useFilters } from '../../hooks/useFilters';

type MerchantAccountsFilterFormValues = {
  provider: string;
  gateways: string[];
  currencies: string[];
  name: string;
  merchants: string[];
};

export const MerchantAccountsFilters: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantAccountsFilterFormValues>({
    provider: '',
    gateways: [],
    currencies: [],
    name: '',
    merchants: [],
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              label="Название"
              name="name"
              source="name"
              variant="outlined"
            />
            <ReferenceArrayInput
              filter={{ active: true }}
              isFilter={true}
              name="currency"
              perPage={1000}
              reference="currencies"
              sort={{ field: 'id', order: 'ASC' }}
              source="currency"
            >
              <AutocompleteArrayInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                name="currency"
                optionText={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceInput
              isFilter={true}
              name="provider"
              perPage={100}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              isFilter={false}
              name="gateways"
              perPage={200}
              reference="gateways"
              source="gateways"
            >
              <AutocompleteArrayInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюз"
                name="gateways"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              isFilter={true}
              perPage={200}
              reference="merchants"
              source="merchants"
            >
              <AutocompleteArrayInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Мерчанты"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
