import { DEFAULT_PAGINATION } from 'constants/defaultPagination';

import { useEffect, useState } from 'react';

import { useGetMerchantRules } from 'components/Merchants/hooks/useGetMerchantRules';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { ListContextProvider, useList, useShowController } from 'react-admin';
import { useForm } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import { DeleteIconButton } from 'shared/mui/IconButtons';
import { PageHeader } from 'shared/mui/PageHeader';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { EditIconButton, ShowIconButton } from 'shared/react-admin/IconButtons';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { GatewayRuleRecord, Merchant, Permission } from 'types';

import { MerchantShowRulesDeleteDialog } from './components/MerchantShowRulesDeleteDialog';
import { MerchantShowRulesNameField } from './components/MerchantShowRulesNameField';
import {
  MerchantShowRulesFilterValues,
  MerchantShowRulesListFilter,
} from './MerchantShowRulesListFilter';
import { StyledDatagrid, StyledPagination } from './MerchantShowRulesListStyled.styled';
import { UserActionsIconButton } from '../../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

const DEFAULT_FILTER_VALUES = {
  ...DEFAULT_PAGINATION,
  name: null,
};

export const MerchantShowRulesList: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record: merchantRecord } = useShowController<Merchant>();
  const [filters, setFilters] = useState<MerchantShowRulesFilterValues>(DEFAULT_FILTER_VALUES);

  const form = useForm<MerchantShowRulesFilterValues>({
    defaultValues: DEFAULT_FILTER_VALUES,
  });

  const { merchantRules, totalItems, isLoading, refetch } = useGetMerchantRules(
    merchantRecord?.originId,
    filters,
  );

  const merchantRulesListContext = useList({
    data: merchantRules || [],
    isLoading,
    page: form.getValues('page'),
    perPage: form.getValues('itemsPerPage'),
  });

  useEffect(() => {
    refetch();
  }, [filters]);

  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<GatewayRuleRecord>();

  const onReset = () => {
    setFilters(DEFAULT_FILTER_VALUES);
    form.reset();
  };

  const onSubmit = () => {
    setFilters(form.getValues());
  };

  return (
    <ListContextProvider value={merchantRulesListContext}>
      <PageHeader
        actions={[
          hasResourcePermissions('gateway_rules', [Permission.Full, Permission.Create]) && (
            <CreateButton
              key="create-rules"
              resource="gateway_rules"
              sx={{ marginTop: '8px', padding: '3px 16px' }}
              target="_blank"
            />
          ),
        ]}
        headerTitle={{
          titleText: 'Список правил мерчанта',
          titleSize: 'small',
        }}
      />
      {merchantRules?.length || isLoading || filters.name ? (
        <>
          <MerchantShowRulesListFilter form={form} onReset={onReset} onSubmit={onSubmit} />
          <StyledDatagrid>
            <TextField label="ID" sortable={false} source="id" />
            <MerchantShowRulesNameField label="Название" source="name" />
            <DateTimeField label="Дата создания" sortable={false} source="createdAt" />
            <DateTimeField label="Дата редактир." sortable={false} source="updatedAt" />
            <ActionsSlots
              render={(record: GatewayRuleRecord) => (
                <>
                  {hasResourceAccess('user_actions') && (
                    <UserActionsIconButton
                      entityId={record?.id}
                      entityName="gateway_rule"
                      target="_blank"
                    />
                  )}
                  <ShowIconButton
                    record={{ ...record, id: `/admin/gateway_rules/${record.id}` }}
                    resource="gateway_rules"
                    target="_blank"
                  />
                  {hasResourcePermissions('gateway_rules', [
                    Permission.Full,
                    Permission.Update,
                  ]) && (
                    <EditIconButton
                      record={{ ...record, id: `/admin/gateway_rules/${record.id}` }}
                      resource="gateway_rules"
                      target="_blank"
                    />
                  )}
                  {hasResourcePermissions('gateway_rules', [
                    Permission.Full,
                    Permission.Delete,
                  ]) && <DeleteIconButton onClick={() => handleOpenDeleteDialog(record)} />}
                </>
              )}
            />
          </StyledDatagrid>
          <StyledPagination
            page={form.getValues('page')}
            perPage={form.getValues('itemsPerPage')}
            setPage={(page) => {
              form.setValue('page', page);
              setFilters((prev) => ({ ...prev, page: page }));
            }}
            setPerPage={(itemsPerPage) => {
              form.setValue('itemsPerPage', itemsPerPage);
              setFilters((prev) => ({ ...prev, itemsPerPage: itemsPerPage }));
            }}
            total={totalItems}
          />
        </>
      ) : (
        <Alert severity="info">{`Мерчанту ${merchantRecord?.name} не добавлены правила.`}</Alert>
      )}
      <MerchantShowRulesDeleteDialog
        onClose={handleCloseDeleteDialog}
        open={openDeleteDialog}
        record={recordDeleteDialog}
      />
    </ListContextProvider>
  );
};
