import { useCallback } from 'react';
// (value: number) => string | undefined

interface IuseMerchantAlertsValidationReturn {
  validatePercentFailure: (value: number) => string | undefined;
}

export function useMerchantAlertsValidation(): IuseMerchantAlertsValidationReturn {
  const validatePercentFailure = useCallback((value: number): string | undefined => {
    if (!Number.isInteger(value)) {
      return 'Значение должно быть целым числом';
    }
    return undefined;
  }, []);

  return { validatePercentFailure };
}
