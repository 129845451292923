import React from 'react';

import { Settings } from '@mui/icons-material';
import { EditButton, ListActionsProps, TextField, useRecordContext } from 'react-admin';

import AppReferenceField from './AppReferenceField';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';

const AppMerchantCredentials = (props: ListActionsProps): JSX.Element => {
  const record = useRecordContext(props);
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  if (record.merchantCredentials) {
    if (!hasResourceAccess('merchant_credentials')) {
      return <></>;
    }

    return (
      <AppReferenceField link="show" reference="merchant_credentials" source="merchantCredentials">
        <TextField source="name" />
      </AppReferenceField>
    );
  }

  if (!hasResourcePermissions('merchant_credentials', [Permission.Full, Permission.Create])) {
    return <>—</>;
  }

  const pathname = '/merchant_accounts' + '/' + encodeURIComponent(record.id);

  return <EditButton icon={<Settings />} label="Добавить" to={{ pathname }} />;
};

export default AppMerchantCredentials;
