import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

export const BoxCollapsed = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  justifyContent: 'space-between',
  minHeight: '56px',
});

export const BoxNotCollapsed = styled(Box)({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  padding: '16px 0',
});
