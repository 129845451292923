import { OrderStatuses } from 'constants/orderStatuses';

import { ChartDataset, ScriptableContext } from 'chart.js';
import { appColors } from 'themes/variables';

const Y_AXIS_ID = 'y';
const BORDER_WIDTH = 2;
const POINT_RADIUS = 2;
const POINT_STYLE = 'circle';

export const defaultDatasets: Record<string, ChartDataset> = {
  [OrderStatuses.Created]: {
    label: 'Создан',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Created],
    backgroundColor: appColors.orderStatus[OrderStatuses.Created],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Prepared]: {
    label: 'Подготовлен',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Prepared],
    backgroundColor: appColors.orderStatus[OrderStatuses.Prepared],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Pending]: {
    label: 'Ожидание',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Pending],
    backgroundColor: appColors.orderStatus[OrderStatuses.Pending],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Success]: {
    label: 'Успех',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Success],
    backgroundColor: appColors.orderStatus[OrderStatuses.Success],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Failure]: {
    label: 'Провал',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Failure],
    backgroundColor: appColors.orderStatus[OrderStatuses.Failure],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Expired]: {
    label: 'Просрочен',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Expired],
    backgroundColor: appColors.orderStatus[OrderStatuses.Expired],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Cancelled]: {
    label: 'Отменён',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Cancelled],
    backgroundColor: appColors.orderStatus[OrderStatuses.Cancelled],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Refund]: {
    label: 'Рефанд',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Refund],
    backgroundColor: appColors.orderStatus[OrderStatuses.Refund],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  [OrderStatuses.Chargeback]: {
    label: 'Чарджбек',
    data: [],
    borderColor: appColors.orderStatus[OrderStatuses.Chargeback],
    backgroundColor: appColors.orderStatus[OrderStatuses.Chargeback],
    yAxisID: Y_AXIS_ID,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
  summary: {
    label: 'Итого',
    data: [],
    borderColor: 'rgba(0, 188, 212, .2)',
    backgroundColor: function (context: ScriptableContext<'line'>) {
      //данная функция взята из примеров графиков в документации библиотеки
      const chart = context.chart;
      const { ctx, chartArea } = chart;
      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }
      let width, height, gradient;
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(0, 188, 212, 0)');
        gradient.addColorStop(1, 'rgba(0, 188, 212, .2)');
      }
      return gradient;
    },
    yAxisID: Y_AXIS_ID,
    fill: true,
    borderWidth: BORDER_WIDTH,
    pointRadius: POINT_RADIUS,
    pointStyle: POINT_STYLE,
  },
};
