import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { FunctionField, RaRecord, useRedirect } from 'react-admin';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ShowPage } from 'shared/react-admin/Pages';
import { ApiClient, Permission } from 'types';

import { ApiClientsShowTable } from './ApiClientsShowTable';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

export const ApiClientsShow = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const redirect = useRedirect();

  const {
    record: apiClient,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<ApiClient>();

  return (
    <ShowPage
      actions={
        hasResourceAccess('user_actions')
          ? [<UserActionsIconButton entityName="api_client" key="user-action-link" />]
          : undefined
      }
      leftActionsSlot={
        hasResourcePermissions('api_clients', [Permission.Full, Permission.Update]) ? (
          <EditLinkButton />
        ) : undefined
      }
      listName="Клиенты Api"
      rightActionsSlot={
        hasResourcePermissions('api_clients', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal(apiClient)} variant="outlined" />
        ) : undefined
      }
      title="Просмотр клиента API"
    >
      <ApiClientsShowTable />
      <FunctionField
        render={(record: RaRecord | undefined) => (
          <DeleteDialog
            details={`Клиент API ${record?.name} будет удален без возможности восстановления.`}
            onClose={handleCloseRecordModal}
            onSuccess={() => redirect('list', 'api_clients')}
            open={openRecordModal}
            record={record}
            title="Удаление клиента API"
          />
        )}
      />
    </ShowPage>
  );
};
