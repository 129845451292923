import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { Typography } from '@mui/material';
import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { MerchantAccount } from 'types';

import { EMPTY_FIELD } from '../../../../../../constants';

export const MerchantShowAccountsActivityField = ({ label }: { label: string }): JSX.Element => {
  const colorsMap = {
    green: { icon: appColors.success.light, text: appColors.success.main },
    orange: { icon: appColors.warning.light, text: appColors.warning.main },
    red: { icon: appColors.error.light, text: appColors.error.main },
  };
  return (
    <FunctionField
      label={label}
      render={(record: MerchantAccount) => {
        const iconColor = record?.balanceUpdatedAtColor
          ? colorsMap[record?.balanceUpdatedAtColor]?.icon
          : appColors.text.disabled;
        const textColor = record?.balanceUpdatedAtColor
          ? colorsMap[record?.balanceUpdatedAtColor]?.text
          : appColors.text.disabled;
        return (
          <Stack alignItems="start" direction="row" gap={1}>
            <MonitorHeartOutlinedIcon htmlColor={iconColor} />
            <Typography color={textColor} component="span">
              {record?.balanceUpdatedAtMessage || EMPTY_FIELD}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};
