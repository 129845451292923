import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { ButtonGroup } from '@mui/material';

import { AppButton } from '../../../../Buttons';

type Props = {
  onAddGroupClick: () => void;
  onAddExpressionClick: () => void;
  children?: React.ReactNode;
};

export function GatewayRulesFormRuleGroupActions({
  onAddGroupClick,
  onAddExpressionClick,
  children,
}: Props): JSX.Element {
  return (
    <>
      <ButtonGroup variant="outlined">
        <AppButton label="Выражение" onClick={onAddExpressionClick} startIcon={<AddIcon />} />
        <AppButton label="Группу" onClick={onAddGroupClick} startIcon={<AddIcon />} />
      </ButtonGroup>
      {children}
    </>
  );
}
