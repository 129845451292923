import { Box, Button } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from 'components/Common/Dialog';
import { AppTypography } from 'components/Common/Typography';

import { GatewayRuleRecord } from '../../../types';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
  record: GatewayRuleRecord;
} & DialogProps;

export const GatewayRuleActiveSwitchConfirm: React.FC<Props> = ({
  onConfirm,
  open,
  onClose,
  record,
}) => {
  return (
    <AppDialogBase onClose={onClose} open={open}>
      <AppDialogHeader
        onClose={onClose}
        title={record.active ? 'Выключение правила' : 'Включение правила'}
      />
      <AppTypography mb={5}>
        {record.active
          ? `Правило ${record?.name} будет временно выключено.`
          : `Правило ${record?.name} после подтверждения активации будет работать в соответствии с текущими настройками`}
      </AppTypography>
      <AppDialogActions
        sx={{
          padding: 0,
          justifyContent: 'flex-start',
        }}
      >
        <Box display="flex" gap={1}>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            variant="contained"
          >
            Подтвердить
          </Button>
          <Button onClick={onClose} sx={{ height: 36 }} variant="outlined">
            Отменить
          </Button>
        </Box>
      </AppDialogActions>
    </AppDialogBase>
  );
};
