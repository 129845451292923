import { GatewayRuleRecord, MappingRecord, MappingItemRecord } from '../../../types';
import { ConditionGroup } from '../../Common/Forms/GatewayRulesForm/GatewayRulesForm';
import {
  GatewayRulesFormValues,
  MappingFormValues,
} from '../../Common/Forms/GatewayRulesForm/hooks/useGatewayRulesForm';

type Props = {
  gatewayRule: GatewayRuleRecord;
  mappings: MappingRecord[];
  mappingItems: MappingItemRecord[];
  conditionGroup: ConditionGroup | null;
};

export function getInitialEditFormValues({
  gatewayRule,
  conditionGroup,
  mappings,
  mappingItems,
}: Props): GatewayRulesFormValues {
  const mappingsResult: MappingFormValues[] = mappings.map((mapping) => {
    return {
      ...mapping,
      mappingItems: mapping.mappingItems.map((mappingItemIndex) => {
        return mappingItems.find((mappingItem) => mappingItem.id === mappingItemIndex);
      }),
    } as MappingFormValues;
  });

  return {
    name: gatewayRule?.name,
    merchant: gatewayRule?.merchant,
    active: gatewayRule?.active,
    conditionGroup,
    mappings: mappingsResult,
  };
}
