import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { GatewayRulesFormValues, MappingFormValues } from './useGatewayRulesForm';
import { getInitialMapping } from '../utils';

type UseMappingFieldReturnValues = {
  mappingFields: Record<'id', string>[];
  onAddMappingClick: () => void;
  onRemoveMappingClick: (index: number) => void;
};

type Props = {
  name: 'mappings';
  form: UseFormReturn<GatewayRulesFormValues, any>;
};

export function useMappingField({ name, form }: Props): UseMappingFieldReturnValues {
  const {
    replace,
    fields: mappingFields,
    append: appendMapping,
    remove: removeMapping,
  } = useFieldArray({
    name,
    control: form.control,
  });

  function onAddMappingClick(): void {
    appendMapping(getInitialMapping() as MappingFormValues);
  }

  function onRemoveMappingClick(index: number): void {
    const currentValues = form.getValues();
    if (currentValues.mappings.length === 1) {
      replace([getInitialMapping() as MappingFormValues]);
      return;
    }
    removeMapping(index);
  }

  return {
    mappingFields,
    onAddMappingClick,
    onRemoveMappingClick,
  };
}
