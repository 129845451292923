import { AppReferenceOneField } from 'components/Common';
import { AppSingleFieldList } from 'components/Common/Fields/AppSingleFieldList';
import {
  ChipField,
  FunctionField,
  NumberField,
  RaRecord,
  ReferenceArrayField,
  TextField,
  WrapperField,
} from 'react-admin';
import { CreateButtonToEntity } from 'shared/mui/Buttons';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { MerchantAccountsFilters } from './MerchantAccountsFilters';
import { usePermissions } from '../../hooks/usePermissions';
import { Hold, Permission } from '../../types';
import { AppMerchantCredentials, AppReferenceField } from '../Common';
import { AppListEditButton, AppListShowButton } from '../Common/Buttons';
import { AppDatagrid } from '../Common/Datagrid';
import { AppList } from '../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../Common/PanelHeader';

const MerchantAccountsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <AppPanelHeader>
        <AppPanelHeaderToolbar
          actions={[
            hasResourcePermissions('merchant_accounts', [Permission.Full, Permission.Create]) && (
              <CreateButtonToEntity entity="merchant" key="create_merchant_accounts" />
            ),
          ]}
          titleText="Счета"
          tooltipText="Данные о количестве средств мерчанта на счете в системе провайдера."
        />
      </AppPanelHeader>
      <MerchantAccountsFilters />
      <AppDatagrid>
        <TextField label="Id" sortBy="id" source="originId" />
        {hasResourceAccess('merchants') && (
          <ReferenceArrayField label="Мерчанты" reference="merchants" source="merchants">
            <AppSingleFieldList>
              {({ record, resource }) => (
                <ChipField record={record} resource={resource} source="name" />
              )}
            </AppSingleFieldList>
          </ReferenceArrayField>
        )}
        <TextField label="Название" source="name" />
        <NumberField label="Баланс" source="balance" />
        {hasResourceAccess('currencies') && (
          <AppReferenceOneField
            label="Валюта"
            reference="currencies"
            sortBy="currency.alphaCode"
            source="currency"
            target="id"
          >
            <FunctionField
              label="Name"
              render={(record: RaRecord | undefined) => `${record?.alphaCode}, ${record?.name}`}
            />
          </AppReferenceOneField>
        )}
        {hasResourceAccess('providers') && (
          <ReferenceField
            emptyText="—"
            label="Провайдер"
            link={false}
            reference="providers"
            sortBy="provider.name"
            source="provider"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {hasResourceAccess('gateways') && (
          <ReferenceArrayField label="Шлюз" reference="gateways" sortable={false} source="gateways">
            <AppSingleFieldList>
              {({ record, resource }) => (
                <ChipField record={record} resource={resource} source="name" />
              )}
            </AppSingleFieldList>
          </ReferenceArrayField>
        )}
        {hasResourceAccess('holds') && (
          <AppReferenceField
            emptyText="—"
            label="Холд"
            link={false}
            reference="holds"
            source="hold"
          >
            <WrapperField>
              <FunctionField render={(record: Hold) => record.value + ', '} />
              <AppReferenceField link={false} reference="currencies" source="currency">
                <TextField source="alphaCode" />
              </AppReferenceField>
            </WrapperField>
          </AppReferenceField>
        )}
        {hasResourceAccess('merchant_credentials') && (
          <FunctionField label="Доступы мерчанта" render={() => <AppMerchantCredentials />} />
        )}
        <ActionsSlots
          render={() => (
            <>
              {hasResourcePermissions('merchant_accounts', [
                Permission.Full,
                Permission.Update,
              ]) && <AppListEditButton />}
              <AppListShowButton />
            </>
          )}
        />
      </AppDatagrid>
    </AppList>
  );
};

export default MerchantAccountsList;
