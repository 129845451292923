import { GatewayRuleFieldExpressionParamCountry } from './GatewayRuleFieldExpressionParamCountry';
import { GatewayRuleFieldExpressionParamCurrency } from './GatewayRuleFieldExpressionParamCurrency';
import { Country, Currency, ExpressionParam } from '../../../../../types';
import { GatewayRuleFieldChip } from '../GatewayRuleFieldChip';

type Props = {
  currencies: Currency[];
  countries: Country[];
  param: ExpressionParam;
  value: string[];
};

export function GatewayRuleFieldExpressionParam({
  param,
  value,
  currencies,
  countries,
}: Props): JSX.Element {
  switch (param.alias) {
    case 'currency':
      return <GatewayRuleFieldExpressionParamCurrency codes={value} currencies={currencies} />;
    case 'country':
      return <GatewayRuleFieldExpressionParamCountry codes={value} countries={countries} />;
    case 'customerId':
    case 'countDeposit':
      return <GatewayRuleFieldChip label={value} />;
    case 'appVersion':
    case 'domain':
    case 'gatewayPayment':
      return <GatewayRuleFieldChip label={value.length > 1 ? value.join(', ') : value} />;
    default:
      return <></>;
  }
}
