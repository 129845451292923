import InfoIcon from '@mui/icons-material/Info';

import { IconButton } from '../../../shared/mui/IconButtons';
import { Tooltip } from '../../../shared/mui/Tooltip/Tooltip/Tooltip';
import { Typography } from '../../../shared/mui/Typography';

export const NewSchemeInfoTooltip: React.FC = () => {
  return (
    <Tooltip
      placement="bottom-start"
      title={
        <Typography fontSize={12}>
          Должна использоваться при создании нового клиента API с одним или множеством мерчантов
        </Typography>
      }
    >
      <IconButton>
        <InfoIcon color="secondary" />
      </IconButton>
    </Tooltip>
  );
};
