import { ROLES_ICONS, ROLES_SELECT_ITEMS } from 'constants/roles';

import { VpnKey, PlaylistAddCheck, ListAlt } from '@mui/icons-material';
import { FunctionField, ReferenceArrayField, TextField, WithRecord } from 'react-admin';
import { CreateButton } from 'shared/mui/Buttons';
import { Chip } from 'shared/mui/Chips';
import { EditLinkIconButton, ShowLinkIconButton } from 'shared/mui/IconButtons';
import { IconLabel } from 'shared/mui/Labels/IconLabel/IconLabel';
import { PageHeader } from 'shared/mui/PageHeader';
import { ExportButton, ListButton } from 'shared/react-admin/Buttons';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { ChipField, ListField } from 'shared/react-admin/Fields';
import { TooltipTextField } from 'shared/react-admin/Fields/TooltipTextField/TooltipTextField';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { ApiClientsListChips } from './ApiClientsListChips';
import { ApiClientsListFilters } from './ApiClientsListFilters';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ApiClient, Permission } from '../../../types';
import { AppList } from '../../Common/List';

export const ApiClientsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <AppList>
      <PageHeader
        actions={[
          <CreateButton key="create_client_api" />,
          <ExportButton key="export_client_api" />,
        ]}
        headerTitle={{
          titleText: 'Клиенты API',
          tooltipText:
            'Приложение, выполняющее запросы к API Kubera. Например, сайт мерчанта или его мобильное приложение.',
        }}
      />
      <ApiClientsListFilters />
      <Datagrid bulkActionButtons={false}>
        <TextField label="Id" sortBy="id" source="originId" />
        <TooltipTextField label="Название" source="name" />
        <TooltipTextField label="Алиас" source="alias" />
        <ListField
          array={ROLES_SELECT_ITEMS}
          label="Доступ"
          renderItem={({ id, name }) => <Chip icon={ROLES_ICONS[id]} key={id} label={name} />}
          sortable={false}
          source="roles"
        />
        {hasResourceAccess('merchants') && (
          <FunctionField
            label="Мерчанты"
            render={(record: ApiClient) => {
              if (!record?.merchant && !record?.merchants?.length) {
                return '—';
              }
              return record?.merchants?.length ? (
                <ReferenceArrayField
                  label="Мерчанты"
                  reference="merchants"
                  sortBy="merchants.name"
                  source="merchants"
                >
                  <ApiClientsListChips />
                </ReferenceArrayField>
              ) : (
                <ReferenceOneField
                  label="Мерчант"
                  link="show"
                  reference="merchants"
                  source="merchant"
                  target="id"
                >
                  <ChipField
                    source="name"
                    sx={{ color: 'black!important', margin: '0!important' }}
                  />
                </ReferenceOneField>
              );
            }}
          />
        )}
        {hasResourceAccess('api_keys') && (
          <WithRecord
            // @ts-ignore
            label={<IconLabel icon={VpnKey} label="API ключи" />}
            render={(record) => {
              return (
                <ListButton
                  filter={{
                    apiClient: record?.id,
                  }}
                  icon={<VpnKey />}
                  label="Показать"
                  pathname="/api_keys"
                  sx={{ width: '140px', justifyContent: 'flex-start' }}
                />
              );
            }}
          />
        )}
        {hasResourceAccess('api_client_white_ip_addresses') && (
          <WithRecord
            // @ts-ignore
            label={<IconLabel icon={ListAlt} label="Белые IP" />}
            render={(record) => {
              return (
                <ListButton
                  filter={{
                    apiClient: record?.id,
                  }}
                  icon={<PlaylistAddCheck />}
                  label="Whitelist"
                  pathname="/api_client_white_ip_addresses"
                />
              );
            }}
          />
        )}
        <ActionsSlots
          render={() => (
            <>
              {hasResourceAccess('user_actions') && (
                <UserActionsIconButton entityName="api_client" />
              )}
              <ShowLinkIconButton />
              {hasResourcePermissions('api_clients', [Permission.Full, Permission.Update]) && (
                <EditLinkIconButton />
              )}
            </>
          )}
        />
      </Datagrid>
    </AppList>
  );
};
