import React from 'react';

import { Box, Button } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { useGetOne, useRecordContext } from 'react-admin';

import { MappingRecord } from '../../../../types';
import { AppDialogActions, AppDialogBase, AppDialogHeader } from '../../../Common/Dialog';
import { AppTypography } from '../../../Common/Typography';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
} & DialogProps;

export const GatewayRuleMappingDeactivateConfirm: React.FC<Props> = ({
  onConfirm,
  onClose,
  open,
}) => {
  const record = useRecordContext<MappingRecord>();

  const { data: method } = useGetOne('methods', {
    id: record.method,
  });

  const { data: gatewayRule } = useGetOne('gateway_rules', {
    id: record.gatewayRule,
  });

  return (
    <AppDialogBase onClose={onClose} open={open}>
      <AppDialogHeader
        onClose={onClose}
        title={record.active ? 'Выключение метода' : 'Включение метода'}
      />
      <AppTypography mb={5}>
        {record?.active
          ? `Метод ${method?.name} для правила ${gatewayRule?.name} будет выключен.`
          : `Метод ${method?.name} для правила ${gatewayRule?.name} после подтверждения активации будет работать в соответствии с текущими настройками.`}
      </AppTypography>
      <AppDialogActions
        sx={{
          padding: 0,
          justifyContent: 'flex-start',
        }}
      >
        <Box display="flex" gap={1}>
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            variant="contained"
          >
            Подтвердить
          </Button>
          <Button onClick={onClose} sx={{ height: 36 }} variant="outlined">
            Отменить
          </Button>
        </Box>
      </AppDialogActions>
    </AppDialogBase>
  );
};
