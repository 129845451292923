import { getInitialMappingItem } from './getInitialMappingItem';
import { MappingFormValues } from '../hooks/useGatewayRulesForm';

export function getInitialMapping(): Nullable<MappingFormValues> {
  return {
    active: false,
    direction: null,
    method: null,
    mappingItems: [getInitialMappingItem()],
  } as any;
}
