import { FilterChipType } from 'constants/filterChipType';

import { useState } from 'react';

import { addDays, subDays } from 'date-fns';
import { useFilterChips } from 'hooks/useFilterChips';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { TextFieldShrink } from 'shared/mui/TextFieldShrink/TextFieldShrink';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { ReferenceInputIntrospected } from 'shared/react-admin/ReferenceInputs';
import { getLastUrlSegment } from 'utils/getLastUrlSegment';

import {
  ORDERS_MONITORING_DATE_LIMIT,
  OrderMonitoringListFilterValues,
} from './OrdersMonitoringList';
import { FilterBaseApplyButton } from '../../../shared/mui/Filter/FilterButtons/FilterBaseApplyButton/FilterBaseApplyButton';
import { FilterBaseResetButton } from '../../../shared/mui/Filter/FilterButtons/FilterBaseResetButton/FilterBaseResetButton';


type Props = {
  defaultDateFrom: Date;
  defaultDateTo: Date;
  onSubmitClick: (filter: OrderMonitoringListFilterValues) => void;
  setFilters: (filters: OrderMonitoringListFilterValues) => void;
  appliedFilters: OrderMonitoringListFilterValues;
};

export const OrderMonitoringListFilter: React.FC<Props> = ({
  defaultDateFrom,
  defaultDateTo,
  onSubmitClick,
  setFilters,
  appliedFilters,
}) => {
  const [minDate, setMinDate] = useState(subDays(defaultDateTo, ORDERS_MONITORING_DATE_LIMIT));
  const [maxDate, setMaxDate] = useState(defaultDateTo);

  const form = useForm<OrderMonitoringListFilterValues>({
    defaultValues: {
      dateFrom: defaultDateFrom,
      dateTo: defaultDateTo,
      gateway: null,
      direction: null,
    },
  });

  const handleFilter = (): void => {
    const { direction, ...filterValues } = form.getValues();
    onSubmitClick({ ...filterValues, direction: getLastUrlSegment(direction) });
  };

  const resetFilter = (): void => {
    form.reset({
      dateFrom: defaultDateFrom,
      dateTo: defaultDateTo,
      gateway: null,
      direction: null,
    });
    setFilters({ dateFrom: defaultDateFrom, dateTo: defaultDateTo });
  };

  const resetFilterBySource = (source: string) => {
    form.setValue(source.split('.')[0] as keyof OrderMonitoringListFilterValues, null);
    handleFilter();
  };

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'dateFrom', label: 'Дата с', type: FilterChipType.DefaultDate },
      { source: 'dateTo', label: 'Дата по', type: FilterChipType.DefaultDate },
      { source: 'gateway', label: 'Шлюз', type: FilterChipType.Id },
      { source: 'direction', label: 'Направление', type: FilterChipType.Direction },
    ],
    appliedFilters: appliedFilters,
  });

  const handleMaxDate = (date: Date) => {
    const maxDate = addDays(date, ORDERS_MONITORING_DATE_LIMIT);
    if (maxDate.getTime() > defaultDateTo.getTime()) {
      setMaxDate(defaultDateTo);
      return;
    }
    setMaxDate(maxDate);
  };

  const isDateChanged = (): boolean => {
    return (
      appliedFilters.dateFrom.getTime() !== defaultDateFrom.getTime() ||
      appliedFilters.dateTo.getTime() !== defaultDateTo.getTime()
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleFilter)}>
        <FilterCollapsible
          actions={[
            <FilterBaseApplyButton key="apply-button" type="submit" />,
            <FilterBaseResetButton
              disabled={!appliedFilters.direction && !appliedFilters.gateway && !isDateChanged()}
              key="reset-button"
              onClick={resetFilter}
            />,
          ]}
          filterChipValues={filterChipValues}
          resetFilterBySource={resetFilterBySource}
        >
          <FilterFieldGroup>
            <Controller
              control={form.control}
              name="dateFrom"
              render={({ field }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      fullWidth
                      label="Дата с"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                  endDate={form.getValues('dateTo')}
                  locale="ru"
                  maxDate={maxDate}
                  minDate={minDate}
                  onChange={(date) => {
                    if (date) {
                      field.onChange(date);
                      handleMaxDate(date);
                    } else {
                      field.onChange(new Date(defaultDateFrom));
                      setMaxDate(addDays(new Date(defaultDateFrom), ORDERS_MONITORING_DATE_LIMIT));
                    }
                  }}
                  required={true}
                  selected={field.value}
                  selectsStart
                  startDate={field.value}
                />
              )}
            />
            <Controller
              control={form.control}
              name="dateTo"
              render={({ field }) => (
                <DatePicker
                  customInput={
                    <TextFieldShrink
                      fullWidth
                      label="Дата по"
                      size="small"
                      value={field.value}
                      variant="outlined"
                    />
                  }
                  dateFormat="dd/MM/yyyy"
                  endDate={field.value}
                  locale="ru"
                  maxDate={maxDate}
                  onChange={(date) => {
                    if (date) {
                      field.onChange(date);
                      setMinDate(subDays(date, ORDERS_MONITORING_DATE_LIMIT));
                    } else {
                      field.onChange(defaultDateTo);
                      setMinDate(subDays(defaultDateTo, ORDERS_MONITORING_DATE_LIMIT));
                    }
                  }}
                  required={true}
                  selected={field.value}
                  selectsEnd
                  startDate={form.getValues('dateFrom')}
                />
              )}
            />
            <ReferenceInputIntrospected
              name="direction"
              reference="directions"
              resource="directions"
              source="direction"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ description: searchText })}
                fullWidth
                helperText={false}
                label="Направление"
                name="direction"
                optionText="description"
                optionValue="id"
                variant="outlined"
              />
            </ReferenceInputIntrospected>
            <ReferenceInputIntrospected
              key="gateway"
              name="gateway"
              perPage={200}
              reference="gateways"
              resource="gateways"
              source="gateway"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюз"
                name="gateway"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInputIntrospected>
          </FilterFieldGroup>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
