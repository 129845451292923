import { GATEWAY_STATUSES, GatewayStatus } from 'constants/gatewayStatuses';

import { useState } from 'react';

import { GatewayEditFormValues } from 'components/Gateways/GatewaysEdit/hooks/useGatewayEditForm';
import { createGateway } from 'config/requests';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { RaRecord, useNotify, useRedirect } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { BaseDialog } from 'shared/mui/Dialogs/BaseDialog/BaseDialog';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreatePage } from 'shared/react-admin/Pages/CreatePage/CreatePage';
import { ErrorData, Gateway } from 'types';

import { GatewaysCreateForm } from './GatewaysCreateForm';
import { getIntervalConfig } from '../utils/getAttemptIntervalConfig';
import { getGatewaysSchemaResolver } from '../utils/getGatewaysSchemaResolver';

const transform = (data: RaRecord): RaRecord => ({
  ...data,
  attemptIntervalConfig: getIntervalConfig(data.attemptIntervalConfig),
});

const errorMessage = 'Ошибка: Форма заполнена неверно, проверьте, пожалуйста, ошибки';

export const GatewaysCreate: React.FC = () => {
  const redirect = useRedirect();
  const { toRoot } = useNavigateToRoot();
  const notify = useNotify();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [recordData, setRecordData] = useState<RaRecord>();
  const [error, setError] = useState<ErrorData | null>(null);

  const form = useForm<GatewayEditFormValues>({ resolver: getGatewaysSchemaResolver() });
  async function submitHandler(formValues: GatewayEditFormValues) {
    setError(null);
    const isGatewayDisabledStatus =
      formValues.status === GATEWAY_STATUSES[GatewayStatus.Disabled].id;

    const formattedFormValues = {
      ...formValues,
      deactivationReason: isGatewayDisabledStatus ? formValues.deactivationReason : null,
      attemptIntervalConfig: getIntervalConfig(formValues.attemptIntervalConfig),
    };

    try {
      const data = await createGateway(formattedFormValues);
      onSuccess(data);
    } catch (error) {
      setError(error as ErrorData);
      notify(errorMessage, { type: 'error' });
    }
  }
  function onSuccess(data: Gateway) {
    setRecordData(data);
    setIsOpenModal(true);
  }

  const onSubmitHandler = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    if (!form.formState.isValid) {
      notify(errorMessage, { type: 'error' });
    }
    form.handleSubmit(submitHandler)();
  };

  return (
    <CreatePage
      fullWidth
      listName="Шлюзы"
      sx={{ marginTop: 0, padding: 0, maxWidth: '444px' }}
      title="Создание шлюза"
      transform={transform}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <FormProvider {...form}>
          <form onSubmit={onSubmitHandler}>
            <GatewaysCreateForm error={error} />
            <CardActionsButtons
              leftActionsSlot={[
                <SaveButton key="save-button" />,
                <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
              ]}
              sx={{ paddingY: '24px', paddingX: 0 }}
            />
          </form>
        </FormProvider>
      </CardContent>
      <BaseDialog
        message={`Создан новый шлюз ${recordData?.name}`}
        onClose={toRoot}
        open={isOpenModal}
        rightActionsSlot={[
          <Button
            key="setting-button"
            label="Настроить режим тестирования"
            onClick={() =>
              redirect(`/gateways/${encodeURIComponent(recordData ? recordData.id : '')}`)
            }
            variant="outlined"
          />,
          <Button key="close-button" label="Закрыть" onClick={toRoot} variant="outlined" />,
        ]}
        title="Шлюз создан"
      />
    </CreatePage>
  );
};
