import { DeleteStages } from 'constants/deleteStages';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { useRedirect, useRefresh, useShowController } from 'react-admin';
import { CancelButton, DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { UserEntityPermissionsIconButton } from 'shared/mui/IconButtons';
import { ShowPage } from 'shared/react-admin/Pages';
import { UserEntityPermissionsNames } from 'shared/widgets/UserEntityPermissions/constants/userEntityPermissionsNames';
import { UserEntityPermissionsCreateDialog } from 'shared/widgets/UserEntityPermissions/UserEntityPermissionsCreateDialog';
import { UserEntityPermissionsEditDialog } from 'shared/widgets/UserEntityPermissions/UserEntityPermissionsEditDialog';
import { Gateway, Permission } from 'types';

import { GatewaysShowTable } from './GatewaysShowTable/GatewaysShowTable';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';

export const GatewaysShow = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<Gateway>();
  const {
    handleCloseRecordModal: handleCloseRecordModalPermissions,
    openRecordModal: openRecordModalPermissions,
    handleOpenRecordModal: handleOpenRecordModalPermissions,
  } = useRecordModal<Gateway>();

  const refresh = useRefresh();
  const redirect = useRedirect();

  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record,
    onSuccess: () => {
      refresh();
      handleCloseRecordModal();
      setStage(DeleteStages.Initial);
      redirect('list', 'gateways');
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    handleCloseRecordModal();
  };

  const getActions = (): React.ReactElement[] | undefined => {
    const actions = [];
    if (hasResourceAccess('user_actions')) {
      actions.push(<UserActionsIconButton entityName="gateway" key="user-action-link" />);
    }
    if (hasResourceAccess('user_entity_permissions')) {
      actions.push(
        <UserEntityPermissionsIconButton
          onClick={() => handleOpenRecordModalPermissions(record)}
        />,
      );
    }
    return actions.length ? actions : undefined;
  };

  return (
    <ShowPage
      actions={getActions()}
      leftActionsSlot={[
        (hasResourcePermissions('gateways', [Permission.Full, Permission.Update]) ||
          record.locked === false) && <EditLinkButton key="edit-button" label="Редактировать" />,
      ]}
      listName="Шлюзы"
      rightActionsSlot={[
        hasResourcePermissions('gateways', [Permission.Full, Permission.Delete]) && (
          <DeleteButton
            key="delete-button"
            onClick={() => handleOpenRecordModal(record)}
            variant="outlined"
          />
        ),
      ]}
      title="Просмотр шлюза"
    >
      <GatewaysShowTable />
      {record && !record.permissionsCount && (
        <UserEntityPermissionsCreateDialog
          entityName={UserEntityPermissionsNames.Gateway}
          onClose={handleCloseRecordModalPermissions}
          open={openRecordModalPermissions}
          recordId={record?.originId}
          recordName={record?.name}
          title="Включение доступа к шлюзу"
        />
      )}
      {record?.permissionsCount && (
        <UserEntityPermissionsEditDialog
          entityName={UserEntityPermissionsNames.Gateway}
          onClose={handleCloseRecordModalPermissions}
          open={openRecordModalPermissions}
          recordId={record?.originId}
          recordName={record?.name}
          title="Редактирование доступа к шлюзу"
        />
      )}
      <DeleteStageDialog
        deleteOneRecord={deleteOneRecord}
        details={`Шлюз ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseDeleteDialog}
        open={openRecordModal}
        stage={stage}
        title="Удаление шлюза"
      >
        <DeleteStageDialogDenyReason reason={denyDeleteReason}>
          <CancelButton
            key="history-back-button"
            label="Вернуться к списку шлюзов"
            onClick={handleCloseDeleteDialog}
          />
        </DeleteStageDialogDenyReason>
      </DeleteStageDialog>
    </ShowPage>
  );
};
