import { AppReferenceOneField } from 'components/Common';
import {
  BooleanField,
  ChipField,
  FunctionField,
  RaRecord,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { HistoryIconButton } from 'shared/mui/IconButtons';

import { usePermissions } from '../../hooks/usePermissions';
import { AppShowGuesser } from '../Common/Show';

const MerchantPayoutControlsShow = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();

  return (
    <AppShowGuesser
      actions={false}
      listName="Контроль выводов"
      title="Просмотр контроля выводов"
      toolbarActions={[<HistoryIconButton key="history-button" />]}
    >
      {hasResourceAccess('merchants') && (
        <AppReferenceOneField
          label="Мерчант"
          link="show"
          reference="merchants"
          source="merchant"
          target="id"
        >
          <TextField source="name" />
        </AppReferenceOneField>
      )}
      {hasResourceAccess('gateways') && (
        <AppReferenceOneField
          label="Шлюз"
          link="show"
          reference="gateways"
          source="gateway"
          target="id"
        >
          <TextField source="name" />
        </AppReferenceOneField>
      )}
      <BooleanField
        label="Разрешить выплату на другой аккаунт"
        source="allowPayoutToAnotherAccount"
      />
      {hasResourceAccess('customers') && (
        <ReferenceArrayField
          label="Исключенные пользователи"
          reference="customers"
          source="excludedCustomers"
        >
          <FunctionField
            render={(record: RaRecord) => {
              if (!record.excludedCustomers.length) {
                return '—';
              }
              return (
                <SingleFieldList linkType={false}>
                  <ChipField source="originId" />
                </SingleFieldList>
              );
            }}
          />
        </ReferenceArrayField>
      )}
    </AppShowGuesser>
  );
};

export default MerchantPayoutControlsShow;
