import { RaRecord } from 'react-admin';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { CreateGuesserPage } from 'shared/react-admin/Pages';
import { MerchantAccount } from 'types';

import { MerchantAccountsCreateForm } from './MerchantAccountsCreateForm';

const MerchantAccountsCreate = (): JSX.Element => {
  const transform = (data: MerchantAccount): RaRecord => {
    return {
      ...data,
      merchantCredentials: data.merchantCredentials || null,
      hold: data.hold || null,
      currency: data.currency || null,
    };
  };

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <Button key="save-button" label="Создать" type="submit" variant="contained" />,
        <CancelButton key="cancel-button" variant="outlined" />,
      ]}
      listName="Счета"
      mutationOptions={{ onError: () => undefined }}
      redirect="list"
      sx={{
        '& .RaCreate-main': {
          width: 600,
        },
      }}
      title="Создание счета"
      transform={transform}
    >
      <CardContent>
        <MerchantAccountsCreateForm />
      </CardContent>
    </CreateGuesserPage>
  );
};

export default MerchantAccountsCreate;
