import { RoleEntity } from 'constants/roles';

import { FormControl } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import { updateProviderAccountWorkTeamUsers } from 'config/requests';
import { RecordContextProvider, SimpleForm, useGetOne, useNotify, useRefresh } from 'react-admin';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { GroupFieldChip } from 'shared/mui/Chips';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { InputLabel } from 'shared/mui/Labels/InputLabel/InputLabel';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { appColors } from 'themes/variables';

import { StyledBox, StyledDialog } from './WorkTeamUsersAddDialog.styled';
import { ProviderAccount, User } from '../../../../../types';

type WorkTeamUsersAddDialogProps = {
  provider: ProviderAccount;
  onClose: () => void;
} & DialogProps;

export const WorkTeamUsersAddDialog = ({
  open,
  provider,
  onClose,
}: WorkTeamUsersAddDialogProps) => {
  const isProviderAccountNew = useFlag('wppa_6258_provider_account_new');
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: currentCompany } = useGetOne('companies', {
    id: provider?.company,
  });

  const { data: currentProvider } = useGetOne('providers', {
    id: provider?.provider,
  });

  const { data: currentWorkTeam } = useGetOne('work_teams', {
    id: provider?.workTeam,
  });

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    if (provider?.id) {
      updateProviderAccountWorkTeamUsers(provider.originId, data, isProviderAccountNew)
        .then(() => {
          refresh();
          onClose();
        })
        .catch((err) => {
          notify(err.data.detail, { type: 'error' });
        });
    }
  };

  const providerName = `${currentCompany?.name || ''} | ${currentProvider?.name || ''}`;

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<></>}>
        <RecordContextProvider value={provider}>
          <StyledBox>
            <CardHeader
              actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
              title="Добавление ответственных"
            />
            <CardContent>
              <FormControl fullWidth sx={{ marginBottom: '16px' }} variant="outlined">
                <InputLabel id="company-name">Личный кабинет</InputLabel>
                <Select
                  disabled
                  label="Личный кабинет"
                  labelId="company-name"
                  size="medium"
                  value={providerName}
                >
                  <MenuItem value={providerName}>{providerName}</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="work-team" sx={{ top: '7px' }}>
                  Рабочая группа
                </InputLabel>
                <Select
                  disabled
                  label="Рабочая группа"
                  labelId="work-team"
                  size="medium"
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputBase-input.MuiSelect-select.Mui-disabled': {
                      WebkitTextFillColor: appColors.text.light,
                    },
                  }}
                  value={currentWorkTeam?.name}
                >
                  <MenuItem value={currentWorkTeam?.name}>
                    <GroupFieldChip group={currentWorkTeam} />
                  </MenuItem>
                </Select>
              </FormControl>
              <ReferenceArrayInput
                filter={{
                  roleEntities: [RoleEntity.Finance],
                  id: currentWorkTeam?.members,
                }}
                perPage={200}
                reference="users"
                source="members"
              >
                <AutocompleteMultiselectInput
                  filterToQuery={(searchText: string) => ({
                    fullName: searchText,
                  })}
                  helperText={false}
                  label="Сотрудники"
                  name="members"
                  optionText="name"
                  renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
                  size="medium"
                />
              </ReferenceArrayInput>
            </CardContent>
            <CardActionsButtons
              leftActionsSlot={[
                <Button key="save-button" label="Сохранить" type="submit" variant="contained" />,
                <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
              ]}
            />
          </StyledBox>
        </RecordContextProvider>
      </SimpleForm>
    </StyledDialog>
  );
};
