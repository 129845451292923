export type ColumnType = { label: string; value: string };

export const PERIOD = 'Период';
export const SUCCESS_PERCENT = 'Процент успеха';
export const TOTAL = 'Итого';

export const tableConfig: ColumnType[] = [
  {
    label: SUCCESS_PERCENT,
    value: 'successRate',
  },
  {
    label: 'Создан',
    value: 'created',
  },
  {
    label: 'Подготовлен',
    value: 'prepared',
  },
  {
    label: 'Ожидание',
    value: 'pending',
  },
  {
    label: 'Успех',
    value: 'success',
  },
  {
    label: 'Провал',
    value: 'failure',
  },
  {
    label: 'Просрочен',
    value: 'expired',
  },
  {
    label: 'Отменен',
    value: 'cancelled',
  },
  {
    label: 'Рефанд',
    value: 'refund',
  },
  {
    label: 'Чарджбек',
    value: 'chargeback',
  },
  {
    label: TOTAL,
    value: 'summary',
  },
];
